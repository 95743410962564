import { Localized } from "@fluent/react";
import { DocumentTypes } from "../documentTypes";

export const DocumentTypesLabel = ({ docType }: { docType: DocumentTypes }) => {
    switch (docType) {
        case "RISK_ANALYSIS":
            return <Localized id="risk-analysis">
                <span>RISK_ANALYSIS</span>
            </Localized>
     
        default:
            return <span>{docType}</span>
    }
}