import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { List, ListItem, ListItemText, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { PasswordConfirmationDialog } from "../dialogs/PasswordConfirmationDialog";
import { CompanySetting, updateCompanySettings } from "../companySettingsAPI";
import { useAppDispatch } from "../../../../app/hooks";
import { showSuccess, showError } from "../../../notifications/notificationsSlice";
import { CardActionButtons } from "./CardActionButtons";
import { Localized } from "@fluent/react";


interface CompanyLoginSettingCardProps {
    companySettings: CompanySetting[];
    onUpdate: (updatedSettings: CompanySetting[]) => void;
}

export const CompanyLoginSettingCard: React.FC<CompanyLoginSettingCardProps> = ({ companySettings, onUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const dispatch = useAppDispatch()
    const [serverError, setServerError] = useState("")

    const originalSetting = companySettings.find(setting => setting.code === "AUTH_2FA_EMAIL_CODE");

    const [auth2FAEmailCodeSetting, setAuth2FAEmailCodeSetting] = useState<CompanySetting | undefined>(originalSetting);

    useEffect(() => {
        setAuth2FAEmailCodeSetting(originalSetting);
    }, [companySettings, isEditing]);

    const toggleAuth2FAEmailCode = () => {
        setAuth2FAEmailCodeSetting(prev => prev ? { ...prev, isActive: !prev.isActive } : undefined);
    };

    const handleSave = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
        setServerError("")
    };

    const handleConfirmSave = async (password: string) => {
        if (password && auth2FAEmailCodeSetting) {
            setSaving(true)
            setServerError("")

            try {
                const updatedSettings: CompanySetting[] = [auth2FAEmailCodeSetting];

                const result =
                    await dispatch(updateCompanySettings({
                        password: password,
                        settings: updatedSettings
                    }))

                if (updateCompanySettings.fulfilled.match(result)) {
                    const settings = result.payload as CompanySetting[];
                    onUpdate(settings)
                    setDialogOpen(false);
                    setIsEditing(false);
                    dispatch(showSuccess("saved"))
                }
                else {
                    let errorMsg = "error"
                    
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    setServerError(errorMsg)
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }


    const handleCancel = () => {
        setIsEditing(false);
    };

    if (!auth2FAEmailCodeSetting) {
        return null;
    }

    return (
        <Card sx={{ maxWidth: 500, borderRadius: 2, boxShadow: 3 }}>
            <CardHeader
                title={<Localized id="company-settings-2fa">
                    <span>_Logowanie dwuetapowe</span>
                </Localized>}
                sx={{
                    textAlign: "center",
                    bgcolor: "primary.main",
                    color: "white",
                    py: 1,
                    "& .MuiCardHeader-title": { fontSize: "1rem" },
                }}
            />
            <CardContent>
                <List>
                    <ListItem sx={{ justifyContent: "space-between" }}>
                        <ListItemText primary={<Localized id="company-settings-2fa-email-desc">
                            <span>_email</span>
                        </Localized>} />
                        <Switch
                            checked={auth2FAEmailCodeSetting?.isActive}
                            onChange={toggleAuth2FAEmailCode}
                            disabled={!isEditing}
                        />
                    </ListItem>

                </List>
                <Divider sx={{ width: "100%", mt: 3 }} />
                <CardActionButtons
                    isEditing={isEditing}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    onEdit={() => setIsEditing(true)}
                />
                
            </CardContent>
            {dialogOpen &&
                <PasswordConfirmationDialog
                    saving={saving}
                    serverError={serverError}
                    onClose={handleClose}
                    onConfirm={handleConfirmSave}
                />
            }
        </Card>

    );
};
