import { Card, CardContent, CardHeader, Divider, TextField } from "@mui/material";
import { List, ListItem, ListItemText, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { PasswordConfirmationDialog } from "../dialogs/PasswordConfirmationDialog";
import { CompanySetting, updateCompanySettings } from "../companySettingsAPI";
import { useAppDispatch } from "../../../../app/hooks";
import { showSuccess, showError } from "../../../notifications/notificationsSlice";
import { CardActionButtons } from "./CardActionButtons";
import { Localized } from "@fluent/react";


interface CompanyUrsRequestMatchingSettingCardProps {
    companySettings: CompanySetting[];
    onUpdate: (updatedSettings: CompanySetting[]) => void;
}

export const CompanyUrsRequestMatchingSettingCard: React.FC<CompanyUrsRequestMatchingSettingCardProps> = ({ companySettings, onUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const dispatch = useAppDispatch()
    const [serverError, setServerError] = useState("")

    const orgLevensteinSetting = companySettings.find(setting => setting.code === "MATCHING_MIN_LEVENSHTEIN_SIMILARITY");
    const orgSbertSetting = companySettings.find(setting => setting.code === "MATCHING_MIN_SBERT_SIMILARITY");

    const [levensteinSetting, setLevensteinSetting] = useState<CompanySetting | undefined>(orgLevensteinSetting);
    const [sbertSetting, setSbertSetting] = useState<CompanySetting | undefined>(orgSbertSetting);

    useEffect(() => {
        setLevensteinSetting(orgLevensteinSetting);
        setSbertSetting(orgSbertSetting);
    }, [companySettings, isEditing]);


    const handleSave = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
        setServerError("")
    };

    const handleConfirmSave = async (password: string) => {
        if (password && levensteinSetting && sbertSetting) {
            setSaving(true)
            setServerError("")

            try {
                const updatedSettings: CompanySetting[] = [levensteinSetting, sbertSetting];

                const result =
                    await dispatch(updateCompanySettings({
                        password: password,
                        settings: updatedSettings
                    }))

                if (updateCompanySettings.fulfilled.match(result)) {
                    const settings = result.payload as CompanySetting[];
                    onUpdate(settings)
                    setDialogOpen(false);
                    setIsEditing(false);
                    dispatch(showSuccess("saved"))
                }
                else {
                    let errorMsg = "error"

                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    setServerError(errorMsg)
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }


    const levensteinMethodUsage = () => {
        setLevensteinSetting(prev => prev ? { ...prev, isActive: !prev.isActive } : undefined);
    };

    const sbertMethogUsage = () => {
        setSbertSetting(prev => prev ? { ...prev, isActive: !prev.isActive } : undefined);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };

    return (
        <Card sx={{ maxWidth: 500, borderRadius: 2, boxShadow: 3 }}>
            <CardHeader
                title={<Localized id="company-settings-urs-request-matching">
                    <span>_matching</span>
                </Localized>}
                sx={{
                    textAlign: "center",
                    bgcolor: "primary.main",
                    color: "white",
                    py: 1,
                    "& .MuiCardHeader-title": { fontSize: "1rem" },
                }}
            />
            <CardContent>
                <ListItem>
                    <ListItemText
                        primary={<Localized id="company-settings-use-levenstein">
                            <span>_Use levenstein</span>
                        </Localized>}
                    />
                    <Switch
                        checked={levensteinSetting?.isActive || levensteinSetting?.isFixed}
                        onChange={levensteinMethodUsage}
                        disabled={!isEditing || levensteinSetting?.isFixed}
                    />
                </ListItem>

                {levensteinSetting?.isActive && (
                    <ListItem>
                        <TextField
                            label={<Localized id="company-settings-levenstein-min">
                                <span>_Minimum</span>
                            </Localized>}
                            type="number"
                            value={levensteinSetting?.value || 0}
                            onChange={(e) => {
                                const newValue = Math.min(100, Math.max(0, Number(e.target.value)));
                                setLevensteinSetting(prev => prev ? { ...prev, value: newValue.toString() } : undefined);
                            }}
                            disabled={!isEditing}
                            variant="outlined"
                            fullWidth
                            size="small"
                            inputProps={{ min: 0, max: 100 }}
                        />
                    </ListItem>
                )}

                <ListItem>
                    <ListItemText
                        primary={<Localized id="company-settings-use-sbert">
                            <span>_Use SBERT</span>
                        </Localized>}
                    />
                    <Switch
                        checked={sbertSetting?.isActive || sbertSetting?.isFixed}
                        onChange={sbertMethogUsage}
                        disabled={!isEditing || sbertSetting?.isFixed}
                    />
                </ListItem>
                {sbertSetting?.isActive && (
                    <ListItem>
                        <TextField
                            label={<Localized id="company-settings-sbert-min">
                                <span>_Minimum</span>
                            </Localized>}
                            type="number"
                            value={sbertSetting?.value || 0}
                            onChange={(e) => {
                                const newValue = Math.min(100, Math.max(0, Number(e.target.value)));
                                setSbertSetting(prev => prev ? { ...prev, value: newValue.toString() } : undefined);
                            }}
                            disabled={!isEditing}
                            variant="outlined"
                            fullWidth
                            size="small"
                            inputProps={{ min: 0, max: 100 }}
                        />
                    </ListItem>
                )}

                <Divider sx={{ width: "100%", mt: 3 }} />
                <CardActionButtons
                    isEditing={isEditing}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    onEdit={() => setIsEditing(true)}
                />

            </CardContent>
            {dialogOpen &&
                <PasswordConfirmationDialog
                    saving={saving}
                    serverError={serverError}
                    onClose={handleClose}
                    onConfirm={handleConfirmSave}
                />
            }
        </Card>

    );
};
