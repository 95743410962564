import { Localized, useLocalization } from '@fluent/react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useDataGridCustomLocaleText } from '../../../app/hooks'
import { useEffect, useState } from 'react'
import { loadSystemDocumentTemplates } from '../api/systemDocumentTemplatesApi'
import { unwrapResult } from '@reduxjs/toolkit'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { getStructureName } from '../../device/Structure'
import { ErrorContainer } from '../../../app/ErrorContainer'
import { LoadingContainer } from '../../../app/LoadingContainer'
import { ButtonGroup, Paper, TableContainer } from '@mui/material'
import { QuickSearchToolbar } from '../../../app/QuickSearchToolbar'
import { FlexDiv } from '../../../app/Utils'
import { AddFabButton } from '../../../app/components/AddFabButton'
import { DocumentStatusLabel } from '../../documents/DocumentStatusLabel'
import { SystemDocumentTemplateHeader } from '../api/systemTypes'
import { CreateSystemDocumentTemplateDialog } from './CreateSystemDocumentTemplateDialog'

export const SystemDocumentTemplatesList = () => {
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [templates, setTemplates] = useState<SystemDocumentTemplateHeader[]>([]);
    const localeText = useDataGridCustomLocaleText();
    const [createDialogVisible, setCreateDialogVisible] = useState(false);
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);


    useEffect(() => {
        setIsLoading(true)
        dispatch(loadSystemDocumentTemplates())
            .then(unwrapResult)
            .then(setTemplates)
            .catch((error) => { console.log(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false));
    }, [])

    const handleGoToClick = (id) => {
        navigate(`/sys/document-templates/edit/${id}`)
    };

    const onSucces = (template) => {
        setCreateDialogVisible(false);
        handleGoToClick(template.id)
    }

    const handleHideDialog = () => {
        setCreateDialogVisible(false);
    }

    const openCreateDialog = () => {
        setCreateDialogVisible(true);
    };

    const columns: GridColDef[] = [
        {
            field: 'no',
            headerName: l10n.getString('document-number', null, 'Numer'),
            width: 200,
        },
        {
            field: 'title',
            headerName: l10n.getString('document-title', null, '_Title'),
            width: 300,
        },
        {
            field: 'title_en',
            headerName: l10n.getString('document-title-en', null, '_Title'),
            width: 300,
        },
        {
            field: 'structureId',
            headerName: l10n.getString("structure", null, "_structure"),
            minWidth: 200,
            valueGetter: (_, row) => {
                return row.structureId ? getStructureName(row.structureId) : "";
            }
        },
        {
            field: 'language',
            headerName: l10n.getString('document-language', null, '_language'),
            width: 300,
        },
        {
            field: 'status',
            headerName: l10n.getString('project-status', null, 'Status'),
            width: 150,
            renderCell: (params) => (
                <div>
                    <DocumentStatusLabel status={params.row.status ?? ""} />
                </div>
            )
        },
        {
            field: 'actions', type: 'actions', width: 300,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button onClick={() => handleGoToClick(params.row)} color="secondary" variant="outlined">
                            <Localized id="go-to">
                                <span>_go</span>
                            </Localized>
                        </Button>

                    </ButtonGroup>
                )
            }
        }
    ];


    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    return <>
        <TableContainer component={Paper}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        style={{ minHeight: 100, height: 'calc(100vh - 250px)' }}
                        localeText={localeText}
                        rows={templates}
                        columns={columns}
                        slots={{ toolbar: QuickSearchToolbar }}
                        onRowDoubleClick={({ row }) => handleGoToClick(row.id)} />
                </div>
            </div>
            <FlexDiv>
                <AddFabButton onClick={openCreateDialog} />
            </FlexDiv>
        </TableContainer>
        {
            createDialogVisible && <CreateSystemDocumentTemplateDialog onSucces={onSucces} onCancel={handleHideDialog} />
        }
    </>
}
