import { Box, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Localized } from "@fluent/react";
import { selectUserCompanyId } from "../../../user/userSlice";
import { Company, loadCompany } from "../../../company/companiesSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StarIcon from "@mui/icons-material/Star";
import { ErrorContainer } from "../../../../app/ErrorContainer";
import { LoadingContainer } from "../../../../app/LoadingContainer";
import { Help } from "../../../dialogs/HelpDialog";

export const SubscriptionCard: React.FC = () => {
    const dispatch = useAppDispatch()
    const companyId = useAppSelector(selectUserCompanyId) as string
    const [company, setCompany] = useState<Company>();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);

    useEffect(() => {
        if (companyId) {
            setIsLoading(true);
            dispatch(loadCompany(companyId))
                .then(unwrapResult)
                .then(setCompany)
                .catch((error) => { console.log(error); setIsError(true); setError(error); })
                .finally(() => setIsLoading(false));
        }
    }, [companyId]);

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return (
        <Card sx={{ maxWidth: 500, borderRadius: 2, boxShadow: 3 }}>
            <CardContent>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    <Localized id="company-settings-subscription-information">_Informacje o subskrypcji</Localized>
                </Typography>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <AccountCircleIcon color="primary" sx={{ marginRight: 1 }} />
                            <TextField
                                label={<Localized id="company-settings-users">_Użytkownicy</Localized>}
                                value={`${company?.usersCount || 0} / ${company?.userLimit || 0}`}
                                InputProps={{ readOnly: true }}
                                variant="outlined"
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <StarIcon color="secondary" sx={{ marginRight: 1 }} />
                            <TextField
                                label={<Localized id="company-settings-subscription">_Subskrypcja</Localized>}
                                value={company?.license || ""}
                                InputProps={{ readOnly: true }}
                                variant="outlined"
                                fullWidth
                            />
                            <Help id="c0a5e5d9-6624-4d92-a0b2-423176b1bf94" />
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
