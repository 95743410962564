import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { getWithAuth, postWithAuth } from "../../http";
import { AppDataState, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { isSameRequestId } from "../../app/crud";
import { RootState } from "../../app/store";

export interface StructureDocumentTemplates {
    id: number;
    structureId: number;
    name: string;
    no: string;
    type: string;
}

export interface StructureAccess {
    id: number;
    structureId: number;
    name: string;
    access: boolean;
}

interface ModuleStruct {
    moduleId: string
    structureId: string
}

interface CompanyStruct {
    companyId: string
    structureId: string
}

export const loadStructureDocumentTemplates = 
    createAsyncThunk<StructureDocumentTemplates[], void, AppThunkAPIType>("structure/load-templates", async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: "api/sys/Structure/document-templates"
        }))
        const { payload } = result 
        if (getWithAuth.fulfilled.match(result)) {
            return payload as StructureDocumentTemplates[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadStructureModules = 
    createAsyncThunk<StructureAccess[], string, AppThunkAPIType>("structure/load-structure-modules-access", async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/sys/Structure/${id}/modules`
        }))
        const { payload } = result 
        if (getWithAuth.fulfilled.match(result)) {
            return payload as StructureAccess[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadStructureCompanies = 
    createAsyncThunk<StructureAccess[], string, AppThunkAPIType>("structure/load-structure-companies-access", async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/sys/Structure/${id}/companies`
        }))
        const { payload } = result 
        if (getWithAuth.fulfilled.match(result)) {
            return payload as StructureAccess[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const createModuleStructure = 
    createAsyncThunk<ModuleStruct[], ModuleStruct[], AppThunkAPIType>("module_structure_access/save", async (items, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/sys/module-structure-access`,
            payload: items,
        }))
        if (postWithAuth.fulfilled.match(result)) {
            return items
        } else {
            const { payload } = result 
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const deleteModuleStructure = 
    createAsyncThunk<ModuleStruct[], ModuleStruct[], AppThunkAPIType>("module_structure_access/delete", async (items, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/sys/module-structure-access/delete`,
            payload: items,
        }))
        if (postWithAuth.fulfilled.match(result)) {
            return items
        } else {
            const { payload } = result 
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const createCompanyStructure = 
    createAsyncThunk<CompanyStruct[], CompanyStruct[], AppThunkAPIType>("company_structure_access/save", async (items, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/sys/company-structure-access`,
            payload: items,
        }))
        if (postWithAuth.fulfilled.match(result)) {
            return items
        } else {
            const { payload } = result 
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const deleteCompanyStructure = 
    createAsyncThunk<CompanyStruct[], CompanyStruct[], AppThunkAPIType>("company_structure_access/delete", async (items, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/sys/company-structure-access/delete`,
            payload: items,
        }))
        if (postWithAuth.fulfilled.match(result)) {
            return items
        } else {
            const { payload } = result 
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })