import { ReactNode, useEffect, useMemo } from "react";
import { loadAreas, selectAreas } from "../features/areas/areaSlice";
import { loadCompanies, selectCompanies } from "../features/company/companiesSlice";
import { loadDevices, selectDevices } from "../features/device/deviceSlice";
import { loadDeviceTypes, selectDeviceTypes } from "../features/device/deviceTypesSlice";
import { buildRelationship } from "../features/device/structureRelationshipSlice";
import { loadAllStructure, loadStructure, selectStructure } from "../features/device/structureSlice";
import { loadUserModules, selectModule } from "../features/modules/moduleSlice";
import { loadProcesses, selectProcesses } from "../features/process/processSlice";
import { clearRemarksSlice, loadRemarks, selectRemarks } from "../features/process/processStageRemarkSlice";
import { clearProcessTests, loadProcessTests, selectProcessTests } from "../features/process/processTestsSlice";
import { clearProcessTestSteps, loadProcessTestSteps, selectProcessTestSteps } from "../features/process/processTestStepSlice";
import { loadRequirements, loadSysRequirements, selectRequirements } from "../features/requirements/RequirementsDictSlice";
import { loadRequirementsTypes, loadSysRequirementsTypes, selectRequirementsTypes } from "../features/requirements/RequirementsTypesSlice";
import { loadRequirementTest, loadSysRequirementTest, selectRequirementTestState } from "../features/requirements/requirementTestSlice";
import { loadMyTasks, selectMyTasks } from "../features/tasks/myTasksSlice";
import { loadUrsOfferList, selectUrsOffers } from "../features/ursOffer/ursOfferSlice";
import { loadSysUrsTemplatesList, loadUrsTemplatesList, selectUrsTemplates } from "../features/urstemplates/ursTemplatesSlice";
import { selectIsTokenValid, selectUserCompanyId } from "../features/user/userSlice";
import { loadUsers, selectUsers } from "../features/users/usersSlice";
import { loadActivities, loadSysActivities, selectActivities } from "../features/validations/activitiesSlice";
import { loadSysValidations, loadValidations, selectValidations, ValidationStage } from "../features/validations/validationsSlice";
import { AppDataState, AppId } from "./appTypes";
import { useAppDispatch, useAppSelector } from "./hooks";
import { loadSuppliers, selectSuppliers } from "../features/suppliers/suppliersSlice";
import {loadUrsList, selectUrs} from "../features/urs/ursListSlice";
import { loadSupplierDeviceDetails, selectSupplierDeviceDetails } from "../features/supplierdevices/supplierDeviceDetailsSlice";
import {loadSupplierDevices, selectSupplierDevices} from "../features/supplierdevices/supplierDeviceSlice";
import { loadMaterialCardItems, selectMaterialCardItems } from "../features/project/materialCards/materialCardItemSlice";
import { loadMaterialCards, selectMaterialCards } from "../features/project/materialCards/materialCardSlice";
import { loadProjects } from "../features/project/projectSlice";
import { loadBusinessCases, selectBusinessCases } from "../features/businessCase/businessCaseSlice";
import { loadSpecs, selectSpecs } from "../features/specs/specSlice";
import { useLocalStorage } from "../useLocalStorage";
import { URSListFilters } from "../features/documents/utils/types";
import { defURSFilters, filtersToQueryString } from "../features/documents/utils/helper";
import { ErrorContainer } from "./ErrorContainer";

export interface AppDataLoaderProps {
    component: ReactNode
    children: ReactNode
}

const unauthorized = (state: AppDataState): boolean => {
    const { type } = state
    switch (type) {
        case "error":
            if (state.error.kind === "http") {
                return state.error.status === 401
            } else {
                return false
            }
        default:
            return false
    }
}

const empty = (state: AppDataState): boolean => {
    return state.type === "empty"
}

//!! case "empty": Co wyświetlamy? (Zanim endpoint zostanie wysłany pojawia się tymczasowo podany component) 

export const LoadUsers = ({ component, children }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { items } = useAppSelector(selectUsers)
    const { status } = items
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && (empty(status) || unauthorized(status))) {
            dispatch(loadUsers())
        }
    }, [dispatch, status, validToken])

    switch (status.type) {
        case "error":
            return <ErrorContainer error={null}/>
        case "empty":
        case "loading":
            return <>{component}</>    
        case "loaded":
            return <>{children}</>
        default:
            return <></>
    }
}

export const LoadCompanies = ({ component, children }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { loaded } = useAppSelector(selectCompanies)
    const currentModule = useAppSelector(state => selectModule(state).currentModule) 

    useEffect(() => {
        if (!loaded) {
            dispatch(loadCompanies())
        }
    }, [dispatch, loaded])

    switch (loaded) {
        case false:
            return <>{component}</>    
        case true:
            return <>{children}</>
    }
}

export const LoadDeviceTypes = ({ component, children }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectDeviceTypes)
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && (empty(state) || unauthorized(state))) {
            dispatch(loadDeviceTypes())
        }
    }, [dispatch, state, validToken])

    switch (state.type) {
        case "error":
            return <ErrorContainer error={null} />
        case "empty":
        case "loading":
            return <>{component}</>    
        case "loaded":
            return <>{children}</>
    }
}

export const LoadDevices = ({ component, children }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state, moduleId: loadedModuleId } = useAppSelector(selectDevices)
    const currentModule = useAppSelector(state => selectModule(state).currentModule) 
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && currentModule !== null && currentModule.code !== "super") {
            if (empty(state) || unauthorized(state) || (state.type === "loaded" && loadedModuleId !== currentModule.sysModuleId)) {
                dispatch(loadDevices(currentModule.sysModuleId))
            }
        }
    }, [dispatch, state, currentModule, loadedModuleId, validToken])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>    
        case "loaded":
            return <>{children}</>
    }
}

export const LoadValidations = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const state = useAppSelector(selectValidations).state
    const module = useAppSelector(state => selectModule(state).currentModule?.code)
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && (empty(state) || unauthorized(state))) {
            if (module === "super") {
                dispatch(loadSysValidations())
            } else if (module !== undefined) {
                dispatch(loadValidations())
            }
        }
    }, [dispatch, state, module, validToken])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }
}

export const LoadRequirementTestMappings = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const state = useAppSelector(selectRequirementTestState)
    const module = useAppSelector(state => selectModule(state).currentModule?.code)
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && (empty(state) || unauthorized(state))) {
            if (module === "super") {
                dispatch(loadSysRequirementTest())
            } else {
                dispatch(loadRequirementTest())
            }
        }
    }, [dispatch, state, module, validToken])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }
}

export const LoadSupplierDevices = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { loaded: state } = useAppSelector(selectSupplierDevices)

    useEffect(() => {
        if (!state) {
            dispatch(loadSupplierDevices())
        }
    }, [dispatch])

    switch (state) {
        case false:
            return <>{component}</>    
        case true:
            return <>{children}</>
    }
}

export const LoadSupplierDeviceDetails = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { loaded: state } = useAppSelector(selectSupplierDeviceDetails)

    useEffect(() => {
        if (!state) {
            dispatch(loadSupplierDeviceDetails())
        }
    }, [dispatch])

    switch (state) {
        case false:
            return <>{component}</>    
        case true:
            return <>{children}</>
    }
}

export const LoadMaterialCards = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { loaded: state } = useAppSelector(selectMaterialCards)

    useEffect(() => {
        if (!state) {
            dispatch(loadMaterialCards())
        }
    }, [dispatch])

    switch (state) {
        case false:
            return <>{component}</>    
        case true:
            return <>{children}</>
    }
}

export const LoadMaterialCardItem = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { loaded: state } = useAppSelector(selectMaterialCardItems)

    useEffect(() => {
        if (!state) {
            dispatch(loadMaterialCardItems())
        }
    }, [dispatch])

    switch (state) {
        case false:
            return <>{component}</>    
        case true:
            return <>{children}</>
    }
}

export const LoadActivities = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectActivities)
    const module = useAppSelector(state => selectModule(state).currentModule?.code)
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && (empty(state) || unauthorized(state))) {
            if (module === "super") {
                dispatch(loadSysActivities())
            } else {
                dispatch(loadActivities())
            }
        }
    }, [dispatch, state, module, validToken])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }
}

export const LoadProcesses = ({ component, children }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { loaded: state } = useAppSelector(selectProcesses)

    const currentModule = useAppSelector(state => selectModule(state).currentModule) 

     useEffect(() => {
        if (currentModule !== null && currentModule.code !== "super") {
            if (!state) {
                dispatch(loadProcesses(currentModule.sysModuleId))
            }
        }
    }, [dispatch, state, currentModule])


    switch (state) {
        case false:
            return <>{component}</>    
        case true:
            return <>{children}</>
    }
}

export const LoadStageRemarks = 
    (props: AppDataLoaderProps & { stageId?: AppId }) => {
        const { children, component, stageId } = props
        const dispatch = useAppDispatch()
        const { status, key: sliceKey } = useAppSelector(selectRemarks)

        useEffect(() => {
            if (status.type === "loaded" && sliceKey !== stageId) {
                dispatch(clearRemarksSlice())
            }
        }, [dispatch, status, sliceKey, stageId])

        useEffect(() => {
            if (status.type === "empty" && stageId) {
                dispatch(loadRemarks({
                    stageId: stageId,
                }))
            }
        }, [dispatch, status,  stageId])

        switch (status.type) {
            case "loaded":
                return stageId === sliceKey ? <>{children}</> : null
            default:
                return <>{component}</>
        }
    }

export const LoadProcessTests = 
    (props: AppDataLoaderProps & { processId: AppId, stage: ValidationStage }) => {
        const { children, component, processId, stage } = props
        const dispatch = useAppDispatch()
        const { status, key: sliceKey } = useAppSelector(selectProcessTests)

        useEffect(() => {
            if (status.type === "loaded" && (sliceKey?.processId !== processId || sliceKey.stage !== stage)) {
                dispatch(clearProcessTests())
            }
        }, [dispatch, status, sliceKey, processId, stage])

        useEffect(() => {
            if (status.type === "empty") {
                dispatch(loadProcessTests({
                    procId: processId,
                    stage,
                }))
            }
        }, [dispatch, status, stage, processId])

        switch (status.type) {
            case "loaded":
                if (processId === sliceKey?.processId && stage === sliceKey.stage) {
                    return <>{children}</>
                } else {
                    return null
                }
            default:
                return <>{component}</>
        }
    }

export const LoadProcessTestSteps = 
    (props: AppDataLoaderProps & { testId: AppId }) => {

        const { children, component, testId } = props
        const dispatch = useAppDispatch()
        const { status, testId: sliceTestId } = useAppSelector(selectProcessTestSteps)

        useEffect(() => {
            if (status.type === "loaded" && sliceTestId !== testId) {
                dispatch(clearProcessTestSteps())
            }
        }, [dispatch, status, sliceTestId, testId])

        useEffect(() => {
            if (status.type === "empty") {
                dispatch(loadProcessTestSteps(testId))
            }
        }, [dispatch, status, testId])

        switch (status.type) {
            case "empty":
            case "loading":
            case "error":        
                return <>{component}</>
            case "loaded":
                return testId === sliceTestId ? <>{children}</> : null
        }
    }


export const LoadURSTemplates = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state , moduleId: loadedModuleId } = useAppSelector(selectUrsTemplates)
    const currentModule = useAppSelector(state => selectModule(state).currentModule) 
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && currentModule !== null ) {
            if (currentModule.code === "super" && (empty(state) || unauthorized(state))) {
                dispatch(loadSysUrsTemplatesList())
            }
            else {
                if (empty(state) || unauthorized(state) || (state.type === "loaded" && loadedModuleId !== currentModule.sysModuleId)){
                    dispatch(loadUrsTemplatesList(currentModule.sysModuleId))
                } 
            }
        }
    }, [dispatch, state, currentModule, loadedModuleId, validToken])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }
}

export const LoadURSs = ({ children, component, filter }: AppDataLoaderProps & { filter: string }) => {
    const dispatch = useAppDispatch()
    const { state, moduleId: loadedModuleId } = useAppSelector(selectUrs)
    const currentModule = useAppSelector(state => selectModule(state).currentModule) 
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && currentModule !== null && currentModule.code !== "super") {
            if (empty(state) || unauthorized(state) || (state.type === "loaded" && loadedModuleId !== currentModule.sysModuleId)) {
                dispatch(loadUrsList({ moduleId: currentModule.sysModuleId, filter }))
            }
        }
    }, [dispatch, state, currentModule, loadedModuleId, validToken])

    switch (state.type) {
        case "error":
            return <ErrorContainer error={null}/>
        case "empty":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }
}

export const LoadURSOffers = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectUrsOffers)
    const currentModule = useAppSelector(state => selectModule(state).currentModule) 
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && currentModule !== null && currentModule.code !== "super") {
            if (empty(state) || unauthorized(state)) {
                dispatch(loadUrsOfferList({
                    moduleId: currentModule.sysModuleId,
                    filter: "",
                }))
            }
        }
    }, [dispatch, state, currentModule, validToken])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }
}

export const LoadRequirementsTypes = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectRequirementsTypes)
    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (currentModule !== null && validToken){
            if((empty(state || unauthorized(state))) && currentModule.code === "super") {
                dispatch(loadSysRequirementsTypes())
            }
            else {
                if (empty(state || unauthorized(state))) {
                    dispatch(loadRequirementsTypes())
                }
            }
        }    
    }, [dispatch, state, currentModule, validToken])

    switch (state.type) {
        case "error":
            return <ErrorContainer error={null} />
        case "empty":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }

}

export const LoadRequirements = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state, moduleId: loadedModuleId } = useAppSelector(selectRequirements)
    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (currentModule !== null && validToken){
            if(currentModule.code === "super" && (empty(state) || unauthorized(state))) {
                dispatch(loadSysRequirements())
            }
            else {
                if (empty(state) || unauthorized(state) || (state.type === "loaded" && loadedModuleId !== currentModule.sysModuleId)) {
                    dispatch(loadRequirements({ moduleId: currentModule.sysModuleId }))
                }
            }
        }
    }, [dispatch, state, currentModule, loadedModuleId, validToken])

    switch (state.type) {
        case "error":
            return <ErrorContainer error={null} />
        case "empty":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }
}

export const LoadAreas = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectAreas)
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && (empty(state) || unauthorized(state))) {
            dispatch(loadAreas())
        }
    }, [dispatch, state, validToken])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }
}

export const LoadStructure = ({ children, component, }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectStructure)
    const currentModule = useAppSelector(state => selectModule(state).currentModule) 
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        const load = async () => {
            if (currentModule?.code === "super") {
                await dispatch(loadAllStructure())
            }
            else if (currentModule !== null) {
                await dispatch(loadStructure(currentModule.code))
            }
            await dispatch(buildRelationship())
        }
        if (validToken && (empty(state) || unauthorized(state))) {
            load()
        }
    }, [dispatch, state, currentModule, validToken])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }
}

export const LoadModules = ({ children, component, }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectModule)
    const companyId = useAppSelector(selectUserCompanyId)
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && companyId !== null && (empty(state) || unauthorized(state))) {
            dispatch(loadUserModules(companyId))
        }
    }, [dispatch, state, companyId, validToken])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }

}

export const LoadMyTasks = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectMyTasks)
    const validToken = useAppSelector(selectIsTokenValid)

    useEffect(() => {
        if (validToken && (empty(state) || unauthorized(state))) {
            dispatch(loadMyTasks())
        }
    }, [dispatch, state, validToken])

    switch (state.type) {
        case "error":
            return <ErrorContainer error={null} />
        case "empty":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    } 
}

export const LoadSuppliers = ({ component, children }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectSuppliers)

    useEffect(() => {
        if (empty(state)) {
            dispatch(loadSuppliers())
        }
    }, [dispatch, state])

    switch (state.type) {
        case "error":
            return <ErrorContainer error={null}/>
        case "empty":
        case "loading":
            return <>{component}</>    
        case "loaded":
            return <>{children}</>
    }
}

export const LoadProjects = ({ children, component }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { loaded } = useAppSelector(state => state.projects)

    useEffect(() => {
        if (!loaded) {
            dispatch(loadProjects())
        }
    }, [loaded])

    switch (loaded) {
        case false: return <>{component}</>
        case true: return <>{children}</>
    }
}

export const LoadBusinessCases = ({ children, component, }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectBusinessCases)

    useEffect(() => {
        if (empty(state)) {
            dispatch(loadBusinessCases())
        }
    }, [state])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }

}


export const LoadSpecs = ({ children, component, }: AppDataLoaderProps) => {
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectSpecs)

    useEffect(() => {
        if (empty(state)) {
            dispatch(loadSpecs())
        }
    }, [state])

    switch (state.type) {
        case "empty":
        case "error":
        case "loading":
            return <>{component}</>
        case "loaded":
            return <>{children}</>
    }

}
