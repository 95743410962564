import { Container, Paper, TableContainer, Button } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useEffect, useMemo } from "react"
import { useAppDispatch, useAppSelector, useDataGridCustomLocaleText } from "../../app/hooks"
import { loadSpecs, selectAllSpecs, selectSpecs, Spec } from "./specSlice"
import { Localized, useLocalization } from "@fluent/react"
import { LoadingContainer } from "../../app/LoadingContainer"
import { useNavigate, useSearchParams } from "react-router-dom"
import { FlexDiv, parseIntOrDef } from "../../app/Utils"
import { QuickSearchToolbar } from "../../app/QuickSearchToolbar"
import { getStructureName } from '../device/Structure'
import NewSmallBadge from "../../app/components/NewSmallBadge"
import { AddFabButton } from "../../app/components/AddFabButton"
import { selectContextStructure } from "../structure/contextStructureSlice"
import { selectAllStructure, selectStructureAndChildren } from '../device/structureRelationshipSlice'

export const SpecList = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { l10n } = useLocalization()
    const [searchParams, setSearchParams] = useSearchParams()
    const { state } = useAppSelector(selectSpecs)
    const allSpecs = useAppSelector(selectAllSpecs)
    const localeText = useDataGridCustomLocaleText();
    const { data: structure } = useAppSelector(selectContextStructure)
    const relationship = useAppSelector(state => {
        if (structure && structure.mode === 'manual') {
            return selectStructureAndChildren(state, structure.structId)
        } else {
            return selectAllStructure(state)
        }
    })

    const specs = useMemo(() => {
            return allSpecs.filter(({ structureId }) => relationship.has(structureId ?? ''));
    }, [allSpecs, relationship])

    const handleCreateNewSpecClick = async () => {
        navigate(`/specs/create`)
    }

    useEffect(() => {
        if (state.type === 'empty') {
            dispatch(loadSpecs())
        }
    }, [state])

    const page: number = parseIntOrDef(searchParams.get('page'), 0)
    const pageSize: number = parseIntOrDef(searchParams.get('pageSize'), 20)

    const isToday = (dateString: string) => {
        const date = new Date(dateString);
        const today = new Date();

        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };

    const handleGoToClick = (id) => {
        navigate(`/specs/edit/${id}`)
    };


    const cols: GridColDef<Spec, any, any>[] = useMemo(() => {
        return [
            {
                field: 'type',
                width: 150,
                headerName: l10n.getString('spec-type', null, '_type')
            },
            {
                field: 'name',
                headerName: l10n.getString('spec-name', null, 'Nazwa'),
                flex: 1
            },
            {
                field: 'structureId',
                headerName: l10n.getString("structure", null, "_structure"),
                minWidth: 200,
                valueGetter: (_, row) => {
                    return row.structureId ? getStructureName(row.structureId) : "";
                }
            },
            {
                field: 'createDate',
                width: 150,
                headerName: l10n.getString('spec-created', null, 'Data utworzenia'),
                valueGetter: (_, row) => row.createDate ? new Date(row.createDate) : null,
                renderCell: (params) => {
                    if (!params.value) return "";
                    const formattedDate = params.value.toISOString().split('T')[0];
                    const isNew = isToday(params.row.createDate);

                    return (
                        <>
                            {formattedDate}
                            {isNew && <NewSmallBadge />}
                        </>
                    );
                },
            },
            {
                field: 'actions', type: 'actions', width: 150,
                renderCell: (params) => {
                    return (
                        <Button onClick={() => handleGoToClick(params.row.id)} color="secondary" variant="outlined">
                            <Localized id="go-to">
                                <span>go-to</span>
                            </Localized>
                        </Button>
                    )
                }
            }
        ]
    }, [l10n])

    const qfInitialValue: string[] = useMemo(() => searchParams.getAll('qf'), [])

    switch (state.type) {
        case 'empty':
        case 'loading':
            return <LoadingContainer />
        case 'error':
            return <Container component={Paper} maxWidth='lg'>
                <h5>Data not loaded due to error!</h5>
            </Container>
        case 'loaded':
            return <> <TableContainer component={Paper}>
                <DataGrid
                    columns={cols}
                    rows={specs}
                    slots={{ toolbar: QuickSearchToolbar }}
                    style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                    localeText={localeText}
                    onRowDoubleClick={({ row }) => handleGoToClick(row.id)}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                        }
                    }}
                    paginationModel={{ pageSize, page }}
                    onPaginationModelChange={(m) => {
                        searchParams.set('page', m.page.toString())
                        searchParams.set('pageSize', m.pageSize.toString())
                        setSearchParams(searchParams, { replace: true })
                    }}
                    initialState={{
                        filter: {
                            filterModel: {
                                items: [],
                                quickFilterValues: qfInitialValue,
                            },
                        },
                        sorting: { sortModel: [{ field: 'createDate', sort: 'desc' }] }
                    }}
                    onFilterModelChange={(m) => {
                        searchParams.delete('qf')
                        m.quickFilterValues?.forEach(val => {
                            searchParams.set('qf', val)
                        })
                        setSearchParams(searchParams)
                    }}
                />
                <FlexDiv>
                    <AddFabButton onClick={handleCreateNewSpecClick} />
                </FlexDiv>
            </TableContainer>
            </>
    }
}
