import React from "react";
import { Box, Typography, Paper, Chip, Stack } from "@mui/material";
import { SystemDocumentTemplate } from "../api/systemTypes";
import StatusIndicator from "./StatusIndicator";

interface SystemTemplateHeaderProps {
    documentTemplate: SystemDocumentTemplate;
}

const SystemTemplateHeader: React.FC<SystemTemplateHeaderProps> = ({ documentTemplate }) => {
    const { version, structureId, type, title, no, status } = documentTemplate;

    return (
        <Paper
            elevation={1}
            sx={{
                p: 2,
                borderRadius: 1,
                backgroundColor: "#f5f5f5", // Delikatne szare tło
                position: "relative"
            }}
        >
            <Box>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Chip label={`Number: ${no}`} color="primary" />
                    <Typography variant="h5" fontWeight="bold">
                        | {title}
                    </Typography>
                </Stack>

                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    <span style={{ fontWeight: "bold" }}>Version:</span> {version} |{" "}
                    <span style={{ fontWeight: "bold" }}>Type:</span> {type} |{" "}
                    <span style={{ fontWeight: "bold" }}>Structure:</span> {structureId}
                </Typography>
            </Box>

            {/* Dedykowane miejsce na status po prawej stronie */}
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    right: 16,
                    transform: "translateY(-50%)"
                }}
            >
                <StatusIndicator status={status} />
            </Box>
        </Paper>
    );
};

export default SystemTemplateHeader;
