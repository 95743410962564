import { Localized } from "@fluent/react";
import { Box, Grid, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

interface AuthPageLayoutProps {
  children: ReactNode;
}

export const AuthPageLayout = ({ children }: AuthPageLayoutProps) => {
  const backgroundImage = `${process.env.PUBLIC_URL}/mainbackground.jpg`;
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [sentenceOfDay, setSentenceOfDay] = useState("");

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * 5) + 1;
    setWelcomeMessage(`welcome-sentence-${randomIndex}`);

    const ind = Math.floor(Math.random() * 5) + 1;
    setSentenceOfDay(`welcome-motivation-${ind}`);
  }, []);

  return (
    <Grid container sx={{ minHeight: "100vh" }}>
      {/* Left panel*/}
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Box />
        <Box sx={{ marginBottom: 2 }}>
          <img
            src={process.env.PUBLIC_URL + '/base_logo.png'}
            alt="Logo"
            style={{ maxWidth: "600px", height: "auto" }}
          />
        </Box>
      </Grid>

      {/* Right panel*/}
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f9f9f9",
          padding: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            mt: -12
          }}
        >
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" fontWeight="bold">
              <Localized id={welcomeMessage}>
                <span>{"Hello"}</span>
              </Localized>
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={{ fontStyle: "italic" }}
            >
              <Localized id={sentenceOfDay}>
                <span>""</span>
              </Localized>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: 4,
              borderRadius: 4,
              background: "rgba(255, 255, 255, 0.8)",
              boxShadow: "0 8px 24px rgba(0,0,0,0.1)",
              backdropFilter: "blur(10px)",
            }}
          >
            {children}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
