import { Box, Container, Paper, Fab, Dialog, DialogTitle, DialogActions, Button, DialogContent, TextField, Grid, Select, MenuItem, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbarQuickFilter } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { BusinessCase, loadBusinessCases, selectAllBusinessCases, selectBusinessCases } from "./businessCaseSlice"
import AddIcon from '@mui/icons-material/Add'
import { Localized, useLocalization } from "@fluent/react"
import { LoadingContainer } from "../../app/LoadingContainer"
import { useNavigate } from "react-router-dom"
import { selectSettings } from "../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import React from "react"
import { BusinessCasePrinter } from "./BusinessCasePrinter"
import { ErrorContainer } from "../../app/ErrorContainer"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>




const QuickSearchToolbar = () => {
    return <Box sx={{ p: 0.5, pb: 0 }}>
        <GridToolbarQuickFilter />
    </Box>
}

export const BusinessCaseList = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { locale } = useAppSelector(selectSettings);
    const { state } = useAppSelector(selectBusinessCases)
    const allBusinessCases = useAppSelector(selectAllBusinessCases)
    const { l10n } = useLocalization()

    const [open, setOpen] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleOpenDialog = () => setDialogOpen(true);
    const [presentationTitle, setPresentationTitle] = useState("");
    const [selectedBCIds, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [isPrintDialogVisible, setPrintDialogVisible] = useState<boolean>(false);
    const [filter, setFilter] = React.useState<Filter>({ year: null, month: null });
    const years = [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
    const months = [
        { value: 1, label: 'Styczeń' },
        { value: 2, label: 'Luty' },
        { value: 3, label: 'Marzec' },
        { value: 4, label: 'Kwiecień' },
        { value: 5, label: 'Maj' },
        { value: 6, label: 'Czerwiec' },
        { value: 7, label: 'Lipiec' },
        { value: 8, label: 'Sierpień' },
        { value: 9, label: 'Wrzesień' },
        { value: 10, label: 'Październik' },
        { value: 11, label: 'Listopad' },
        { value: 12, label: 'Grudzień' },
      ];

    const handleResetFilter = () => {
        setFilter({ year: null, month: null });
    };

    type Row = {
        id: number;
        name: string;
        date: string; // Zakładam, że data jest w formacie ISO (string)
    };

    type Filter = {
        year: number | null;
        month: number | null;
    };

    const filterRowsByDate = (rows: BusinessCase[], filter: Filter): BusinessCase[] => {
        if (!filter.year && !filter.month) return rows;


        return rows.filter((row) => {
            if (!row.capexDate) return false; // Ignoruj wiersze bez wartości w capexDate
            const date = new Date(row.capexDate);
            const isYearMatch = filter.year ? date.getFullYear() === filter.year : true;
            const isMonthMatch = filter.month ? date.getMonth() + 1 === filter.month : true;
            return isYearMatch && isMonthMatch;
        });
    }

    const filteredRows = filterRowsByDate(allBusinessCases, filter);


    const columns: GridColDef[] = [
        {
            field: 'title'
            , headerName: 'Tytuł'
            , flex: 1
        },
        {
            field: 'status'
            , headerName: 'Status'
        },
        {
            field: 'createDate',
            headerName: 'Utworzono',
            valueGetter: (_, row) => {
                return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
            }
        },
        {
            field: 'capexDate',
            headerName: l10n.getString("bc-approval-capex-month", null, "_Capex"),
            width: 150,
            valueGetter: (_, row) => {
                if (!row.capexDate) return "";

                const date = new Date(row.capexDate);
                return new Intl.DateTimeFormat('pl-PL', { month: 'long', year: 'numeric' }).format(date);
            }
        },
        {
            field: 'createdBy',
            headerName: l10n.getString("created-by", null, "_created by"),
        },
    ]

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setPresentationTitle("");
        setRowSelectionModel([]);
        setPrintDialogVisible(false);
    };

    const handleGeneratePresentation = () => {
        if (selectedBCIds.length > 0 && presentationTitle) {
            setDialogOpen(false);
            setPrintDialogVisible(true)
        }
    };

    const handleCreateNewBusinessCaseClick = async () => {
        navigate(`/businessCase/create`)
    }

    useEffect(() => {
        if (state.type === 'empty') {
            dispatch(loadBusinessCases())
        }
    }, [state])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    switch (state.type) {
        case 'empty':
        case 'loading':
            return <LoadingContainer />
        case 'error':
            return <ErrorContainer error={null}/>
        case 'loaded':
            return <><Container maxWidth='xl'>
                <h5>Business Case list</h5>
                <DataGrid
                    style={{ minHeight: 100, height: 'calc(100vh - 250px)' }}
                    localeText={getCustomLocaleText()}
                    columns={columns}
                    rows={allBusinessCases}
                    slots={{ toolbar: QuickSearchToolbar }}
                    onRowDoubleClick={({ row }) => navigate(`/businessCase/edit/${row.id}`)}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                        }
                    }}
                    initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
                />
                <Fab sx={{ mt: 2 }} color="secondary" onClick={handleCreateNewBusinessCaseClick}>
                    <AddIcon />
                </Fab>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                    style={{ marginTop: 16 }}
                >
                    Generuj prezentację
                </Button>

            </Container>
                <Dialog open={open} fullWidth maxWidth='lg'>
                    <DialogTitle>
                        <Localized id='new-business-case'>Nowy Business Case</Localized>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ pt: 2 }}>
                            <TextField
                                label={<Localized id='title'>Tytuł</Localized>}
                                placeholder='Tytuł'
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                fullWidth
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { }}>
                            <Localized id='save'>Zapisz</Localized>
                        </Button>
                        <Button onClick={() => setOpen(false)}>
                            <Localized id='cancel'>Anuluj</Localized>
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={dialogOpen} fullWidth maxWidth="xl" onClose={handleCloseDialog}>`
                    <DialogTitle>Generowanie Prezentacji</DialogTitle>
                    <DialogContent>

                        <TextField
                            fullWidth
                            margin="normal"
                            label="Tytuł prezentacji"
                            value={presentationTitle}
                            required
                            error={presentationTitle === ""}
                            helperText={presentationTitle === "" && <RequiredError />}
                            onChange={(e) => setPresentationTitle(e.target.value)}
                        />
                         <Paper elevation={1} style={{ padding: 2, marginBottom: 5 , textAlign: 'right'}}>
                            
                         <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                            <Grid item >
                                <Typography variant="h6" style={{ padding: 2, marginBottom: 2 }}>
                                    Capex
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Select
                                    value={filter.year ?? ''}
                                    onChange={(e) => setFilter({ ...filter, year: e.target.value ? Number(e.target.value) : null })}
                                    displayEmpty
                                    size="small"
                                >
                                    <MenuItem value="">Wszystkie lata</MenuItem>
                                    {years.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item>
                                <Select
                                    value={filter.month ?? ''}
                                    onChange={(e) => setFilter({ ...filter, month: e.target.value ? Number(e.target.value) : null })}
                                    displayEmpty
                                    size="small"
                                >
                                    <MenuItem value="">Wszystkie miesiące</MenuItem>
                                    {months.map((month) => (
                                        <MenuItem key={month.value} value={month.value}>
                                            {month.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" color="secondary" onClick={handleResetFilter}>
                                    x
                                </Button>
                            </Grid>
                        </Grid>
                        </Paper>
                        <div style={{ height: "60%", width: "100%" }}>
                            <DataGrid
                                rows={filteredRows}
                                columns={columns}
                                slots={{ toolbar: QuickSearchToolbar }}
                                checkboxSelection
                                rowSelectionModel={selectedBCIds}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRowSelectionModel(newRowSelectionModel);
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleGeneratePresentation} variant="contained" color="primary">
                            Generuj
                        </Button>
                        <Button onClick={handleCloseDialog} color="secondary">
                            Anuluj
                        </Button>
                    </DialogActions>
                </Dialog>
                {isPrintDialogVisible &&
                    <BusinessCasePrinter
                        businessCaseIds={selectedBCIds.map(id => String(id))}
                        language={""}
                        reportMode="multi"
                        mode={"download"}
                        isDialogOpen={true}
                        closeDialog={handleCloseDialog}
                        title={presentationTitle}
                    />
                }
            </>
    }
}
