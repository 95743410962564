import { Localized } from "@fluent/react";
import { Box, Typography } from "@mui/material";


export const ActiveUserLabel = () => {

    return (
        <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
            <Box
                sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    backgroundColor: "green"
                }}
            />
            <Typography variant="body2">
                <Localized id="user-status-active" />
            </Typography>
        </Box>
    );
};
