import { Localized } from "@fluent/react";
import { Box, Button } from "@mui/material";

export const CardActionButtons: React.FC<{ isEditing: boolean; onSave: () => void; onCancel: () => void; onEdit: () => void }> = ({ isEditing, onSave, onCancel, onEdit }) => (
    <Box display="flex" justifyContent="center" gap={1} mt={2}>
        {isEditing ? (
            <>
                <Button variant="contained" size="small" color="success" onClick={onSave}>
                    <Localized id="save">
                        <span>_save</span>
                    </Localized>
                </Button>
                <Button variant="outlined" size="small" color="secondary" onClick={onCancel}>
                    <Localized id="cancel">
                        <span>_cancel</span>
                    </Localized>
                </Button>
            </>
        ) : (
            <Button variant="contained" size="small" color="primary" onClick={onEdit}>
                <Localized id="edit">
                    <span>_edit</span>
                </Localized>
            </Button>
        )}
    </Box>
);
