import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { SaveButton } from "../../../../app/components/SaveButton";
import { CancelButton } from "../../../../app/components/CancelButton";
import { Localized } from "@fluent/react";

export const PasswordConfirmationDialog: React.FC<{
    saving: boolean,
    serverError?: string,
    onClose: () => void;
    onConfirm: (password: string) => void
}> = ({ saving, serverError, onClose, onConfirm }) => {
    const [password, setPassword] = useState("");

    const handleConfirm = () => {
        onConfirm(password);
    };

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>
                <Localized id="confirm-changes">
                    <span>_confirm</span>
                </Localized>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Localized id="confirm-changes-by-password">
                        <span>_password</span>
                    </Localized>
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label={<Localized id="password"><span>_password</span></Localized>}
                    type="password"
                    required={true}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {
                    serverError && <Alert sx={{ marginTop: 1, }}  severity="error">
                        <Localized id={serverError}>
                            <span>Server error</span>
                        </Localized>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <SaveButton loading={saving} onClick={handleConfirm}></SaveButton>
                <CancelButton loading={saving} onClick={onClose}></CancelButton>
            </DialogActions>
        </Dialog>
    );
};