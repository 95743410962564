import React, { useState, useCallback, ChangeEvent, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { Box, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Tab, TextField, Stack, DialogTitle } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Localized } from "@fluent/react";
import { approveStage, Process, rejectStage, finishStage, selectProcessById, sendStageToApprove, updateStagePurpose, Stage, updateStageSummary } from './processSlice';
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DeviceInfo } from '../device/DeviceInfo';
import { ValidationStage } from '../validations/validationsSlice';
import { Autocomplete, Skeleton } from '@mui/material';
import { LoadDevices, LoadProcesses, LoadProcessTests, LoadStageRemarks, LoadUsers, LoadValidations } from '../../app/AppDataLoader';
import { AppId, AppTextFieldHandler } from '../../app/appTypes';
import { changeAllTestApprover, changeAllTestPlannedDate, changeAllTestTester, copyProcessTestFromCatalog, deleteTest, ProcessTest, selectAllProcessTests } from './processTestsSlice';
import { format, formatISO, parseJSON } from "date-fns";
import { closeRemark, createRemark, deleteRemark, ProcessStageRemark, reopenRemark, selectAllRemarks } from './processStageRemarkSlice';
import { unwrapResult } from "@reduxjs/toolkit";
import { showError, showSuccess } from '../notifications/notificationsSlice';
import { RemarkStatusLabel } from './RemarkStatusLabel';
import { TestStatusLabel } from './TestStatusLabel';
import { StageStatusTeamInfo } from './tiles/StageStatusTeamInfo';
import { StagePurposeInfo } from './tiles/StagePurposeInfo';
import { FullName } from '../users/FullName';
import { BottomAppBar } from '../../app/BottomAppBar';
import { selectAllUsers, User } from "../users/usersSlice";
import { DatePicker, LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import { If } from "../../app/If";
import { ValidationsBrowser } from '../validations/ValidationsBrowser';
import produce from 'immer';
import { AppLink } from '../../app/AppLink';
import { UrsInfo } from './tiles/UrsInfo';
import { useNavigate } from 'react-router-dom';
import { RichTextEditor } from '../../app/RichTextEditor';
import PrintIcon from '@mui/icons-material/Print';
import OpenReport from './OpenReport';

const PREFIX = 'ProcessStage';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  step: `${PREFIX}-step`,
  head: `${PREFIX}-head`,
  button: `${PREFIX}-button`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`,
  heading: `${PREFIX}-heading`,
  stageColumn: `${PREFIX}-stageColumn`,
  fab: `${PREFIX}-fab`,
  commandsColumn: `${PREFIX}-commandsColumn`,
  tableRow: `${PREFIX}-tableRow`,
  tableCell: `${PREFIX}-tableCell`,
  flex: `${PREFIX}-flex`,
  pushRight: `${PREFIX}-pushRight`,
  hover: `${PREFIX}-hover`,
  selected: `${PREFIX}-selected`,
  descriptionField: `${PREFIX}-descriptionField`,
  approver: `${PREFIX}-approver`,
  fieldWrapper: `${PREFIX}-fieldWrapper`
};

const empty: Set<AppId> = new Set()

type UriParams = {
  id: string,
  stage: ValidationStage,
}

export const ProcessStage = () => {
  const { id: paramId, stage: paramStage } = useParams<UriParams>()
    const id = paramId ?? ''
    const stage = paramStage ?? 'IQ'

  return (
    <LoadProcesses component={<Skeleton variant="rectangular" height={400} />}>
      <LoadDevices component={<Skeleton variant="rectangular" height={400} />}>
        <StageData id={id} stage={stage} />
      </LoadDevices>
    </LoadProcesses>
  );
}

type ActiveTab = "tests" | "remarks" | "summary"

export const StageData = ({ id, stage }: { id: AppId, stage: ValidationStage }) => {
  const process = useAppSelector(state => selectProcessById(state, id)) as Process
  const stageEntity = process.stages.find(x=>x.stage === stage) as Stage
  const stageTests = useAppSelector(selectAllProcessTests);

  const [activeTabId, setActiveTab] = useState<ActiveTab>("tests");

  const handleSwitchTab = (_: React.ChangeEvent<{}>, tabId: ActiveTab) => {
    setActiveTab(tabId);    
  };


  const navigate = useNavigate()

  const handleBackClick = useCallback(() => {
    navigate(-1)
}, [navigate])
  
  const dispatch = useAppDispatch()

  const [overVisible] = React.useState("");
  const [showEditPurposeDialog, setShowEditPurposeDialog] = React.useState(false);
  const [showEditSummaryDialog, setShowEditSummaryDialog] = React.useState(false);
  
  const [authAction, setAuthAction] = useState<"approve" | "finish" | undefined>(undefined)
  const [password, setPassword] = useState("")

  const [running, setRunning] = useState(false)

  const handleSendToApproveClick = async () => {
    if(stageEntity && stageEntity.id){
      try {
        unwrapResult(await dispatch(sendStageToApprove(stageEntity.id)));
        dispatch(showSuccess("saved"))
      } catch (error) {
        dispatch(showError("error"))
      }     
    }
  }

  const handleApproveClick = () => {
    setPassword("")
    setAuthAction("approve")
  }

  const handlePasswordFieldChange: AppTextFieldHandler = useCallback(e => {
      setPassword(e.target.value)
  }, [])

  const handleAuthActionOkClick = async () => {
    switch (authAction) {
      case "approve":
        if(stageEntity && stageEntity.id) {
          setRunning(true)
          const result = await dispatch(approveStage({ stageId: stageEntity.id, password, }))

          if (approveStage.fulfilled.match(result)) {
            dispatch(showSuccess("saved"))
            setAuthAction(undefined)
          } else {
            let errorMsg = "error"
            if (result.payload) {
                if (result.payload.kind === 'http') {
                    const problem = result.payload.problem
                    if (problem) {
                        errorMsg = problem.title
                    }
                }
            }
            dispatch(showError(errorMsg))
            setRunning(false)
          }
        }
        
        break
      case "finish":
        if(stageEntity && stageEntity.id) {
            const result = await dispatch(finishStage({ stageId: stageEntity.id, password, processId: id, }))
            if (finishStage.fulfilled.match(result)) {
              dispatch(showSuccess("saved"))
              setAuthAction(undefined)
            } else {
              let errorMsg = "error"
              if (result.payload) {
                  if (result.payload.kind === 'http') {
                      const problem = result.payload.problem
                      if (problem) {
                          errorMsg = problem.title
                      }
                  }
              }
              dispatch(showError(errorMsg))
              setRunning(false)
            }
        }

        break
      default:
        break
    }
  }
  const handleAuthActionCancelClick = useCallback(() => {
    setAuthAction(undefined)
  }, [])

  const handleRejectClick = async () => {
    if(stageEntity && stageEntity.id){
      setRunning(true)
      const result = await dispatch(rejectStage(stageEntity.id))

      if (rejectStage.fulfilled.match(result)) {
        dispatch(showSuccess("saved"))
      } else {
        let errorMsg = "error"
        if (result.payload) {
            if (result.payload.kind === 'http') {
                const problem = result.payload.problem
                if (problem) {
                    errorMsg = problem.title
                }
            }
        }
        dispatch(showError(errorMsg))
      }
      setRunning(false)
    }
  }

  const [showErrorFinishDialog, setShowErrorFinishDialog] = useState<boolean>(false);

  const handleFinishClick = async () => {

    let z =  stageTests.find(x=>x.hasErrors)
    if (z)
    {
      setShowErrorFinishDialog(true)
    }
    else{
      setPassword("")
      setAuthAction("finish")
    }
  }


  const handleOkErrorFinishClick = async () => {
    setShowErrorFinishDialog(false);
    setPassword("")
    setAuthAction("finish")
  }  

  const handleCancelErrorFinishClick = async () => {
    setShowErrorFinishDialog(false);
  }  
  

  const [purpose, setPurpose] = useState<string>(stageEntity?.purpose ?? "");
  const [stageSummary, setStageSummary] = useState<string>(stageEntity?.stageSummary ?? "");
  
  const handlePurposeEditClick = async () => {
    setShowEditPurposeDialog(true);
  }

  const handleSavePurposeClick = async () => {
    if(stageEntity && stageEntity.id){
      try {
        unwrapResult(await dispatch(updateStagePurpose({stageId: stageEntity.id,purpose: purpose})));
        dispatch(showSuccess("saved"))
      } catch (error) {
        dispatch(showError("error"))
      }     
      setShowEditPurposeDialog(false);
    }
  }

  const handleSaveSummaryClick = async () => {
    if(stageEntity && stageEntity.id){
      try {
        unwrapResult(await dispatch(updateStageSummary({stageId: stageEntity.id, stageSummary: stageSummary})));
        dispatch(showSuccess("saved"))
      } catch (error) {
        dispatch(showError("error"))
      }     
      setShowEditSummaryDialog(false);
    }
  }  



  const handleCancelSummaryClick = async () => {
    setShowEditSummaryDialog(false);
  }

  const handleCancelPurposeClick = async () => {
    setShowEditPurposeDialog(false);
  }

  const handleSummaryEditClick = async () => {
    setShowEditSummaryDialog(true);
  }
  
  const handlePurposeChange: (e: ChangeEvent<HTMLInputElement>) => void = 
    useMemo(() => 
      (e) => setPurpose(e.target.value), [setPurpose]);

  const handleStageSummaryChange: (e: ChangeEvent<HTMLInputElement>) => void = 
    useMemo(() => 
      (e) => setStageSummary(e.target.value), [setStageSummary]);

  
  if (!process) return <Localized id="error-entity-not-exists"><span>Obiekt nie istnieje</span></Localized>

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {process.ursId && !process.deviceId && 
          <>       
            <Grid item xs={5}>
              <UrsInfo id={process.ursId}/>
            </Grid>
          </>
        } 
        {process.ursId && process.deviceId && 
          <>       
            <Grid item xs={2}>
              <DeviceInfo id={process.deviceId} />
            </Grid>
            <Grid item xs={3}>
              <UrsInfo id={process.ursId}/>
            </Grid>
          </>
        }   
         {!process.ursId && process.deviceId &&
          <Grid item xs={5}>
            <DeviceInfo id={process.deviceId} />
            </Grid>
          }
        <Grid item xs={5}>
          <StagePurposeInfo process={process} stage={stage} />      
        </Grid>                
        <Grid item xs={2}>
          <StageStatusTeamInfo process={process} stage={stage} />
        </Grid>                   
      </Grid>
        <TabContext value={activeTabId}>
          <Box display={overVisible} className={classes.root}>
            <TabList
              indicatorColor="primary"
              textColor="primary"
              onChange={handleSwitchTab}
            >
              <Tab value="tests" label={<Localized id="stage-tab-label-tests">Testy</Localized> } className={classes.root} />                                                          
              <Tab value="remarks" label={<Localized id="stage-tab-label-remarks">Uwagi</Localized> } className={classes.root} />                  
              <Tab value="summary" label={<Localized id="stage-tab-label-summary">Podsumowanie</Localized> } className={classes.root} />                  
            </TabList>
            <TabPanel value="tests">
              <Box>
                <LoadProcessTests component={<Skeleton variant="rectangular" height={400} />} processId={id} stage={stage}>
                    <StageTests processId={id} stage={stage} stageEntity={stageEntity}/>            
                </LoadProcessTests>      
              </Box>
            </TabPanel>
            <TabPanel value="remarks">
              <Box>
                <LoadStageRemarks component={<Skeleton variant="rectangular" height={400} />} stageId={stageEntity?.id}>
                  <LoadUsers component={<Skeleton variant="rectangular" height={400} />}>
                    <StageRemarks {...stageEntity} />            
                  </LoadUsers>
                </LoadStageRemarks>
              </Box>
            </TabPanel>
            <TabPanel value="summary">
              <Box>
                <SummaryTab {...stageEntity} />            
              </Box>
            </TabPanel>
          </Box>
        </TabContext>

      <Dialog open={authAction !== undefined} maxWidth="xs" fullWidth>
          <DialogTitle>
            <Localized id="stage-action-approve">
              <span>Zatwierdź</span>
            </Localized>
          </DialogTitle>
          <DialogContent>
              <TextField 
                  sx={{
                      marginTop: 1
                  }}
                  fullWidth
                  autoFocus
                  type="password"
                  value={password}
                  onChange={handlePasswordFieldChange}
                  label={<Localized id="password"><span>Hasło</span></Localized>}
                  helperText={<Localized id="autorization-password-required"><span>Wymagana autoryzacja hasłem</span></Localized>}
                  autoComplete="off"
              />
          </DialogContent>
          <DialogActions>
              <LoadingButton loading={running} onClick={handleAuthActionOkClick}>
                  <Localized id="ok">
                      <span>OK</span>
                  </Localized>
              </LoadingButton>
              <LoadingButton loading={running} onClick={handleAuthActionCancelClick}>
                  <Localized id="cancel">
                      <span>Anuluj</span>
                  </Localized>
              </LoadingButton>
          </DialogActions>
      </Dialog>

      <Dialog open={showEditPurposeDialog} fullWidth maxWidth='md'>
        <DialogContent>
            <RichTextEditor label={<Localized id="stage-enter-purpose"><span>Wprowadź cel procesu</span></Localized> } value={purpose} onValueChange={setPurpose} />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSavePurposeClick}>
                <Localized id="ok"><span>Ok</span></Localized>
            </Button>
            <Button onClick={handleCancelPurposeClick}>
                <Localized id="cancel"><span>Anuluj</span></Localized>
            </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showEditSummaryDialog} style={{
                  minWidth: "500px",
                  minHeight: "500px"
                }}>
        <DialogContent>
            <DialogContentText>                
              <Localized id="stage-enter-summary"><span>Wprowadź cel procesu</span></Localized>                   
            </DialogContentText>
            <TextField 
                multiline       
                minRows={4}  
                maxRows = {10}       
                onChange={handleStageSummaryChange}
                value={stageSummary}
                variant="outlined"
                style={{
                  minWidth: "500px",                  
                  maxHeight: "400px"
                }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveSummaryClick}>
                <Localized id="ok"><span>Ok</span></Localized>
            </Button>
            <Button onClick={handleCancelSummaryClick}>
                <Localized id="cancel"><span>Anuluj</span></Localized>
            </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showErrorFinishDialog}> 
        <DialogContent>
            <DialogContentText>                
              <Localized id="stage-finish-warning-message"><span>Testy zakończyły sie błędami czy napewno chcesz zakończyc etap?</span></Localized>                   
            </DialogContentText>
           
        </DialogContent>
        <DialogActions>
            <Button onClick={handleOkErrorFinishClick}>
                <Localized id="stage-action-finish"><span>Ok</span></Localized>
            </Button>
            <Button onClick={handleCancelErrorFinishClick}>
                <Localized id="cancel"><span>Anuluj</span></Localized>
            </Button>
        </DialogActions>
      </Dialog>

      <BottomAppBar>
        <ButtonGroup variant="contained" color="secondary">
          <Button onClick={handleBackClick} color="secondary" variant="outlined">
            <Localized id="back">Wróć</Localized>
          </Button>
          <OpenReport 
            id={stageEntity.processId} 
            stage={stageEntity.stage} 
            reportType={"protocol"} 
            label={"process-print-protocol"}
            />
          <OpenReport 
            id={stageEntity.processId} 
            stage={stageEntity.stage} 
            reportType={"report"} 
            label={"process-print-report"}
          />
          <Button  variant="contained"  
            style={ {display: (stageEntity?.status === "P" || stageEntity?.status === "R" )? "inline" : "none" }} 
            onClick={handlePurposeEditClick} className={classes.button}>
              <Localized id="stage-edit-purpose"><span>Edytuj cel</span></Localized>            
          </Button>   
          <Button variant="outlined"
            style={ {display: (stageEntity?.status === "V" )? "inline" : "none" }} 
            onClick={handleSummaryEditClick} color="secondary">
              <Localized id="stage-edit-summary"><span>Edytuj podsumowanie</span></Localized>            
          </Button>
          <Button  variant="contained" color="primary" 
              style={ {display: (stageEntity?.status === "P" || stageEntity?.status === "R" )? "inline" : "none" }}
              onClick={handleSendToApproveClick} className={classes.button}>
                <Localized id="stage-action-send-to-approve"><span>Prześlij do akceptacji</span></Localized>
          </Button>
          <LoadingButton loading={running} variant="contained" color="primary" 
            style={ {display: (stageEntity?.status === "A")? "inline" : "none" }}
            onClick={handleApproveClick}  className={classes.button}>
              <Localized id="stage-action-approve"><span>Zatwierdź</span></Localized>
          </LoadingButton>
          <LoadingButton loading={running} variant="contained"
            style={ {display: (stageEntity?.status === "A")? "inline" : "none" }}
            onClick={handleRejectClick} className={classes.button}>
              <Localized id="stage-action-reject"><span>Odrzuć</span></Localized>
          </LoadingButton>
          <Button  variant="contained" color="primary" 
            style={ {display: (stageEntity?.status === "V")? "inline" : "none" }}
            onClick={handleFinishClick} className={classes.button}>
            <Localized id="stage-action-finish"><span>Zakończ</span></Localized>
          </Button>              
        </ButtonGroup>
      </BottomAppBar>
    </div>
  );
}


const StageTests = ({ processId, stage, stageEntity }: { processId: AppId, stage: ValidationStage, stageEntity: Stage }) => {
  const dispatch = useAppDispatch()
  const allUsers = useAppSelector(selectAllUsers)
  const { structureId } = useAppSelector(state => selectProcessById(state, processId)) as Process
  //const { deviceTypeId } = useAppSelector(state => selectDeviceById(state, structureId)) as Device
  const [newUser, setNewUser] = React.useState<User | null>(null)
  const [newTestApprover, setNewTestApprover] = React.useState<User | null>(null)
  const [newPlannedDate, setNewPlannedDate] = useState<Date>(new Date())


  const stageTests = useAppSelector(selectAllProcessTests)
  const [testToDelete, setTestToDelete] = React.useState<AppId|undefined>(undefined);  
  const [showAssingTesterDialog, setShowAssingTesterDialog] = React.useState(false);
  const [showAssingTestApproverDialog, setShowAssingTestApproverDialog] = React.useState(false);
  const [showPlannedDateDialog, setShowPlannedDateDialog] = React.useState(false);
    
  const [showTestBrowser, setShowTestBrowser] = useState(false)
  const [testSelection, setTestSelection] = useState<Set<AppId>>(new Set())
  const [copying, setCopying] = useState(false)
  
  const handleDelTestClick = useCallback((id) => {
    setTestToDelete(id)
  }, [setTestToDelete])

  const handleConfirmDelClick = async () => {
    if(testToDelete){
      try {         
          unwrapResult(await dispatch(deleteTest(testToDelete)));
                
        dispatch(showSuccess("saved"))          
      } 
      catch (error) {
        dispatch(showError("error"))
      }
      finally{
        setTestToDelete(undefined)
      }          
    }
  }

  const handleCancelDelClick = useCallback(() => {
    setTestToDelete(undefined)
  }, [setTestToDelete])

  const handleAssingTesterClick = useCallback(() => {
    setShowAssingTesterDialog(true)
  },[setShowAssingTesterDialog])

    //----  

  const handleUserChange = useCallback((_: any, newUser: User | null) => {
    
    setNewUser(newUser)
  }, [setNewUser]); 

  const handleCancelChangeUserClick = useCallback(() => {
    setShowAssingTesterDialog(false)    
  }, [setShowAssingTesterDialog])

  const handleConfirmChangeUserClick  = async () => {
    setShowAssingTesterDialog(false)   
    
    try {
      unwrapResult(
          await dispatch(changeAllTestTester({
            procId: processId,
            stage: stage,
            userName:  String(newUser?.userName)
          }))
      )
      dispatch(showSuccess("saved"))
    } catch (error) {
      dispatch(showError("error"))
    }
  }
  
  //----  
  const handleAssingTestApproverClick = useCallback(() => {
    setShowAssingTestApproverDialog(true)
  },[setShowAssingTestApproverDialog])

  const handleTestApproverChange = useCallback((_: any, user: User | null) => {    
      setNewTestApprover(user)    
  }, [setNewTestApprover]); 

  const handleCancelChangeTestApproverClick = useCallback(() => {
    setShowAssingTestApproverDialog(false)    
  }, [setShowAssingTestApproverDialog])

  const handleConfirmChangeTestApproverClick = async () => {
    setShowAssingTestApproverDialog(false)   
    
    try {
      unwrapResult(
          await dispatch(changeAllTestApprover({
            procId: processId,
            stage: stage,
            userName:  String(newTestApprover?.userName)
          }))
      )
      dispatch(showSuccess("saved"))
    } catch (error) {
      dispatch(showError("error"))
    }
  }

  //----  
  const handleSetPlannedDateClick = useCallback(() => {
    setShowPlannedDateDialog(true)
  },[setShowPlannedDateDialog])

  const handleCancelSetPlannedDateClick = useCallback(() => {
    setShowPlannedDateDialog(false)    
  }, [setShowPlannedDateDialog])

  const handleConfirmSetPlannedDateClick = async () => {
    setShowPlannedDateDialog(false)   
    
    try {
      unwrapResult(
          await dispatch(changeAllTestPlannedDate({
            procId: processId,
            stage: stage,
            plannedDate: formatISO(newPlannedDate),
          }))
      )
      dispatch(showSuccess("saved"))
    } catch (error) {
      dispatch(showError("error"))
    }
  }
  
  const handleSelectBrowserItem = useCallback((validationId: AppId) => {
      setTestSelection(
          produce(draft => {
              draft.add(validationId)
          })
      )
  }, [])
  const handleDeselectBrowserItem = useCallback((validationId: AppId) => {
      setTestSelection(
          produce(draft => {
              draft.delete(validationId)
          })
      )
  }, [])
  const handleCancelDialogClick = useCallback(() => {
    setShowTestBrowser(false)
  }, [])
  const handleCopyTestsClick = async () => {
    setCopying(true)
    for (const validationId of testSelection) {
      await dispatch(copyProcessTestFromCatalog({
        processId,
        stage,
        validationId,
      }))
    }
    setCopying(false)
    setShowTestBrowser(false)
  }
  const handleBrowseTestsClick = () => {
    setTestSelection(new Set())
    setShowTestBrowser(true)
  }

  return (
    <>
      <Stack spacing={1}>
        <TableContainer sx={{
          maxHeight: 'calc(100vh - 420px)',
        }} component={Paper}>
              <Table>
                  <TableHead className={classes.head}>
                      <TableRow>
                          <TableCell>
                            <Localized id="test-name">
                              <span>Test</span>
                            </Localized>                        
                          </TableCell> 
                          <TableCell>
                            <Localized id="test-status">
                              <span>Status</span>
                            </Localized>                             
                          </TableCell>   
                          <TableCell>
                            <Localized id="test-tester-name">
                              <span>Osoba wykonująca</span>
                            </Localized>                         
                          </TableCell>   
                          <TableCell>
                            <Localized id="test-approver-name">
                              <span>Zatwierdzanie</span>
                            </Localized>                         
                          </TableCell>  
                          <TableCell>
                            <Localized id="test-planned-date">
                              <span>Planowana data</span>
                            </Localized>                          
                          </TableCell>  
                          <TableCell sx={{ textAlign: 'center' }}>  
                            <Localized id="test-result">
                              <span>Result</span>
                            </Localized>                          
                          </TableCell>           
                          <TableCell sx = {{minWidth:80, width:80, maxWidth:80, justifyContent: 'flex-end' }} />
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {stageTests.map(({ id, name, status, testUser, resultApproverUser, plannedDate, stage, hasErrors }) =>
                          <TableRow
                            key={id}
                            hover
                            classes={{ hover: classes.hover, selected: classes.selected }}
                            className={classes.tableRow}
                          >
                          <TableCell>
                            <div dangerouslySetInnerHTML={{ __html: name }}></div>
                          </TableCell>                                
                          <TableCell><TestStatusLabel status={status}/></TableCell> 
                          <TableCell>
                            <FullName userName={testUser}></FullName>
                          </TableCell>
                          <TableCell>
                            <FullName userName={resultApproverUser}></FullName>
                          </TableCell>                                                                
                          <TableCell>{plannedDate!=null ? format(parseJSON(plannedDate), "yyyy-MM") : ""}</TableCell>                           
                          <TableCell sx={{ textAlign: 'center' }}>
                            <If condition={status !== "P"} otherwise = "" >
                              <If condition={hasErrors === false} otherwise={<Clear fontSize="small" color='error' />}>
                                  <Check fontSize="small" color='success' />
                              </If>
                            </If>
                          </TableCell>                                   
                          <TableCell>
                            <ButtonGroup>
                              <Button 
                                size="small" 
                                component={AppLink}
                                to={{
                                  pathname: `/${stage}/test/edit/${processId}/${id}`,
                                }}
                                color="secondary"
                                variant="outlined" 
                                disabled={stageEntity?.status !== "N" && stageEntity?.status !== "P" && stageEntity?.status !== "R" }
                                  >
                                  <Localized id="edit">
                                      <span>Edytuj</span>
                                  </Localized>
                              </Button>
                              <Button size="small" onClick={() =>handleDelTestClick(id)}  color="secondary" variant="outlined" 
                                disabled={stageEntity?.status !== "N" && stageEntity?.status !== "P" && stageEntity?.status !== "R" }>
                                  <Localized id="delete">
                                      <span>Usuń</span>
                                  </Localized>
                              </Button>
                              <Button
                                size="small"
                                component={AppLink}
                                to={{
                                  pathname: `/${stage}/test/result/${processId}/${id}`,
                                }}
                                color="secondary"
                                variant="outlined" >
                                  <Localized id="results">
                                      <span>Wyniki</span>
                                  </Localized>
                              </Button>
                            </ButtonGroup>
                          </TableCell>                                                   
                      </TableRow>
                      )}
                  </TableBody>
              </Table>
          </TableContainer>
          <ButtonGroup size="small">
            <Button
              variant="outlined"
              component={AppLink}
              to={{
                pathname: `/${stage}/test/create/${processId}`,
              }}
              disabled={stageEntity?.status !== "N" && stageEntity?.status !== "P" && stageEntity?.status !== "R" }>
              <Localized id="stage-add-new-test">
                <span>Dodaj nowy test</span>
              </Localized>
            </Button>
            <Button variant="outlined" onClick={handleBrowseTestsClick}
              disabled={stageEntity?.status !== "N" && stageEntity?.status !== "P" && stageEntity?.status !== "R" }>
              <Localized id="stage-add-test-from-catalog">
                <span>Dodaj z katalogu</span>
              </Localized>
            </Button>
            <Button variant="outlined" onClick={handleAssingTesterClick}
              disabled={stageEntity?.status !== "N" && stageEntity?.status !== "P" && stageEntity?.status !== "R" }>
              <Localized id="stage-assing-tester" >
                <span>Przypisz osobę wykonującą</span>
              </Localized>
            </Button>
            <Button variant="outlined" onClick={handleAssingTestApproverClick}
              disabled={stageEntity?.status !== "N" && stageEntity?.status !== "P" && stageEntity?.status !== "R" }>
              <Localized id="stage-assing-test-approver" >
                <span>Przypisz osobę sprawdzającą</span>
              </Localized>
            </Button>
            <Button variant="outlined" onClick={handleSetPlannedDateClick}
              disabled={stageEntity?.status !== "N" && stageEntity?.status !== "P" && stageEntity?.status !== "R" }>
              <Localized id="stage-assing-test-planned-date" >
                <span>Ustaw planowaną datę</span>
              </Localized>
            </Button>
          </ButtonGroup>
      </Stack>

      <Dialog open={testToDelete !== undefined}>
        <DialogContent>
            <DialogContentText>
                <Localized id="confirm-delete">
                    <span>Czy napewno chcesz usunąć test?</span>
                </Localized>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleConfirmDelClick}>
                <Localized id="yes"><span>Tak</span></Localized>
            </Button>
            <Button onClick={handleCancelDelClick}>
                <Localized id="no"><span>Nie</span></Localized>
            </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAssingTesterDialog}>
        <DialogContent>
            <DialogContentText>
                <Localized id="stage-assing-tester-dialog-header">
                    <span>Wybierz użytkownika</span>
                </Localized>
            </DialogContentText>
            <LoadUsers component={<Skeleton variant="rectangular" />}>
              <Autocomplete                         
                  options={allUsers}
                  value={newUser}
                  sx={{minWidth: "400px", marginTop: "10px"}}
                  onChange={handleUserChange}
                  getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                  renderInput={params =>
                    <TextField {...params}                               
                    />
                }
              />                        
            </LoadUsers>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmChangeUserClick}>
              <Localized id="ok"><span>Ok</span></Localized>
          </Button>
          <Button onClick={handleCancelChangeUserClick}>
              <Localized id="cancel"><span>Anuluj</span></Localized>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAssingTestApproverDialog}>
        <DialogContent>
            <DialogContentText>
                <Localized id="stage-assing-test-approver-dialog-header">
                    <span>Wybierz użytkownika</span>
                </Localized>
            </DialogContentText>
            <LoadUsers component={<Skeleton variant="rectangular" />}>     
              <Autocomplete                         
                  options={allUsers}
                  value={newTestApprover}                
                  sx={{minWidth: "400px", marginTop: "10px"}}
                  onChange={handleTestApproverChange}
                  getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                  renderInput={params =>
                    <TextField {...params}                               
                    />
                }
              />                     
            </LoadUsers>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmChangeTestApproverClick}>
              <Localized id="ok"><span>Ok</span></Localized>
          </Button>
          <Button onClick={handleCancelChangeTestApproverClick}>
              <Localized id="cancel"><span>Anuluj</span></Localized>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showPlannedDateDialog}>
        <DialogContent>
            <DialogContentText>
                <Localized id="stage-assing-test-planned-date-header">
                    <span>Ustaw planowana date</span>
                </Localized>
            </DialogContentText>
            <DatePicker
                views={["year", "month"]}               
                value={newPlannedDate}
                onChange={(date: any) => setNewPlannedDate(date as Date)}
                renderInput={(params) => <TextField  sx={{minWidth: "300px", marginTop: "20px"}} {...params} helperText={null} />}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmSetPlannedDateClick}>
              <Localized id="ok"><span>Ok</span></Localized>
          </Button>
          <Button onClick={handleCancelSetPlannedDateClick}>
              <Localized id="cancel"><span>Anuluj</span></Localized>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showTestBrowser} maxWidth="lg" fullWidth={true} >
          <DialogTitle>
              <Typography variant="subtitle2" color="textSecondary" gutterBottom >
                  <Localized id="menu-validations">
                      <span>Katalog testów</span>
                  </Localized>
              </Typography>
          </DialogTitle>
          <DialogContent>
            <LoadValidations component={<Skeleton />}>
              <ValidationsBrowser 
                  selection={testSelection} 
                  excluded={empty} 
                  itemChecked={handleSelectBrowserItem} 
                  itemUnchecked={handleDeselectBrowserItem}
                  stage={stage} />
            </LoadValidations>
          </DialogContent>
          <DialogActions>
              <LoadingButton onClick={handleCopyTestsClick} loading={copying} color="secondary" variant="contained">
                  <Localized id="ok">
                      <span>OK</span>
                  </Localized>
              </LoadingButton>
              <LoadingButton loading={copying} onClick={handleCancelDialogClick}>
                  <Localized id="cancel"><span>Anuluj</span></Localized>
              </LoadingButton>
          </DialogActions>
      </Dialog>
    </>
  );
}
const SummaryTab = (stageEntity: Stage) => {   
  return <>
    <Stack direction="column" spacing={2}>
      <TextField
          multiline
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          minRows={4}
          maxRows={6}
          value={stageEntity.purpose}
          label={<Localized id="stage-purpose"><span>Cel</span></Localized>}
      />
      <TextField
          multiline
          minRows={4}
          maxRows={6}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          value={stageEntity.stageSummary}
          label={<Localized id="stage-summary"><span>Opis</span></Localized>}
      />
    </Stack>
  </>
}

const StageRemarks = (stageEntity: Stage) => {    
  const dispatch = useAppDispatch()
  const stageTests = useAppSelector(selectAllProcessTests);

  const allRemarks = useAppSelector(state => selectAllRemarks(state));
  const [showAddRemarkDialog, setShowAddRemarkDialog] = React.useState(false);
  const [showAddCommentkDialog, setShowAddCommentkDialog] = React.useState(false);
  const [remarkText, setRemarkText] = React.useState("");
  const [remarkTest, setRemarkTest] = useState<ProcessTest | undefined>();

  const [commentText, setCommentText] = React.useState("");
  const [currentRemarkId, setCurrentRemarkId] = useState<AppId | undefined>(undefined)
  const [commentAction, setCommentAction] = useState<string | undefined>(undefined)

  const handleShowAddCommentDialogClick = useCallback((id: AppId, action: string) => {  
    setCommentAction(action)
    setCurrentRemarkId(id)
    setShowAddCommentkDialog(true)
  }, [setShowAddCommentkDialog])    

  const handleCancelAddCommentClick = useCallback(() => {
    setShowAddCommentkDialog(false)
  }, [setShowAddCommentkDialog])

  const handleShowAddRemarkDialogClick = useCallback(() => {    
    setRemarkTest(undefined)
    setRemarkText("")
    setShowAddRemarkDialog(true)
  }, [setShowAddRemarkDialog])    

  const handleCancelAddRemarkClick = useCallback(() => {
    setShowAddRemarkDialog(false)
  }, [setShowAddRemarkDialog])        

  const handleRemarkTestChange = useCallback((_: any, test: ProcessTest | null) => {
    if (test) {
      setRemarkTest(test)
    }
  }, []);

  const handleDeleteRemark = async (id: AppId) => {
    if(id){
      try {         
          unwrapResult(await dispatch(deleteRemark(id)));
                
        dispatch(showSuccess("saved"))          
      } 
      catch (error) {
        dispatch(showError("error"))
      }          
    }
  }

  const handleSaveCommentClick= async () => {
    if(currentRemarkId){
      try {
      if(commentAction === 'CLOSE'){
          unwrapResult(await dispatch(closeRemark({
            remarkId: currentRemarkId,           
            comment: String(commentText)
          })));
        }
        else if(commentAction === 'REOPEN') {
          unwrapResult(await dispatch(reopenRemark({
            remarkId: currentRemarkId,           
            comment: String(commentText)
          })));
        }

        dispatch(showSuccess("saved"))
        setCurrentRemarkId(undefined);
        setCommentText("");
        setCommentAction(undefined);
      } 
      catch (error) {
        dispatch(showError("error"))
      }

      setShowAddCommentkDialog(false)    
    }
  }

  const handleSaveRemarkClick = async () => {
    if(stageEntity.id !== ''){
      try {

          unwrapResult(await dispatch(createRemark({
            stageId: String(stageEntity.id),           
            remark: String(remarkText),
            testId: remarkTest?.id ?? ""
          })));
                              
        dispatch(showSuccess("saved"))
        setRemarkText("");
      } 
      catch (error) {
        dispatch(showError("error"))
      }

      setShowAddRemarkDialog(false)    
    }
  }

  const handleRemarkTextChange= (e: ChangeEvent<HTMLInputElement>) => {     
      setRemarkText(e.target.value);
  }

  const handleCommentTextChange= (e: ChangeEvent<HTMLInputElement>) => {     
    setCommentText(e.target.value);
  }

  const CommentCell = ({ row }: { row: ProcessStageRemark }) => {
    if (row.comments.length > 0 ) {
      let commentRow =  row.comments[row.comments.length - 1];
      return <>
          <Typography variant="body2" color="textSecondary" component="div" >
              {commentRow.commentDate} {commentRow.createdByFullName}
          </Typography>
          {commentRow.comment}
      </>      
    }
    return <></>
  }

  const TestNameCell = ({ row }: { row: ProcessStageRemark }) => {
    let testInd = stageTests?.findIndex(x => x.id === row.testId);
    if (testInd >= 0) {
      
      return <>
          <Typography variant="body2" color="textSecondary" component="div" >
              <div dangerouslySetInnerHTML={{ __html: stageTests[testInd].name }}></div>
          </Typography>            
      </>      
    }
    return <></>
  }
  
  return <>
  <Stack spacing={1}>
    <TableContainer sx={{
      maxHeight: 'calc(100vh - 420px)',
    }} component={Paper}>
      <Table>
        <TableHead className={classes.head}>
              <TableRow>
                  <TableCell >   
                    <Localized id="stage-remark">
                      <span>Uwaga</span>
                    </Localized>                                                  
                  </TableCell> 
                  <TableCell> 
                    <Localized id="stage-comment">
                      <span>Komentarz</span>
                    </Localized>                                                      
                  </TableCell> 
                  <TableCell> 
                    <Localized id="test-name">
                      <span>Test</span>
                    </Localized>                                                    
                  </TableCell> 
                  <TableCell>                              
                    <Localized id="remark-status">
                      <span>Status</span>
                    </Localized>    
                  </TableCell>    
                  <TableCell className={classes.commandsColumn}> 
                  </TableCell>                                                                              
              </TableRow>
          </TableHead>
          <TableBody>
              {allRemarks.map(row =>
                  <TableRow key={row.id}>                    
                      <TableCell>                      
                          <Typography variant="body2" color="textSecondary" component="div" >
                              {row.remarkDate} {row.createdByFullName}
                          </Typography>
                          {row.remark}
                      </TableCell>  
                      <TableCell> 
                        <CommentCell row={row}/>                        
                      </TableCell> 
                      <TableCell> 
                        <TestNameCell row={row}/>                           
                      </TableCell> 
                      <TableCell> 
                        {<RemarkStatusLabel status={row.status} />} 
                      </TableCell>                       
                      <TableCell>
                        <ButtonGroup>
                          <Button color="secondary" variant="outlined"                     
                              onClick={() => handleShowAddCommentDialogClick(row.id, 'CLOSE')} 
                              style={{ display: row?.status === "C" ? "none" : "inline" }}>                            
                            <Localized id="close"><span>Zamknij</span></Localized>
                          </Button>
                          <Button color="secondary" variant="outlined" 
                              onClick={() => handleShowAddCommentDialogClick(row.id, 'REOPEN')}
                              style={{ display: row?.status === "C" ? "inline" : "none" }}>
                            <Localized id="open"><span>Otwórz</span></Localized>
                          </Button>     
                          <Button color="secondary" variant="outlined" 
                              onClick={() => handleDeleteRemark(row.id)}
                              disabled={row?.status !== "N"}>
                            <Localized id="delete"><span>Usuń</span></Localized>                            
                          </Button>  
                        </ButtonGroup>              
                </TableCell>                                                            
              </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonGroup size="small">
        <Button variant="outlined" onClick={() => handleShowAddRemarkDialogClick()} >              
            <Localized id="add"><span>Dodaj</span></Localized>                
        </Button>
      </ButtonGroup>
    </Stack>
    <Dialog open={showAddRemarkDialog}>
      <DialogContent>
          <DialogContentText> 
            <Localized id="stage-add-remark"><span>Wprowadz uwage do planowania</span></Localized>
          </DialogContentText>
          
          <TextField 
              multiline
              rows={5}
              onChange={handleRemarkTextChange}
              value={remarkText}
              variant="outlined"
              style={{
                minWidth: "400px",
                marginBottom: "10px"
              }}                                              
          />   

          <Autocomplete                         
              options={stageTests}
              value={remarkTest}
              onChange={handleRemarkTestChange}
              getOptionLabel={({ name }) => `${name}`}
              renderInput={(params) => <TextField {...params} label={
                <Localized id="stage-remark-link-test">
                    <span>skojarz z testem</span>
                </Localized>
              } variant="outlined" />
            }
          />                              
      </DialogContent>
      <DialogActions>
          <Button onClick={handleSaveRemarkClick}>
              <Localized id="ok"><span>Ok</span></Localized>
          </Button>
          <Button onClick={handleCancelAddRemarkClick}>
              <Localized id="cancel"><span>Anuluj</span></Localized>
          </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={showAddCommentkDialog}>
      <DialogContent>
          <DialogContentText>    
            <Localized id="stage-add-comment"><span>Dodaj komentarz</span></Localized>                          
          </DialogContentText>
          <TextField 
              multiline
              rows={5}
              onChange={handleCommentTextChange}
              value={commentText}
              variant="outlined"
              style={{
                minWidth: "400px"
              }}                                              
          />                            
      </DialogContent>
      <DialogActions>
          <Button onClick={handleSaveCommentClick}>
              <Localized id="ok"><span>Ok</span></Localized>
          </Button>
          <Button onClick={handleCancelAddCommentClick}>
              <Localized id="cancel"><span>Anuluj</span></Localized>
          </Button>
      </DialogActions>
    </Dialog>    
</>
}
