import { ChangeEvent, useState } from "react";
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "./app/hooks";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { Localized, useLocalization } from "@fluent/react";
import { showError, showSuccess } from "./features/notifications/notificationsSlice";
import { changePassword, resetPassword } from "./features/user/userSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const PREFIX = 'Login';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    logo: `${PREFIX}-logo`,
    text: `${PREFIX}-text`,
    backdrop: `${PREFIX}-backdrop`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        textAlign: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '400px',
        margin: 'auto',
    },

    [`& .${classes.paper}`]: {
        width: "100%",
        paddingTop: 32,
        paddingBottom: 32,
    },

    [`& .${classes.logo}`]: {
        pointerEvents: 'none',
        height: 300,
    },

    [`& .${classes.text}`]: {
        width: '80%',
    },

    [`& .${classes.backdrop}`]: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    }
}));


export interface LoginAPIError {
    success: boolean
    token: null | string
    errors: string[]
}

export const ChangePassword = () => {
    const dispatch = useAppDispatch()
    const { l10n } = useLocalization()

    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [email, setEmail] = useState("");

    const [errorPassword, setPasswordError] = useState("");
    const [emailError, setEmailError] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);

    const [mask, setMask] = useState<boolean>(false);
    const navigate = useNavigate()

    const onOldPasswordChange = (e: ChangeEvent<HTMLInputElement>) => setOldPassword(e.target.value)
    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
    const onPasswordConfirmChange = (e: ChangeEvent<HTMLInputElement>) => setPasswordConfirm(e.target.value)
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)

    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError("Nieprawidłowy adres e-mail");
        }
        else {
            setEmailError("");
        }
    };

    const clearErrors = () => {
        setPasswordError("")
    }

    const Validate = () => {
        let valResult = true;
        if (email === '') {
            valResult = false;
        }
        if (password !== '' && passwordConfirm !== '' && passwordConfirm !== password) {
            valResult = false;
            setPasswordError(l10n.getString("password-do-not-match", null, "_password not match"));
        }
        if (!isPasswordStrong(password)) {
            valResult = false;
            setPasswordError(
                "Hasło musi zawierać co najmniej 8 znaków, jedną dużą literę i jeden znak specjalny (!, @, #, $ itp.)."
            );
        }
        return valResult;
    }

    const isPasswordStrong = (password) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
        return passwordPattern.test(password);
    };

    const handleSaveClick = async () => {

        clearErrors()
        if (!Validate()) {

            return;
        }


        try {
            setMask(true)

            const resultAction = await dispatch(changePassword({
                email: email,
                oldPassword: oldPassword,
                newPassword: password
            }))

            if (changePassword.fulfilled.match(resultAction)) {
                dispatch(showSuccess("password-changed-sucesfully"))
                await timeout(2000)
                navigate("/login", { replace: true })
            }

            if (changePassword.rejected.match(resultAction)) {
                const { payload } = resultAction
                if (payload) {
                    switch (payload.kind) {
                        case "http":
                            if (payload.body) {
                                dispatch(showError(payload.body))
                            }
                            else {
                                dispatch(showError("error"))
                            } break
                        case "connection":
                            dispatch(showError("connection-issue"))
                            break
                    }
                } else {
                    dispatch(showError("error"))
                }
            }

        }
        catch (error) {
            dispatch(showError(error ?? "error-login-failed"))
        }
        finally {
            setMask(false)
        }


    }
    return (
        <Root className={classes.root}>
            <Paper variant="elevation" elevation={4} component="div" className={classes.paper}>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                            <Localized id="password-change-required">
                                <span>Wymagana zmiana hasła</span>
                            </Localized>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            sx={{ width: 320 }}
                            value={email}
                            required
                            onBlur={validateEmail}
                            error={!!emailError}
                            helperText={emailError}
                            type="email"
                            onChange={handleEmailChange}
                            inputProps={{ maxLength: 100, autoComplete: "email" }}
                            label={<Localized id="user-email">Email</Localized>}>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type={showOldPassword ? 'text' : 'password'}
                            className={classes.text}
                            onBlur={clearErrors}
                            onChange={onOldPasswordChange}
                            required
                            error={!oldPassword}
                            label={l10n.getString("password-current", null, "Obecne hasło")}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton tabIndex={-1} onClick={toggleOldPasswordVisibility}>
                                            {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Divider style={{ marginLeft: 30, marginRight: 20, marginTop: 20, marginBottom: 20 }} />

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            className={classes.text}
                            onBlur={clearErrors}
                            onChange={onPasswordChange}
                            error={!!errorPassword}
                            helperText={errorPassword}
                            label={l10n.getString("password-new", null, "Nowe hasło")}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton tabIndex={-1} onClick={togglePasswordVisibility}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type={showConfirmPassword ? 'text' : 'password'}
                            className={classes.text}
                            onChange={onPasswordConfirmChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton tabIndex={-1} onClick={toggleConfirmPasswordVisibility}>
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            label={l10n.getString("", null, "Powtórz hasło")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={handleSaveClick} color="primary" size="large" variant="contained">
                            <Localized id="save">
                                <span>Zapisz</span>
                            </Localized>
                        </Button>
                    </Grid>
                </Grid>
                <Backdrop className={classes.backdrop} open={mask}>
                    <CircularProgress color="inherit"></CircularProgress>
                </Backdrop>
            </Paper>
            <Box sx={{ marginTop: 2, }}>
                <img className={classes.logo} src={process.env.PUBLIC_URL + '/logo.gif'} alt="Logo" />
            </Box>
        </Root>
    );

}
