import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { AppId, AppTextFieldHandler } from "../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { RichTextEditor } from "../../../app/RichTextEditor"
import { RequirementType } from "../../requirements/RequirementsTypesSlice"
import { createURSRequirement, selectUrs, Urs, URSRequirement } from "../UrsSlice"


export interface AddRequirementDialogProps {
    onSuccess     : (urs: Urs) => void
    onCancel      : () => void
    sectionId     : AppId
}

export const AddRequirementDialog = ({ onSuccess, onCancel, sectionId }: AddRequirementDialogProps) => {
    const dispatch = useAppDispatch()
    // SELECTORS 
    const { id: ursId } = useAppSelector(selectUrs).data

    // STATE 
    const [criticality, setCriticality] = useState("E")
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(false)
    const [saving, setSaving] = useState(false)

    // HANDLERS 
    const handleCriticalityChange: AppTextFieldHandler = useCallback(e => setCriticality(e.target.value), [setCriticality])
    const handleOkClick = async () => {
        if (ursId) {
            if (!name) {
                setNameError(true)
            } else {
                setSaving(true)
                const entity: URSRequirement = {
                    id: ``,
                    code: "",
                    name: name,
                    description: "",
                    criticality: criticality,
                    typeId: null,
                    ursId: ursId,
                    severity: 0,
                    frequency: 0,
                    detection: 0,
                    potentialDefect: "",
                    consequence: "",
                    reason: "",
                    testDefinitionId: null,
                    requirementId: null,
                    stage: "DQ",
                    note: "",
                    comment: "",
                    group: '',
                    order: 0,
                    guid: "",
                    sectionGuid: sectionId,
                    numeration: null,
                    offerMessages: [],
                    parentRequirementId: null,
                    childRequirements: [],
                    modifyDate: null,
                    jsonContent: null,
                    searcheableContent: null,
                }
                dispatch(createURSRequirement(entity))
                    .unwrap()
                    .catch(err => console.log(err))
                    .then(e => e && onSuccess(e))
                    .finally(() => setSaving(false))
            }
        }
    }

    return <Dialog open={true} fullWidth maxWidth="md">
        <DialogTitle>
            <Localized id="requirements-new">
                <span>Nowe wymaganie</span>
            </Localized>
        </DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TextField                                                            
                        value={criticality}
                        fullWidth
                        required={true}
                        select
                        label={<Localized id="urs-item-criticality"><span>Criticality</span></Localized>}
                        onChange={handleCriticalityChange}
                        >
                    
                        <MenuItem value={"E"}><Localized id="urs-item-criticality-e"><span>Essential</span></Localized></MenuItem>
                        <MenuItem value={"D"}><Localized id="urs-item-criticality-d"><span>Desired</span></Localized></MenuItem>
                        <MenuItem value={"I"}><Localized id="urs-item-criticality-i"><span>Optional</span></Localized></MenuItem>                                
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <RichTextEditor label={null} value={name} onValueChange={setName} />
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6}>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOkClick}>
                <Localized id="ok">
                    <span>Ok</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={onCancel}>
                <Localized id="cancel">
                    <span>Cancel</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

const getRequirementTypeName = (dt: RequirementType) => `${dt.code} - ${dt.name}`
