import { ChangeEvent, useState } from "react"
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom"
import { useAppDispatch } from "./app/hooks"
import { forgotPassword } from './features/user/userSlice'
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";

import { Localized, useLocalization } from "@fluent/react"
import { showError } from "./features/notifications/notificationsSlice";
import { AuthPageLayout } from "./AuthPageLayout";

const PREFIX = 'ForgotPassword';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    logo: `${PREFIX}-logo`,
    text: `${PREFIX}-text`,
    backdrop: `${PREFIX}-backdrop`
};

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        textAlign: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '400px',
        margin: 'auto',
    },

    [`& .${classes.paper}`]: {
        width: "100%",
        paddingTop: 32,
        paddingBottom: 32,
    },

    [`& .${classes.backdrop}`]: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    }
}));

export const ForgotPassword = () => {
    const dispatch = useAppDispatch()

    const { l10n } = useLocalization()
    const [email, setEmail] = useState("");
    const [mask, setMask] = useState<boolean>(false);
    const [resetLinkSent, setResetLinkSent] = useState<boolean>(false);

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)

    const handleResetClick = async () => {
        if (email) {
            try {
                setMask(true)
                const resultAction = await dispatch(forgotPassword({ email }))

                if (forgotPassword.fulfilled.match(resultAction)) {
                    setResetLinkSent(true)
                }
                if (forgotPassword.rejected.match(resultAction)) {
                    const { payload } = resultAction
                    if (payload) {
                        switch (payload.kind) {
                            case "http":
                                if (payload.body) {
                                    dispatch(showError(payload.body))
                                }
                                else {
                                    dispatch(showError("error"))
                                } break
                            case "connection":
                                dispatch(showError("connection-issue"))
                                break
                        }
                    } else {
                        dispatch(showError("error"))
                    }
                }
            }
            catch (error) {
                dispatch(showError(error ?? "error-login-failed"))
            }
            finally {
                setMask(false)
            }
        }
    }

    switch (resetLinkSent) {
        case true:
            return (
                <Root className={classes.root}>
                    <Paper variant="elevation" elevation={4} component="div" className={classes.paper}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom style={{ marginLeft: 30, marginRight: 20, }}>
                                    <Localized id="forgot-password-link-sent">
                                        <span>forgot-password-link-sent</span>
                                    </Localized>
                                </Typography>
                            </Grid>
                            <Divider style={{ marginLeft: 30, marginRight: 20, marginTop: 20 }} />
                            <Grid item xs={12} style={{ textAlign: 'center', marginTop: 10, marginRight: 10 }}>
                                <Link to="/login" style={{ alignContent: 'center' }}>
                                    <span style={{ textAlign: 'center' }}></span>
                                    <Localized id="back-to-login">
                                        <span>Powrót do logowania</span>
                                    </Localized>
                                </Link>
                            </Grid>
                        </Grid>

                    </Paper>
                    <Box sx={{ marginTop: 2, }}>
                        <img style={{ pointerEvents: 'none', height: 300 }} src={process.env.PUBLIC_URL + '/logo.gif'} alt="Logo" />
                    </Box>
                </Root>
            )
        default:
            return (
                <AuthPageLayout>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" fontWeight="bold" textAlign="center">
                                <Localized id="forgot-password-question">
                                    <span>Zapomniałeś hasła?</span>
                                </Localized>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                <Localized id="forgot-password-description">
                                    <span>Zapomniałeś hasła?</span>
                                </Localized>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={email}
                                sx={{ width: '80%' }}
                                onChange={onEmailChange}
                                label={l10n.getString("user-email", null, "Adres email")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={handleResetClick} color="primary" size="large" variant="contained">
                                <Localized id="reset-password">
                                    <span>reset</span>
                                </Localized>
                            </Button>
                        </Grid>
                        <Divider style={{ marginLeft: 30, marginRight: 20, marginTop: 20 }} />
                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: 10, marginRight: 10 }}>
                            <Link to="/login" style={{ alignContent: 'center' }}>
                                <span style={{ textAlign: 'center' }}></span>
                                <Localized id="back-to-login">
                                    <span>Powrót do logowania</span>
                                </Localized>
                            </Link>
                        </Grid>
                    </Grid>
                    <Backdrop className={classes.backdrop} open={mask}>
                        <CircularProgress color="inherit"></CircularProgress>
                    </Backdrop>
                </AuthPageLayout>
            );
    }
}