import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Localized } from "@fluent/react";
import { Button, Stack, Typography } from "@mui/material";
import { StructureName, StructureNodeSelector } from './Structure';
import { Box } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectStructure, selectStructureNodeById } from './structureSlice';
import { clearContextStructure, selectContextStructure, setStructureManually } from '../structure/contextStructureSlice';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DeviceTypeContext = () => {
    const dispatch = useAppDispatch()
    const [open, setOpen] = React.useState(false)
    const { data: contextStructure } = useAppSelector(selectContextStructure)
    const structure = useAppSelector(state => selectStructureNodeById(state, contextStructure?.structId ?? ""))
    const { selected: selectedStructure } = useAppSelector(state => selectStructure(state).tree)

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOkClick = () => {
        if (selectedStructure !== undefined) {
            dispatch(setStructureManually(selectedStructure))
        }
        setOpen(false)
    }
    const handleClearClick = () => {
        dispatch(clearContextStructure())
    }

    const showSelectButton = contextStructure === undefined || contextStructure.mode === 'manual'
    const isManual = contextStructure?.mode === 'manual'

    const structureComponent = structure === undefined ?
        <Typography component="div">
            <Localized id="device-type-not-selected">Nie wybrano typu urządzenia</Localized>
        </Typography> :
        <Typography component="div">
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <StructureName structureId={structure.id} /> ({structure.code})
                {isManual && <IconButton sx={{ color: '#fff' }} size='small' color='secondary' onClick={handleClearClick}><ClearOutlinedIcon /></IconButton>}
            </Box>
        </Typography>

    return <>
        <Stack spacing={2} direction="row-reverse" sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                marginRight: 8,
            }}>
            {showSelectButton && <Button variant="outlined" color="inherit" onClick={handleClickOpen}>
                <Localized id="select">
                    <span>Wybierz</span>
                </Localized>
            </Button>}
            {structureComponent}
        </Stack>
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                >
                <CloseIcon />
                </IconButton>
                <Button autoFocus color="inherit" onClick={handleOkClick}>
                    <Localized id="ok">
                        <span>OK</span>
                    </Localized>
                </Button>
            </Toolbar>
            </AppBar>
            <Box sx={{ padding: 4, }}>
                <StructureNodeSelector />
            </Box>
        </Dialog>
    </>
}
