import {
    Avatar, Box, Button, Divider, Grid, List, ListItem, ListItemText,
    Paper, Tab, Tabs, Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { ActiveUserLabel } from "./ActiveUserLabel";
import { loadUser, User } from "./usersSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLoggedUserId } from "../user/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { LoadingContainer } from "../../app/LoadingContainer";
import { ErrorContainer } from "../../app/ErrorContainer";
import { Localized, useLocalization } from "@fluent/react";
import { useNavigate } from "react-router-dom";

export const UserProfile = () => {
    const loggedUserId = useAppSelector(selectLoggedUserId);
    const [user, setUser] = useState<User>()
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const { l10n } = useLocalization();

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadUser(loggedUserId))
            .then(unwrapResult)
            .then(setUser)
            .catch((error) => { setIsError(true); setError(error); })
            .finally(() => setIsLoading(false));
    }, [dispatch, loggedUserId])


    const redirectToSupportPage = () => {
        window.open(`${process.env.REACT_APP_SUPPORT}/UserBugs/CreateBug?t=${l10n.getString("error-unexpected-problem", null, "Wystąpił nieoczekiwany problem")}`, "_blank")
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    if (isError || !user) {
        return <>
            <ErrorContainer error={error} />
        </>
    }
    if ( !user) {
        return <>
           
        </>
    }

    return (
        <Box sx={{ backgroundColor: "#f5f5f5", minHeight: "90%", p: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 3, textAlign: "center", height: "100%", borderRadius: 4 }}>
                        <Avatar
                            sx={{ width: 100, height: 100, margin: "0 auto" }}
                        />
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            {user.firstName} {user.lastName}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                            <ActiveUserLabel />
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <List>
                            <ListItem>
                                <ListItemText primary={<Localized id="user-email">_email</Localized>} secondary={user.email} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Localized id="user-first-name">_first</Localized>} secondary={user.firstName} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Localized id="user-last-name">_last</Localized>} secondary={user.lastName} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Localized id="user-phone">_phone</Localized>} secondary={user.phone} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Localized id="user-position">_pos</Localized>} secondary={user.position} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={<Localized id="user-role">_role</Localized>} secondary={user.role.toUpperCase()} />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<Localized id="user-password-expiry-date">_expiryDate</Localized>}
                                    secondary={
                                        user.expiryDate
                                            ? new Date(user.expiryDate).toLocaleDateString()
                                            : "N/A"
                                    }
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper sx={{ p: 3 }}>
                                <Typography variant="h6" gutterBottom>
                                    <Localized id="actions">_actions</Localized>
                                </Typography>
                                <Button fullWidth variant="contained" color="primary" sx={{ mt: 1 }} onClick={() => navigate(`/changepsw`)}>
                                    <Localized id="change-password">_password</Localized>
                                </Button>
                                <Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                                    <Localized id="report-an-error-in-personal-data">_report-data</Localized>
                                </Button>
                                <Button fullWidth variant="outlined" sx={{ mt: 1 }} onClick={redirectToSupportPage}>
                                    <Localized id="report-an-error-in-system">_report-system</Localized>
                                </Button>

                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper sx={{ p: 3 }}>
                                <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
                                    <Tab label="Domyślne ustawienia" />
                                </Tabs>
                                <Divider sx={{ my: 2 }} />
                                <Box>
                                    {tabValue === 0 && (
                                        <Typography>
                                        </Typography>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                    variant="contained"
                    color="info"
                    size="medium"
                    onClick={() => navigate(-1)
                    }
                >
                    <Localized id="close">_close</Localized>
                </Button>
            </Box>
        </Box>
    );
};
