import { Localized, useLocalization } from '@fluent/react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { useAppSelector } from '../../app/hooks'
import { selectAllProcesses } from './processSlice'
import { ButtonGroup, Skeleton } from '@mui/material'
import { LoadDevices, LoadProcesses } from '../../app/AppDataLoader'
import { AppLink } from '../../app/AppLink'
import { AppId } from '../../app/appTypes'
import { selectDeviceById } from '../device/deviceSlice'
import { selectSettings } from '../settings/settingsSlice'
import { DataGrid, GridColDef, } from "@mui/x-data-grid"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const Process = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const allprocesses = useAppSelector(selectAllProcesses)
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [selectedProcess, setSelectedProcess] = useState(null);
    const device = useAppSelector(state => selectedDevice ? selectDeviceById(state, selectedDevice) : null)
    const navigate = useNavigate();

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    useEffect(() => {
        if(device) {
            redirectToPage(selectedProcess, device.deviceTypeId);
        }
    })

    const redirectToPage = (processId, deviceId:string) => {
        navigate(`/process/dashboard/${processId}${deviceId !== null ? `?structureId=${deviceId}` : ''}`)
    };

    const columns: GridColDef[] = [
        {
            field: 'processNo',
            headerName: l10n.getString("process-number", null, "_Numer"),
            width: 300
        },
        {
            field: 'createdDate',
            type: 'date',
            headerName: l10n.getString("process-date-from", null, "_Data rozpoczęcia"),
            width: 150,
            valueGetter: (_, row) => new Date(row.createdDate),
        },
        {
            field: 'finishDate',
            type: 'date',
            headerName: l10n.getString("process-date-to", null, "_Data zakończenia"),
            width: 300,
            valueGetter: (_, row) => {
                if (!row.finishDate) {
                    return '';
                }
                return new Date(row.finishDate).toLocaleString(); // lub dowolny inny format
    
            }
        },
        {
            field: 'deviceName',
            headerName: l10n.getString("process-device-name", null, "_Nazwa urzadzenia"),
            flex: 1
        },
        {
            field: 'deviceType',
            headerName: l10n.getString("process-device-type", null, "_Type urzadzenia"),
            flex: 1
        },
        {
            field: 'processOwnerFullName',
            headerName: l10n.getString("process-manager", null, "_Manager procesu"),
            width: 300
        },
        {
            field: 'process-status',
            headerName: l10n.getString("process-status", null, "_Status"),
            width: 200,
            valueGetter: (_, row) => {
                var status = row.status.toUpperCase();
                switch (status) {
                    case "D":
                        return l10n.getString("process-status-d", null, "")
                    case "I":
                        return l10n.getString("process-status-i", null, "")
                    case "F":
                        return l10n.getString("process-status-f", null, "")
                    case "R":
                        return l10n.getString("process-status-r", null, "")
                    case "C":
                        return l10n.getString("process-status-c", null, "")
                    default:
                        return ""
                }
            },
        },
        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <DashboardLink processId={params.row.id} deviceId={params.row.deviceId} />
                    </ButtonGroup>
                )
            }
        }
    ]
    return <LoadProcesses component={<Skeleton variant="rectangular" height="70vh" />}>
        <LoadDevices component={<Skeleton variant="rectangular" height="70vh" />}>
            <TableContainer component={Paper}>
                <DataGrid localeText={getCustomLocaleText()}
                    style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                    rows={allprocesses}
                    columns={columns}
                    initialState={{
                        sorting: { sortModel: [{ field: 'processNo', sort: 'asc' }] }
                    }}
                    onRowDoubleClick={({ row }) => { setSelectedProcess(row.id); setSelectedDevice(row.deviceId) } }
                />
            </TableContainer>
        </LoadDevices>
    </LoadProcesses>
}

const DashboardLink = ({ processId, deviceId }: { processId: AppId, deviceId: AppId }) => {
    const device = useAppSelector(state => selectDeviceById(state, deviceId))

    return <Button
        component={AppLink}
        to={{ pathname: `/process/dashboard/${processId}` }}
        color="secondary"
        variant="outlined" >
        <Localized id="process-overview">
            <span>Podgląd</span>
        </Localized>
    </Button>
}
