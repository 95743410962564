import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { Locale, selectSettings, setLocalePersistent } from './features/settings/settingsSlice';

export const LanguageSelector = () => {
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector(selectSettings);

    const handleLangChange = useCallback((e: SelectChangeEvent) => {
        dispatch(setLocalePersistent(e.target.value as Locale));
    }, [dispatch]);

    return (
        <FormControl fullWidth variant="standard" size="small">
            <InputLabel
                id="language-label"
                sx={{ fontSize: '0.9rem', color: 'text.secondary' }}
            >
                Language / Język
            </InputLabel>
            <Select
                labelId="language-label"
                id="language-select"
                value={locale}
                onChange={handleLangChange}
                disableUnderline
                sx={{
                    fontSize: '0.95rem',
                    paddingY: 0.5,
                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.03)',
                    },
                }}
            >
                <MenuItem value="en-US">English</MenuItem>
                <MenuItem value="pl">Polski</MenuItem>
            </Select>
        </FormControl>
    );
};
