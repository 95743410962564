import { ButtonGroup, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { BottomAppBar } from "../../app/BottomAppBar";
import { AppBlock, AppRow, selectImportedUrs } from "../ursOffer/importedUrsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { importUrsData, imporUrsHeader, IUrsBody, ISection, IReq } from "../documents/import/ursImport";
import { nanoid } from "@reduxjs/toolkit";
import { showError } from "../notifications/notificationsSlice";
import { LoadingButton } from "@mui/lab";

export type UrsMetadata =
    { version: '1', layout: 'flat' | 'structure' }

export type BlockMeta
    = { type: 'h', content: string }
    | { type: 'p', content: string }
    | { type: 'th', tableId: string, cols: string[] }
    | { type: 'tr', tableId: string, cols: string[] }

export type ReqMeta
    = { version: '1', blockMeta: BlockMeta }

function getBlockContent(block: AppBlock): string {
    switch (block.type) {
        case 'h':
            return block.content
        case 'p':
            return block.content
        case 'table':
            return '';
    }
}

export const ImportedSupplierUrsPreview = () => {
    const dispatch = useAppDispatch()
    const { data } = useAppSelector(selectImportedUrs)
    const navigate = useNavigate()

    const [saving, setSaving] = useState(false)

    const handleDiscardClick = useCallback(() => navigate(-1), [])

    const handleFinalizeImportClick = async () => {
        if (data === null) return
        const { title, structureId, ursNumber, relatedCompanyName, relatedDocumentId, blocks } = data
        setSaving(true)
        try {
            const newUrs = await dispatch(imporUrsHeader({
                description: title,
                structureId,
                title,
                ursNumber,
                language: 'EN',
                relatedCompanyName,
                relatedDocumentId
            })).unwrap()

            let requirements: IReq[] = []
            for (let i = 0; i < blocks.length; i++) {
                const block = blocks[i]
                switch (block.type) {
                    case 'h':
                        const hMeta: BlockMeta = { type: 'h', content: block.content }
                        requirements.push({
                            code: '',
                            name: block.content,
                            criticality: 'E',
                            jsonContent: JSON.stringify(hMeta),
                            searcheableContent: null,
                        })
                        break
                    case 'p':
                        const pMeta: BlockMeta = { type: 'p', content: block.content }
                        requirements.push({
                            code: '',
                            name: block.content,
                            criticality: 'E',
                            jsonContent: JSON.stringify(pMeta),
                            searcheableContent: null,
                        })
                        break
                    case 'table':
                        const tableId = nanoid()
                        for (let j = 0; j < block.rows.length; j++) {
                            const row = block.rows[j];
                            const tableMeta: BlockMeta = {
                                type: row.type,
                                tableId,
                                cols: row.cols,
                            }
                            requirements.push({
                                code: '',
                                name: row.cols.join(' '),
                                criticality: 'E',
                                jsonContent: JSON.stringify(tableMeta),
                                searcheableContent: null,
                            })
                        }
                        break
                }
            }

            const syntheticSection: ISection = {
                name: "Synthetic",
                requirements,
                groups: [],
            }
            const meta: UrsMetadata = { version: '1', layout: 'flat' }
            const payload: IUrsBody = {
                types: [syntheticSection],
                metadata: JSON.stringify(meta)
            }

            await dispatch(importUrsData({
                id: newUrs.id,
                body: payload,
            })).unwrap()

            // await dispatch(uploadBlobs([newUrs.guid, files]))

            navigate(`/supplier-imported-urs/edit/${newUrs.id}`, { replace: true })
        } catch (error) {
            dispatch(showError("error"))
        } finally {
            setSaving(false)
        }

    }

    if (data === null) return <div>No data</div>

    return <>
        <Container sx={{
            border: '1px dashed var(--gray-400)',
            height: 'calc(100vh - 150px)',
            overflow: 'auto',
        }} fixed maxWidth='lg'>{data.blocks.map((block, idx) => <RenderBlock key={idx} block={block} />)}</Container>
        <BottomAppBar>
            <ButtonGroup>
                <LoadingButton loading={saving} onClick={handleDiscardClick} color="error" variant="outlined">Discard & go back</LoadingButton>
                <LoadingButton  loading={saving} variant="contained" onClick={handleFinalizeImportClick}>Finish import</LoadingButton>
            </ButtonGroup>
        </BottomAppBar>
    </>
}

const RenderBlock = (props: { block : AppBlock }) => {
    const { block } = props
    switch (block.type) {
        case 'h':
            return <Typography variant="h6">
                <div dangerouslySetInnerHTML={{ __html: block.content }}></div></Typography>
        case 'p':
        return <Typography variant="body1">
                <div dangerouslySetInnerHTML={{ __html: block.content }}></div>
            </Typography>
        case 'table':
            return <AppTable colsCount={block.colsCount} rows={block.rows} />
    }
}

function AppTable(props: { colsCount: number, rows: AppRow[] }) {
    const { colsCount, rows } = props
    const firstTrIdx = rows.findIndex(r => r.type === 'tr')
    const ths = rows.slice(0, firstTrIdx)
    const trs = rows.slice(firstTrIdx)
    return <TableContainer sx={{
        my: 1,
    }} component={Paper}>
        <Table>
            {ths.length > 0 && <TableHead>
                {ths.map(row => <TableRow>
                    {row.cols.map(col => <TableCell sx={{ minWidth: '150px' }}><span dangerouslySetInnerHTML={{ __html: col}}></span></TableCell>)}
                </TableRow>)}
            </TableHead>}
            <TableBody>
                {trs.map(row => <TableRow>
                    {row.cols.map(col => <TableCell>
                        <p dangerouslySetInnerHTML={{ __html: col }}></p>
                    </TableCell>)}
                </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>;
}
