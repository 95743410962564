import { Localized } from "@fluent/react";
import { Box, Button, ButtonGroup, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Skeleton } from '@mui/material';
import { LoadURSTemplates } from "../../app/AppDataLoader";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAllUrsTemplates, UrsTemplate } from "../urstemplates/ursTemplatesSlice";
import SaveIcon from '@mui/icons-material/Save';
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { AppId, AppTextFieldHandler } from "../../app/appTypes";
import { createURS, UrsHeader, Urs } from "./UrsSlice";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { DeviceTypeCombobox } from "../device/DeviceTypeCombobox";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { selectStructureNodeById } from '../device/structureSlice';
import { If } from "../../app/If";
import { StructureName } from "../device/Structure";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { selectAllUrs } from "./ursListSlice";
import { useLocation } from "react-router-dom";
import { Relationship, selectStructureRelationship } from "../device/structureRelationshipSlice";
import { RichTextEditor } from "../../app/RichTextEditor";
import { Help } from "../dialogs/HelpDialog";
import { useNavigate } from "react-router-dom";
import { loadSettingsForCompany } from "../systemSettings/systemDocumentSettings/SystemDocumentSettingsApi";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectContextStructure } from "../structure/contextStructureSlice";


const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>
type FormTabs = "purpose" | "description" | "area"
type CreateFromOptions = "Empty" | "Template" | "URS"
type UrsNumberingMode = "ONLY_SIMPLE_REQ" | "UNIQUE_REQ" | "DECIMAL_HIERARCHICAL"

export const CreateURS = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { state, search } = useLocation()

    const params = new URLSearchParams(search)

    const processId = params.has("processId") ? params.get("processId") : "";

    const allURSTemplates = useAppSelector(selectAllUrsTemplates)
    const { data: contextStructure } = useAppSelector(selectContextStructure)
    const contextStructureId = contextStructure && contextStructure.mode === 'manual' ? contextStructure.structId : ''
    const structure = useAppSelector(state => selectStructureNodeById(state, contextStructureId))
    const allUrs = useAppSelector(selectAllUrs)

    const [deviceTypeId, setDeviceTypeId] = useState<AppId | undefined>(structure && structure.subStructureIds && structure.subStructureIds.length === 0 ? structure.id : "");
    const deviceType = useAppSelector(state => selectStructureNodeById(state, deviceTypeId ?? ""))
    const relationship = useAppSelector(selectStructureRelationship)
    const [ursNumber, setUrsNumber] = useState("")
    const [ursTitle, setUrsTitle] = useState("")
    const [language, setLanguage] = useState("PL")
    const [purpose, setPurpose] = useState("")
    const [description, setDescription] = useState("")
    const [area, setArea] = useState("")
    const [selectedTemplate, setSelectedTemplate] = useState<UrsTemplate | undefined>(undefined);
    const [selectedOption, setSelectedOption] = useState<CreateFromOptions>("Empty");
    const [numberingMode, setNumberingMode] = useState<UrsNumberingMode>("DECIMAL_HIERARCHICAL");
    const [showForType, setShowForType] = useState(true);

    useEffect(() => {
        if(selectedOption === "Empty") {
            dispatch(loadSettingsForCompany(`?documentCode=URS`))
                .then(unwrapResult)
                .then((response) => {
                    const numMode = response.find(f => f.name === "NUMBERING_MODE");
                    if(numMode !== undefined) {
                        setNumberingMode(numMode.value as UrsNumberingMode);
                    }
                })
                .catch((error) => console.error(error))
        }
    }, [selectedOption])

    const [selectedUrs, setSelectedUrs] = useState<UrsHeader | undefined>(() => {
        if (state && state[`defaultUrsId`]) {
            const urs = allUrs.find(x => x.id == state[`defaultUrsId`])
            setSelectedOption("URS")
            if (urs) {
                setDescription(urs.description || "")
                setPurpose(urs.purpose || "")
                setArea(urs.area || "")
                return urs
            } else {
                return undefined
            }
        } else {
            return undefined
        }
    })


    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)
    const [templateDialogVisible, setTemplateDialogVisible] = useState<boolean>(false);
    const [ursBasedOnDialogVisible, setUrsBasedOnDialogVisible] = useState<boolean>(false);


    const [tab, setTab] = useState<FormTabs>("purpose");
    const numberError = validationErrors && ursNumber === ""
    const ursTitleError = validationErrors && ursTitle === ""
    const deviceTypeError = validationErrors && deviceTypeId === ""
    const templateError = validationErrors && selectedOption === "Template" && selectedTemplate === undefined
    const ursError = validationErrors && selectedOption === "URS" && selectedUrs === undefined

    const UrsDocuments = useMemo(() => {
        if (showForType) {
            return allUrs.filter(x => x.deviceTypeId == deviceType?.id ?? "")
        } else if (deviceType) {
            const rel = relationship.entities[deviceType.parentStructureId ?? ""] as Relationship
            const ids = rel.succs.filter(x => x.leaf === true).map(x => x.structureId)
            return allUrs.filter(t => ids.some(structureId => structureId === t.deviceTypeId))
        } else {
            return allUrs
        }
    }, [allUrs, showForType, deviceType])

    const URSTemplates = useMemo(() => {
        if (showForType) {
            return allURSTemplates.filter(x => x.deviceTypeId == deviceType?.id ?? "")
        } else if (deviceType) {
            const rel = relationship.entities[deviceType.parentStructureId ?? ""] as Relationship
            const ids = rel.succs.filter(x => x.leaf === true).map(x => x.structureId)
            return allURSTemplates.filter(t => ids.some(structureId => structureId === t.deviceTypeId))
        } else {
            return allURSTemplates
        }
    }, [allURSTemplates, showForType, deviceType, relationship])


    const handleDeviceTypeIdChange = useCallback(structureId => setDeviceTypeId(structureId), [setDeviceTypeId])

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const handleShowForTypeChange = (event) => {
        setShowForType(event.target.checked);
    };

    const handleNumberingModeChange = (value) => {
        setNumberingMode(value);
    };

    const handleUrsTitleChange: AppTextFieldHandler = useCallback(e => {
        setUrsTitle(e.target.value)
    }, [])

    const handleUrsNumberChange: AppTextFieldHandler = useCallback(e => {
        setUrsNumber(e.target.value)
    }, [])

    const handleTemplateClick = () => {
        setSelectedOption('Template')
    }

    const handleUrsClick = () => {
        setSelectedOption('URS')
    }

    const hadleEmptyClick = () => {
        setSelectedOption('Empty')
        setSelectedUrs(undefined)
        setDescription("")
        setPurpose("")
        setArea("")
        setSelectedTemplate(undefined)
    }

    const handleChooseUrsClick = useCallback((newUrs: UrsHeader) => {
        setSelectedUrs(newUrs)
        setDescription(newUrs.description || "")
        setPurpose(newUrs.purpose || "")
        setArea(newUrs.area || "")
        setSelectedTemplate(undefined)
        setUrsBasedOnDialogVisible(false)
    }, [])

    const handleCancelUrsTemplateClick = useCallback(() => {
        setTemplateDialogVisible(false)
    }, [])

    const handleCancelUrsClick = useCallback(() => {
        setUrsBasedOnDialogVisible(false)
    }, [])

    const handleChooseTemplateClick = useCallback((template: UrsTemplate) => {
        setSelectedTemplate(template)
        setDescription("")
        setPurpose("")
        setArea("")
        setSelectedUrs(undefined)
        setTemplateDialogVisible(false)
    }, [])


    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const handleSaveButtonClick = async () => {
        setValidationErrors(true)

        if (deviceTypeId && ursNumber && ursTitle) {
            if ((selectedOption === "Template" && selectedTemplate === undefined) ||
                (selectedOption === "URS" && selectedUrs === undefined)) {
                return
            }
            setSaving(true)
            try {
                const result =
                    await dispatch(createURS({
                        createFrom: selectedOption,
                        numberingMode: numberingMode,
                        deviceTypeId: deviceTypeId,
                        ursNumber: ursNumber,
                        title: ursTitle,
                        ursTemplateId: selectedTemplate?.id,
                        ursId: selectedUrs?.id,
                        purpose: purpose,
                        area: area,
                        description: description,
                        language,
                        processId: processId
                    })
                    )
                if (createURS.fulfilled.match(result)) {
                    const newUrs = result.payload as Urs;
                    dispatch(showSuccess("saved"))
                    navigate(`/urs/edit/${newUrs.id}?structureId=${deviceTypeId ?? ""}`, { replace: true })
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    return (
        <Container sx={{
            paddingTop: 2,
            paddingBottom: 2,
        }} component={Paper} maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DeviceTypeCombobox
                        onlyLeafs={true}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        error={deviceTypeError}
                        helperText={deviceTypeError && <RequiredError />}
                        value={deviceTypeId}
                        onChange={handleDeviceTypeIdChange}
                        label={<Localized id="urs-device-type">Typ</Localized>}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        helperText={numberError && <RequiredError />}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={ursNumber}
                        error={numberError}
                        inputProps={{ maxLength: 50 }}
                        onChange={handleUrsNumberChange}
                        label={<Localized id="urs-number"><span>Numer URS</span></Localized>}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl sx={{ width: '80%' }} >
                        <InputLabel id="app-language-label"><Localized id='document-language'>Język</Localized></InputLabel>
                        <Select
                            labelId="app-language-label"
                            id="app-language"
                            value={language}
                            onChange={e => setLanguage(e.target.value)}
                            label={<Localized id='document-language'>Język</Localized>}
                            fullWidth
                        >
                            <MenuItem value={'PL'}>PL</MenuItem>
                            <MenuItem value={'EN'}>EN</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        helperText={ursTitleError && <RequiredError />}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={ursTitle}
                        error={ursTitleError}
                        onChange={handleUrsTitleChange}
                        label={<Localized id="urs-title"><span>Tytuł</span></Localized>}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,

                        }}
                        InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    size="small"
                                    color={selectedOption === 'Empty' ? "secondary" : "primary"}
                                    onClick={() => hadleEmptyClick()}
                                    style={{ marginRight: '10px' }}
                                >
                                    <Localized id="urs-create-empty">Pusty</Localized>
                                </Button>
                                <Button
                                    size="small"
                                    color={selectedOption === 'Template' ? "secondary" : "primary"}
                                    onClick={() => handleTemplateClick()}
                                    style={{ marginRight: '10px' }}
                                >
                                    <Localized id="urs-create-template">Szablon</Localized>
                                </Button>
                                <Button
                                    size="small"
                                    color={selectedOption === 'URS' ? "secondary" : "primary"}
                                    onClick={() => handleUrsClick()}
                                >
                                    URS
                                </Button>

                            </InputAdornment>
                        }}
                        label={<Fragment>
                            <Localized id="urs-create-question">Jak wygenerować URS?</Localized>
                            <Help id="8be28b75-4ca8-4967-8cdb-91a3a1828c2e" />
                        </Fragment>
                        }
                    ></TextField>

                </Grid>
                <If condition={selectedOption == "Template"}>
                    <Grid item xs={8}>
                        <LoadURSTemplates component={<Skeleton />}>
                            <TextField
                                label={<Localized id="urs-template"><span>Szablon URS</span></Localized>}
                                value={selectedTemplate?.ursNo}
                                fullWidth
                                required
                                error={templateError}
                                helperText={templateError && <RequiredError />}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position="end"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <IconButton
                                            onClick={() => setTemplateDialogVisible(true)}
                                        >
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            >
                            </TextField>
                        </LoadURSTemplates>
                    </Grid>
                </If>
                <If condition={selectedOption == "URS"}>
                    <Grid item xs={8}>
                        <LoadURSTemplates component={<Skeleton />}>
                            <TextField
                                label={<span>URS</span>}
                                value={selectedUrs?.ursNo}
                                fullWidth
                                required
                                error={ursError}
                                helperText={ursError && <RequiredError />}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position="end"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <IconButton
                                            onClick={() => setUrsBasedOnDialogVisible(true)}
                                        >
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            >
                            </TextField>
                        </LoadURSTemplates>
                    </Grid>
                </If>
                <If condition={selectedOption == "Empty"}>
                    <Grid item xs={8}>
                        <TextField
                            value={numberingMode}
                            sx={{ width: '50%' }}
                            select
                            label={<Fragment>
                                <Localized id="urs-numbering-method">Sposób numerowania  </Localized>
                                <Help id="7e0300fb-e1cf-4810-aab7-183f5e68d975" />
                            </Fragment>
                            }
                            onChange={e => handleNumberingModeChange(e.target.value)}
                        >

                            <MenuItem value={"DECIMAL_HIERARCHICAL"}><Localized id="urs-hierarchical"><span>Hierarchiczne</span></Localized></MenuItem>
                            <MenuItem value={"UNIQUE_REQ"}><Localized id="urs-unique"><span>Unikalne</span></Localized></MenuItem>
                            <MenuItem value={"ONLY_SIMPLE_REQ"}><Localized id="urs-simple"><span>Proste</span></Localized></MenuItem>
                        </TextField>
                    </Grid>
                </If>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                key={0}
                                value={"purpose"}
                                label={<Localized id="urs-purpose">Cel</Localized>} />
                            <Tab
                                key={1}
                                value={"description"}
                                label={<Localized id="urs-description">Opis</Localized>} />
                            <Tab
                                key={2}
                                value={"area"}
                                label={<Localized id="urs-area">Obszar działania</Localized>} />
                        </TabList>
                        <TabPanel value="purpose">
                            <Box sx={{ minHeight: 200, height: 200, overflow: 'auto' }}>
                                <RichTextEditor label={<Localized id="urs-purpose"><span>Cel</span></Localized>} value={purpose} onValueChange={setPurpose} />
                            </Box>
                        </TabPanel>
                        <TabPanel value="description">
                            <Box sx={{ minHeight: 200, height: 200, overflow: 'auto' }}>
                                <RichTextEditor label={<Localized id="urs-description"><span>Opis</span></Localized>} value={description} onValueChange={setDescription} />
                            </Box>
                        </TabPanel>
                        <TabPanel value="area">
                            <Box sx={{ minHeight: 200, height: 200, overflow: 'auto' }}>
                                <RichTextEditor label={<Localized id="urs-area"><span>Obszar działania</span></Localized>} value={area} onValueChange={setArea} />
                            </Box>
                        </TabPanel>
                    </TabContext>
                </Grid>

                <Grid item xs={12}>
                    <ButtonGroup variant="contained" color="secondary">
                        <Button onClick={handleSaveButtonClick} disabled={saving} startIcon={<SaveIcon />}>
                            <Localized id="save">
                                <span>Zapisz</span>
                            </Localized>
                        </Button>
                        <Button onClick={handleBackButtonClick} variant="outlined">
                            <Localized id="cancel">Anuluj</Localized>
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Dialog open={templateDialogVisible} fullWidth maxWidth="md">
                <DialogTitle>
                    <Localized id="urs-template">
                        <span>Szablon URS</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showForType}
                                onChange={handleShowForTypeChange}
                                color="primary"
                            />
                        }
                        label={<Localized id="urs-create-show-for-device-type">
                            <span>Pokaż tylko zgodne z typem urządzenia</span>
                        </Localized>
                        }
                    />
                    <TableContainer
                        component={Paper}
                        sx={{ display: 'flex', height: 'calc(100vh - 440px)' }}
                    >
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            <Localized id="urs-template-code">
                                                <span>Kod</span>
                                            </Localized>
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography variant="subtitle2" color="textSecondary">
                                            <Localized id="urs-template-description">
                                                <span>Opis</span>
                                            </Localized>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 300, width: 300, maxWidth: 300 }}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            <Localized id="urs-device-type">
                                                <span>Nazwa typu rządzenia</span>
                                            </Localized>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            <Localized id="system-item">
                                                <span>Systemowy</span>
                                            </Localized>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {URSTemplates.map(row => {
                                    return <TableRow key={row.id}>
                                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}><Typography component="span">{row.ursNo}</Typography></TableCell>
                                        <TableCell ><Typography component="span">{row.docDesignation}</Typography></TableCell>
                                        <TableCell sx={{ minWidth: 300, width: 300, maxWidth: 300 }}><StructureName structureId={row.deviceTypeId} /></TableCell>
                                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                            <Checkbox defaultChecked={row.isSystem} disabled />
                                        </TableCell>

                                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                            <Button color="primary" variant="outlined" onClick={() => handleChooseTemplateClick(row)} >
                                                <Localized id="select">
                                                    <span>Wybierz</span>
                                                </Localized>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelUrsTemplateClick}>
                        <Localized id="cancel">
                            <span>Anuluj</span>
                        </Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={ursBasedOnDialogVisible} fullWidth maxWidth="md">
                <DialogTitle>
                    <span>URS</span>
                </DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showForType}
                                onChange={handleShowForTypeChange}
                                color="primary"
                            />
                        }
                        label={<Localized id="urs-create-show-for-device-type">
                            <span>Pokaż tylko zgodne z typem urządzenia</span>
                        </Localized>
                        }
                    />
                    <TableContainer
                        component={Paper}
                        sx={{ display: 'flex', height: 'calc(100vh - 440px)' }}
                    >
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Localized id="urs-number">Numer URS</Localized>
                                    </TableCell>
                                    <TableCell>
                                        <Localized id="urs-device-type">Typ urzadzenia</Localized>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {UrsDocuments.map(row => {
                                    return <TableRow key={row.id}>
                                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}><Typography component="span">{row.ursNo}</Typography></TableCell>
                                        <TableCell sx={{ minWidth: 300, width: 300, maxWidth: 300 }}><StructureName structureId={row.deviceTypeId} /></TableCell>
                                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                            <Button color="primary" variant="outlined" onClick={() => handleChooseUrsClick(row)} >
                                                <Localized id="select">
                                                    <span>Wybierz</span>
                                                </Localized>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelUrsClick}>
                        <Localized id="cancel">
                            <span>Anuluj</span>
                        </Localized>
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
