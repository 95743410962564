import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { APIError, AppThunkAPIType } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { post } from "../../http";
import { Role } from "../users/usersSlice";

export interface UserState {
    token: string | undefined;
    tokenState: "empty" | "valid" | "invalid" | undefined;

}

interface JWTToken {
    unique_name: string,
    sub: string,
    role: string,
    companyId: string
    companyName: string
    nameid: string
}

const initialState: UserState = {
    token: undefined,
    tokenState: undefined,
}

export interface Credentials {
    username: string;
    password: string;
}

export interface LoginResponse {
    token: string,
    requires2FA: boolean;
    emailFor2FA: string;
    passwordExpired: boolean;
}


export interface TwoFAResponse {
    requires2FA: boolean;
    emailFor2FA: string;
}

export interface Verify2FaRequest {
    email: string;
    code: string;
}


export interface AccountConfirmation {
    user: string;
    password: string;
    passwordConfirmation: string;
    token: string;
}

export interface ResetPasswordType {
    email: string;
    password: string;
    passwordConfirmation: string;
    token: string;
}

export interface ChangePasswordType {
    email: string;
    oldPassword: string;
    newPassword: string;
}

export interface ForgotPasswordType {
    email: string;
}

export const loadTokenFromStorage = createAsyncThunk("user/loadTokenFromStorage", async () => {
    const token: string | null = localStorage.getItem("token");

    if (token)
        return token as string;
    else
        throw new Error("Token not found")
})

export const logout = createAsyncThunk<void, void, AppThunkAPIType>("user/logout", async () => {
    localStorage.removeItem("token");
})

export const login = createAsyncThunk<LoginResponse, Credentials, { rejectValue: APIError }>('user/login', async (c: Credentials, thunkAPI) => {
    try {
        const response = await post("api/AuthManagement/Login", c);

        if (response.ok) {
            const data = await response.json();

            if (data.requires2FA || data.passwordExpired) {
                return data;
            }

            if (data.token && !data.requires2FA) {
                localStorage.setItem("token", data.token);
                return data;
            }
        }

        return thunkAPI.rejectWithValue({
            kind: "http",
            status: response.status,
            body: await response.text(),
        });

    } catch (error) {
        return thunkAPI.rejectWithValue({
            kind: 'connection',
        })
    }
})

export const verify2FA = createAsyncThunk<string, Verify2FaRequest, { rejectValue: APIError }>('user/verify2FA', async (p: Verify2FaRequest, thunkAPI) => {
    try {
        const response = await post("api/AuthManagement/verify-2fa", p);

        if (response.ok) {
            const data = await response.json();

            if (data.token) {
                localStorage.setItem("token", data.token);
                return data.token;
            }
        }

        return thunkAPI.rejectWithValue({
            kind: "http",
            status: response.status,
            body: await response.text(),
        });

    } catch (error) {
        return thunkAPI.rejectWithValue({
            kind: 'connection',
        })
    }
})



export const confirmAccount = createAsyncThunk<string, AccountConfirmation, { rejectValue: APIError }>('user/accountConfirmation', async (c: AccountConfirmation, thunkAPI) => {
    try {
        const response = await post("api/AuthManagement/ConfirmAccount", c);

        if (response.ok) {


            return "";
        }

        return thunkAPI.rejectWithValue({
            kind: "http",
            status: response.status,
            body: await response.text(),
        });

    } catch (error) {
        return thunkAPI.rejectWithValue({
            kind: 'connection',
        })
    }
})

export const resetPassword = createAsyncThunk<string, ResetPasswordType, { rejectValue: APIError }>
    ('user/resetpassword', async (resetPassword: ResetPasswordType, thunkAPI) => {
        try {
            const response = await post("api/AuthManagement/reset-password", resetPassword);

            if (response.ok) {
                return "";
            }

            return thunkAPI.rejectWithValue({
                kind: "http",
                status: response.status,
                body: await response.text(),
            });

        } catch (error) {
            return thunkAPI.rejectWithValue({
                kind: 'connection',
            })
        }
    })

export const changePassword = createAsyncThunk<string, ChangePasswordType, { rejectValue: APIError }>
    ('user/change-password', async (changePassword: ChangePasswordType, thunkAPI) => {
        try {
            const response = await post("api/AuthManagement/change-password", changePassword);

            if (response.ok) {
                return "";
            }

            return thunkAPI.rejectWithValue({
                kind: "http",
                status: response.status,
                body: await response.text(),
            });

        } catch (error) {
            return thunkAPI.rejectWithValue({
                kind: 'connection',
            })
        }
    })

export const forgotPassword = createAsyncThunk<string, ForgotPasswordType, { rejectValue: APIError }>
    ('user/forgotPassword', async (forgotPassword: ForgotPasswordType, thunkAPI) => {
        try {
            const response = await post("api/AuthManagement/forgot-password", forgotPassword);

            if (response.ok) {
                return "";
            }

            return thunkAPI.rejectWithValue({
                kind: "http",
                status: response.status,
                body: await response.text(),
            });

        } catch (error) {
            return thunkAPI.rejectWithValue({
                kind: 'connection',
            })
        }
    })


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        invalidateToken: state => {
            state.tokenState = "invalid"
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action) => {
            if (action.payload.token && !action.payload.requires2FA) {
                state.token = action.payload.token;
                state.tokenState = "valid";
            }
        });

        builder.addCase(verify2FA.fulfilled, (state, action) => {
            state.token = action.payload;
            state.tokenState = "valid";
        });

        builder.addCase(logout.fulfilled, (state) => {
            state.token = undefined;
            state.tokenState = undefined;
        });
        builder.addCase(loadTokenFromStorage.fulfilled, (state, action) => {
            state.token = action.payload;
            state.tokenState = "valid";
        });
        builder.addCase(loadTokenFromStorage.rejected, (state) => {
            state.token = undefined;
            state.tokenState = "empty";
        });
    },
})

export const { invalidateToken } = userSlice.actions

export const selectUser = (state: RootState) => state.user

export const selectIsTokenValid = (state: RootState) => selectUser(state).tokenState === "valid"

const selectToken = (state: RootState) => state.user.token

export const selectBearerToken = createSelector(selectUser, user => {
    return {
        'Authorization': `Bearer ${user.token ?? 'null'}`,
    }
})

export const selectLoggedUser = createSelector(selectToken, token => {
    if (token) {
        const { sub }: JWTToken = jwtDecode(token)
        return sub
    }

    return ""
})

export const selectUserRole = createSelector(selectToken, token => {
    if (token) {
        const { role }: JWTToken = jwtDecode(token)
        return role as Role
    }

    return []
})

export const selectUserCompanyId = createSelector(selectToken, token => {
    if (token) {
        const { companyId }: JWTToken = jwtDecode(token)
        return companyId
    }

    return null
})

export const selectUserCompanyName = createSelector(selectToken, token => {
    if (token) {
        const { companyName }: JWTToken = jwtDecode(token)
        return companyName
    }

    return null
})

export const selectLoggedUserId = createSelector(selectToken, token => {
    if (token) {
        const { nameid }: JWTToken = jwtDecode(token)
        return nameid
    }

    return ""
})

export default userSlice.reducer