import { UrsOfferRequirement } from "./ursOfferSlice";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { Comment, DiscussOld } from "../../app/Discuss";
import './specMatching.css';
import { SpecComponent, SpecWithDetails } from "../specs/specSlice";
import { SpecItemsTree } from "../specs/SpecItemsTree";
import { useCallback, useState } from "react";
import { Box, Button, ButtonGroup, Chip, Divider, Fab, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { selectLoggedUserId } from "../user/userSlice";
import CableIcon from '@mui/icons-material/Cable';
import { MatchDoc } from "./types";
import { SupplierDevice } from "../supplierdevices/supplierDeviceSlice";
import { SupplierDeviceDetail } from "../supplierdevices/supplierDeviceDetailsSlice";
import { Localized } from "@fluent/react";

export interface MatchRequirementWithSpecProps {
    requirement: UrsOfferRequirement
    publicComments: Comment[]
    privateCommenst: Comment[]
    matchDoc: MatchDoc
    match: (specComponentId: string | null, deviceDetailId: string | null) => Promise<void>
}

type Tab = 'offer' | 'fs' | 'ds'

const findComponent = (cs: SpecComponent[], id: number): SpecComponent | null => {
    for (let i = 0; i < cs.length; i++) {
        if (cs[i].id === id) {
            return cs[i]
        } else {
            const child = findComponent(cs[i].childComponents, id)
            if (child) {
                return child
            }
        }
    }
    return null
}

export const MatchRequirementWithSpec = (props: MatchRequirementWithSpecProps) => {
    const {  matchDoc } = props
    switch (matchDoc.tag) {
        case 'fds': return <MatchWithFDS {...props} specDetails={matchDoc.spec} />
        case 'fs&ds': return <MatchWithFSDS {...props} fsSpecDetails={matchDoc.fs} dsSpecDetails={matchDoc.ds} />
        case 'offer': return <MatchWithOffer {...props} dev={matchDoc.device} devDetails={matchDoc.deviceDetails} />
    }
}

export const MatchWithFDS = (props: MatchRequirementWithSpecProps & { specDetails: SpecWithDetails }) => {
    const { match, requirement, publicComments, privateCommenst, specDetails } = props
    const { deviceComponentId } = requirement

    const currentUserId = useAppSelector(selectLoggedUserId)

    const [componentId, setComponentId] = useState<string | undefined>(requirement.deviceComponentId ?? undefined)
    const handleComponentSelection = useCallback((newId: string) => {
        setComponentId(newId)
    }, [])

    const idNum = parseInt(componentId ?? '')
    const selectedComponent: SpecComponent | null = findComponent(specDetails.components, idNum)

    const renderTreeItem: (c: SpecComponent) => JSX.Element = useCallback((c) => {
        const { id, numeration, name } = c
        const label = numeration ?
            <><span><i>{numeration}.</i></span>&nbsp;<span>{name}</span></> :
            <span>{name}</span>
        const matched = id.toString() === deviceComponentId
        return <Typography component='div' variant={matched ? 'h6' : 'body1'} sx={{
            color:  matched ? 'green' : 'inherit'
        }}>{label}</Typography>
    }, [deviceComponentId])


    return <div className="spec-matching">
        <div className="tile header">
            <div className="title">
                Requirement description
                {componentId}&nbsp;{requirement.deviceComponentId}
            </div>
            <Divider />
            <Chip sx={{ mt: 1 }} variant="filled" label={requirement.numeration}></Chip>
            <div dangerouslySetInnerHTML={{ __html : requirement.name}}></div>
        </div>
        <div className="tile header">
            <div className="title">
                Internal discussion
            </div>
            <Divider />
            <Box sx={{ mt: 1 }}>
                <DiscussOld
                    postComment={null}
                    updateComment={null}
                    deleteComment={null}
                    allComments={privateCommenst}
                    currentUserId={currentUserId}
                    showTitle={false}
                    readOnly={true}
                />
            </Box>
        </div>
        <div className="tile header">
            <div className="title">
                Discussion with client
            </div>
            <Divider />
            <Box sx={{ mt: 1 }}>
                <DiscussOld
                    postComment={null}
                    updateComment={null}
                    deleteComment={null}
                    allComments={publicComments}
                    currentUserId={currentUserId}
                    showTitle={false}
                    readOnly={true}
                />
            </Box>
        </div>
        <div className="tile content">
            <div className="title">FDS index</div>
            <Divider />
            <Box sx={{ my: 1 }}>
                <SpecItemsTree readonly selectedItemId={componentId} data={specDetails} onSelectionChange={handleComponentSelection} render={renderTreeItem} />
            </Box>
        </div>
        <div className="tile spec-detail content">
            <div className="title">Description</div>
            <Divider />
            {selectedComponent !== null && <div dangerouslySetInnerHTML={{ __html: selectedComponent.functionalSpecification ?? '' }}  />}
            <Fab sx={{
                position: 'absolute',
                right: 32,
                bottom: 100,
            }} color='secondary' onClick={() => {
                if (componentId) {
                    match(componentId, requirement.supplierDeviceDetailId)
                }
            }}>
                <CableIcon />
            </Fab>
        </div>
    </div>
}
export const MatchWithFSDS = (props: MatchRequirementWithSpecProps & { fsSpecDetails: SpecWithDetails, dsSpecDetails: SpecWithDetails }) => {
    const { match, requirement, publicComments, privateCommenst, fsSpecDetails, dsSpecDetails } = props

    const currentUserId = useAppSelector(selectLoggedUserId)

    const [tab, setTab] = useState<'fs' | 'ds'>('fs')
    const [componentId, setComponentId] = useState<string | undefined>(requirement.deviceComponentId ?? undefined)
    const handleComponentSelection = useCallback((newId: string) => {
        setComponentId(newId)
    }, [])

    const idNum = parseInt(componentId ?? '')
    const selectedComponent: SpecComponent | null = tab === 'fs'
        ? findComponent(fsSpecDetails.components, idNum)
        : findComponent(dsSpecDetails.components, idNum)

    return <div className="spec-matching">
        <div className="tile header">
            <div className="title">
                Requirement description
                {componentId}&nbsp;{requirement.deviceComponentId}
            </div>
            <Divider />
            <Chip sx={{ mt: 1 }} variant="filled" label={requirement.numeration}></Chip>
            <div dangerouslySetInnerHTML={{ __html : requirement.name}}></div>
        </div>
        <div className="tile header">
            <div className="title">
                Internal discussion
            </div>
            <Divider />
            <Box sx={{ mt: 1 }}>
                <DiscussOld
                    postComment={null}
                    updateComment={null}
                    deleteComment={null}
                    allComments={privateCommenst}
                    currentUserId={currentUserId}
                    showTitle={false}
                    readOnly={true}
                />
            </Box>
        </div>
        <div className="tile header">
            <div className="title">
                Discussion with client
            </div>
            <Divider />
            <Box sx={{ mt: 1 }}>
                <DiscussOld
                    postComment={null}
                    updateComment={null}
                    deleteComment={null}
                    allComments={publicComments}
                    currentUserId={currentUserId}
                    showTitle={false}
                    readOnly={true}
                />
            </Box>
        </div>
        <div className="tile content">
            <TabContext value={tab}>
                <TabList onChange={(_, newTab) => setTab(newTab)}>
                    <Tab label='Functional specification' value='fs' />
                    <Tab label='Design specification' value='ds' />
                </TabList>
                <TabPanel sx={{ px: 0, py: 0.5 }} value='fs'>
                    <div className="tile content">
                        <Box sx={{ my: 1 }}>
                            <SpecItemsTree readonly selectedItemId={componentId} data={fsSpecDetails} onSelectionChange={handleComponentSelection} />
                        </Box>
                    </div>
                </TabPanel>
                <TabPanel sx={{ px: 0, py: 0.5 }} value='ds'>
                    <div className="tile content">
                        <Box sx={{ my: 1 }}>
                            <SpecItemsTree readonly selectedItemId={componentId} data={dsSpecDetails} onSelectionChange={handleComponentSelection} />
                        </Box>
                    </div>
                </TabPanel>
            </TabContext>
        </div>
        <div className="tile spec-detail content">
            <div className="title">Description</div>
            <Divider />
            {selectedComponent !== null && <div dangerouslySetInnerHTML={{ __html: selectedComponent.functionalSpecification ?? '' }}  />}
            <Fab sx={{
                position: 'absolute',
                right: 32,
                bottom: 100,
            }} color='secondary' onClick={() => {
                // if (componentId) {
                //     match(componentId)
                // }
            }}>
                <CableIcon />
            </Fab>
        </div>
    </div>
}
export const MatchWithOffer = (props: MatchRequirementWithSpecProps & { dev: SupplierDevice, devDetails: SupplierDeviceDetail[] }) => {
    const { match, requirement, publicComments, privateCommenst, dev, devDetails } = props

    const currentUserId = useAppSelector(selectLoggedUserId)

    const [componentId, setComponentId] = useState<string | undefined>(requirement.deviceComponentId ?? undefined)
    const [saving, setSaving] = useState(false)

    const handleComponentSelection = useCallback((newId: string) => {
        setComponentId(newId)
    }, [])
    const handleMatchClick = (componentId: string) => {
        setSaving(true)
        match(null, componentId).then(() => {
            setSaving(false)
        })
    }

    const idNum = parseInt(componentId ?? '')

    const { supplierDeviceDetailId } = requirement

    return <>
        <div className="spec-matching">
            <div className="tile header">
                <div className="title">
                    Requirement description
                    {componentId}&nbsp;{requirement.deviceComponentId}
                </div>
                <Divider />
                <Chip sx={{ mt: 1 }} variant="filled" label={requirement.numeration}></Chip>
                <div dangerouslySetInnerHTML={{ __html : requirement.name}}></div>
            </div>
            <div className="tile header">
                <div className="title">
                    Internal discussion
                </div>
                <Divider />
                <Box sx={{ mt: 1 }}>
                    <DiscussOld
                        postComment={null}
                        updateComment={null}
                        deleteComment={null}
                        allComments={privateCommenst}
                        currentUserId={currentUserId}
                        showTitle={false}
                        readOnly={true}
                    />
                </Box>
            </div>
            <div className="tile header">
                <div className="title">
                    Discussion with client
                </div>
                <Divider />
                <Box sx={{ mt: 1 }}>
                    <DiscussOld
                        postComment={null}
                        updateComment={null}
                        deleteComment={null}
                        allComments={publicComments}
                        currentUserId={currentUserId}
                        showTitle={false}
                        readOnly={true}
                    />
                </Box>
            </div>
        </div>
        <TableContainer sx={{ mt: 1, maxHeight: 'calc(100vh - 440px)' }} component={Paper}>
            <Typography sx={{ p: 1 }} color='text.secondary' component='div' variant='caption'>Device details</Typography>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ width: "60px" }}>
                        <Localized id="lp">
                            <span>L.p.</span>
                        </Localized>
                    </TableCell>
                    <TableCell>
                        <Localized id="devices-parameter-name">
                            <span>Nazwa</span>
                        </Localized>
                    </TableCell>
                    <TableCell>  
                        <Localized id="devices-parameter-description">
                            <span>Opis</span>
                        </Localized>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {devDetails.map(row => 
                    <TableRow sx={{
                        bgcolor: supplierDeviceDetailId === row.id ? 'lightgreen' : 'inherit',
                    }} key={row.id}>     
                        <TableCell>{row.position}</TableCell>
                        <TableCell style={{ width: '100%' }}>{row.name}</TableCell>
                        <TableCell 
                            sx={{
                            maxWidth: 1000,
                            whiteSpace: 'nowrap', 
                            overflow: 'hidden',  
                            textOverflow: 'ellipsis'
                            }}
                        >{row.description}</TableCell>
                        <TableCell align="right">
                            <ButtonGroup>
                                <LoadingButton loading={saving} onClick={() => {
                                    handleMatchClick(row.id)
                                }} >Match</LoadingButton>
                            </ButtonGroup>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </TableContainer>
    </>
}
