import { Localized, useLocalization } from "@fluent/react";
import {
    Button,
    ButtonGroup, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid, Paper,
    Stack,
    Tab, TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    styled
} from "@mui/material";
import { Skeleton } from '@mui/material';
import { generatePath } from "react-router";
import { LoadDeviceTypes, LoadRequirements, LoadRequirementsTypes, LoadSuppliers } from "../../app/AppDataLoader";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateURS, TeamMember, loadUrs, ActionLink, publishAction, Urs, changeDeadline, substituteUrsTeamMember, UrsActionType, sendReminder, useUrsState, DescriptionField, isUrsLinkAvail } from "./UrsSlice";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { If } from "../../app/If";
import { AppId, AppTextFieldHandler, AppThunkAPIType } from "../../app/appTypes";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { Box } from "@mui/system";
import { selectSettings } from '../settings/settingsSlice';
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { TeamMemberDialogForm } from "./TeamMemberDialogForm";
import { BottomAppBar } from "../../app/BottomAppBar";
import { selectAllURSComments, selectURSComments, URSComment } from "./URSCommentSlice";
import { ActionRunner } from "./ActionRunner";
import { AsyncThunk } from "@reduxjs/toolkit";
import { createTemplateFromUrs, loadUrsTemplate, updateUrsTemplate } from "../urstemplates/ursTemplatesSlice";
import PrintIcon from '@mui/icons-material/Print';
import { Attachements } from "./Attachements";
import DeleteIcon from '@mui/icons-material/Delete';
import { EditDescriptionField } from "./dialogs/EditDescriptionField";
import { EditSimpleDescriptionField } from "./dialogs/EditSimpleDescriptionField";
import { DeleteTeamMemberDialog } from "./dialogs/DeleteTeamMemberDialog";
import { EditDocNumberDialog } from "./dialogs/EditDocNumberDialog";
import { PublishDialog } from "./dialogs/PublishDialog";
import { AddExternalTeamMember } from "./AddExternalTeamMember";
import clsx from "clsx";
import { selectLoggedUser, selectUserCompanyId } from "../user/userSlice";
import { UrsDiff } from "./UrsDiff";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ImportedSupplierUrsHeader } from "./ImportedSupplierUrsHeader";
import { ImportedUrsDetailsPanel } from "./ImportedUrsDetailsPanel";
import { ImportSupplierUrsDialog } from "../documents/import/ImportSupplierUrsDialog";
import { DocumentVersionsPanel } from "../documents/components/DocumentVersionsPanel";
import HistoryIcon from '@mui/icons-material/History';
import UploadIcon from '@mui/icons-material/Upload';
import { ImportedRequirementsPanel } from "./ImportedRequirementsPanel";
import { StructureContextSetter } from "../structure/StructureContextSetter";
import { PermamentDocumentDeleteDialog } from "../documents/deletedDocuments/dialogs/PermamentDocumentDeleteDialog";
import { ImportedRequirementsFlatPanel } from "./ImportedRequirementsFlatPanel";

type MainTabs = "reqs" | "description" | "team" | "offers" | "attachements" | "changelog" | "versions"

const LoadComponent = () => <Skeleton variant="rectangular" height="50vh" />

type Loader = AsyncThunk<Urs, AppId, AppThunkAPIType>
type Updater = AsyncThunk<Urs, Urs, AppThunkAPIType>

type UrsView = 'form' | 'diff'

export interface EditURSProps {
    template: boolean
    view: UrsView
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));


export const ImportedSupplierUrsForm = (props: EditURSProps) => {
    return <LoadRequirementsTypes component={<Skeleton height='50vh' />}>
        <LoadRequirements component={<Skeleton height='50vh' />}>
            <ImportedSupplierUrsFormInternal {...props} />
        </LoadRequirements>
    </LoadRequirementsTypes>
}

export const ImportedSupplierUrsFormInternal = ({ template, view }: EditURSProps) => {
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const load: Loader = template ? loadUrsTemplate : loadUrs
    const dispatch = useAppDispatch()
    const [tab, setTab] = useState<MainTabs>("reqs")
    const [data, setData] = useState<Urs | null>(null)

    const comments = useAppSelector(state => {
        if (selectURSComments(state).ursId === id) {
            return selectAllURSComments(state)
        } else {
            return []
        }
    })


    useEffect(() => {
        const fetchData = async () => {
            try {
                setData(await dispatch(load(id)).unwrap())
            } catch (err) {
                if (typeof err === 'object' && err !== null && err['name'] && err['name'] === 'ConditionError') {
                    // action cancelled
                } else {
                    dispatch(showError("error"))

                }
                console.log(err)
            }
        }
        setData(null)

        fetchData()
    }, [id])

    if (data === null) return <LoadComponent />

    const { metadata } = data
    const Form = metadata ? FormNoTabs : FormWithTabs

    return <LoadDeviceTypes component={<LoadComponent />}>
        <LoadRequirementsTypes component={<LoadComponent />}>
            <LoadSuppliers component={<LoadComponent />}>
                <LoadRequirements component={<LoadComponent />}>
                    <StructureContextSetter structureId={data.deviceTypeId} />
                    {view === 'form' && <Form entity={data} tab={tab} setTab={setTab} comments={comments} template={template} />}
                    {view === 'diff' && <UrsDiff ursId={id} />}
                </LoadRequirements>
            </LoadSuppliers>
        </LoadRequirementsTypes>
    </LoadDeviceTypes>
}

type DialogState
    = "none"
    | "editUrsNumber"
    | "editRequirement"
    | "requirementBrowser"
    | "editTeamMember"
    | "comments"
    | "attachements"
    | "changeTMDeadline"
    | "substituteTM"
    | "inviteTM"
    | "sendReminder"
    | "deleteTM"
    | { tag: 'editDescriptionField', field: DescriptionField, value: string, ursId: AppId }
    | { tag: 'editSimpleDescriptionField', field: DescriptionField, value: string, ursId: AppId }
    | { tag: "confirmTeamMemberDelete", teamMemberId: AppId, user: string }


interface FormProps {
    entity: Urs,
    tab: MainTabs,
    setTab: Dispatch<SetStateAction<MainTabs>>,
    comments: URSComment[],
    template: boolean
}

export const FormWithTabs = (props: FormProps) => {
    const { entity: initialEntity, template } = props
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const update: Updater = template ? updateUrsTemplate : updateURS
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const user = useAppSelector(selectLoggedUser)
    const entity = useUrsState(initialEntity)

    const [dialog, setDialog] = useState<DialogState>("none")
    const handleEditDescriptionFieldClick = useCallback((field: 'purpose' | 'description' | 'area', value: string) => {
        setDialog({ tag: 'editDescriptionField', field, value, ursId: entity.id })
    }, [])
    const handleEditSimpleDescriptionFieldClick = useCallback((field: 'purpose' | 'description' | 'area' | 'title', value: string) => {
        setDialog({ tag: 'editSimpleDescriptionField', field, value, ursId: entity.id })
    }, [])

    const [docDesignation, setDocDesignation] = useState(entity.docDesignation)
    const [newTemplateDocDesignation, setNewTemplateDocDesignation] = useState("")
    const [newTemplateNumber, setNewTemplateNumber] = useState("")

    const [saving, setSaving] = useState(false)
    const [teamMemberToEdit, setTeamMemberToEdit] = useState<TeamMember | undefined>(undefined)

    const [actionToRun, setActionToRun] = useState<ActionLink & { name: string, description: string } | null>(null)
    const [selectedOfferId, setSelectedOfferId] = useState<AppId>("")

    const today = getLocalISODate(new Date().setDate(new Date().getDate() + 7));
    const [newDeadline, setNewDeadline] = useState(today)
    const [deadlineForAll, setDeadlineForAll] = useState<boolean>(false)

    const [commentsOpen, setCommentsOpen] = useState(false)
    const [permamentDeleteConfirmationVisible, setPermamentDeleteConfirmationVisible] = useState<boolean>(false);

    const handleNewTemplateNumberChange: AppTextFieldHandler = useCallback(e => {
        setNewTemplateNumber(e.target.value)
    }, [])

    const handleNewTemplateDocDesignationChange: AppTextFieldHandler = useCallback(e => {
        setNewTemplateDocDesignation(e.target.value)
    }, [])

    const handleDeadlineFieldChange: AppTextFieldHandler = useCallback(e => {
        setNewDeadline(e.target.value)
    }, [])

    const handlePermamentDeleteClick = () => {
        setPermamentDeleteConfirmationVisible(true);
    }

    const onSuccessfullyDeleted = async () => {
        setPermamentDeleteConfirmationVisible(false);
        navigate(`/supplier-imported-urs`, { replace: true });
    };


    const onDeleteCanceled = () => {
        setPermamentDeleteConfirmationVisible(false);
    };

    const handleConfirmSubstituteTMClick = useCallback(async (userName: string | undefined) => {
        try {
            setSaving(true)
            await dispatch(substituteUrsTeamMember({
                ursId: entity.id,
                userName: teamMemberToEdit?.userName ?? "",
                substitution: userName ?? ""
            }))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setTeamMemberToEdit(undefined)
        setDialog("none")
        setSaving(false)
    }, [teamMemberToEdit])

    const handleEditUrsNumber = useCallback(() => {
        setDialog("editUrsNumber")
    }, [])


    const handleDocDesignationChange: AppTextFieldHandler = useCallback(e => setDocDesignation(e.target.value), [])

    const handleDialogCancelClick = useCallback(() => {
        setDialog("none")
    }, [])


    const handleSaveButtonClick = async () => {
        setSaving(true)
        const result = await dispatch(update(entity))

        if (update.fulfilled.match(result)) {
            dispatch(showSuccess("saved"))
        } else {
            dispatch(showError("error"))
        }

        setSaving(false)
    }

    const [showSaveAsUrsTemplateDialog, setShowSaveAsUrsTemplateDialog] = useState(false);

    const [showRiskAnalysisConfMessage, setShowRiskAnalysisConfMessage] = useState(false);
    const [showPublishMessage, setShowPublishMessage] = useState(false);
    const [showImportNewVersion, setShowImportNewVersion] = useState(false);
    const loggedUserCompanyId = useAppSelector(selectUserCompanyId) as string

    const handlePublishClick = async () => {
        setShowPublishMessage(true)
    }
    const handleImportNewVersion = async () => {
        setShowImportNewVersion(true)
    }

    const handleCancelPublishClick = useCallback(() => {
        setShowPublishMessage(false)
    }, [setShowPublishMessage])

    const handleHideImportDialog = useCallback(() => {
        setShowImportNewVersion(false)
    }, [setShowImportNewVersion])

    const onImportSuccess = (id) => {
        navigate(`/supplier-imported-urs/edit/${id}`)
    };


    const handleConfirmPublishClick = async () => {
        setSaving(true)
        try {
            const result = await dispatch(publishAction({
                url: 'api/Urs/publish/' + entity.id,
                companiesIds: [loggedUserCompanyId],
                suppliersIds: []
            }));

            if (publishAction.fulfilled.match(result)) {
                dispatch(showSuccess("saved"))

            }
            else {
                let errorMsg = "error"
                if (result.payload) {
                    if (result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                }
                dispatch(showError(errorMsg))
            }
        }
        catch (error) {
            dispatch(showError("error"))
        }
        finally {
            setShowPublishMessage(false)
            setSaving(false)
        }
    }

    const handleConfirmChangeTMDeadlineClick = async () => {
        try {
            setSaving(true)
            await dispatch(changeDeadline({
                ursId: entity.id,
                teamMemberId: teamMemberToEdit?.id ?? "",
                deadline: newDeadline,
                forAllMembers: deadlineForAll
            }))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
        setSaving(false)
    }

    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const [params, setParams] = useSearchParams()
    const tab = params.get('tab') as MainTabs || 'reqs'
    const setTab = (tab: MainTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const handleMainTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [setTab])


    const handleCancelRiskAnlasisClick = useCallback(() => {
        setShowRiskAnalysisConfMessage(false)
    }, [])

    const handleCancelSaveAsTemplate = useCallback(() => {
        setShowSaveAsUrsTemplateDialog(false)
    }, [])


    const handleConfirmSaveAsTemplate = async () => {
        if (newTemplateDocDesignation) {
            setSaving(true)
            try {
                const result = await dispatch(createTemplateFromUrs({
                    ursId: entity.id,
                    templateNo: newTemplateNumber,
                    description: newTemplateDocDesignation
                }))

                if (createTemplateFromUrs.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    setShowSaveAsUrsTemplateDialog(false)
                    setSaving(false)
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    const handleConfirmRiskAnlasisClick = async () => {
        try {
            setShowRiskAnalysisConfMessage(false)
            navigate(`/riskAnalysis/edit/${entity.id}`)
        }
        catch (error) {

        }
        setShowRiskAnalysisConfMessage(false)
    }

    const handlePrintUrsClick = async () => {
        const { report } = entity.links
        if (report) {
            const { href } = report
            window.open(`${process.env.REACT_APP_REST_API}${href.startsWith("/") ? href.substring(1) : href}?language=${locale}`, '_blank')
        }
    }


    const handleCancelTeamMemberDeleteClick = useCallback(() => {
        setTeamMemberToEdit(undefined)
        setDialog("none")
    }, [])


    const handleConfirmSendReminderClick = async () => {
        try {
            setSaving(true)
            await dispatch(sendReminder(entity.id))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
        setSaving(false)
    }

    const handleCancelSendReminderClick = useCallback(() => {
        setDialog("none")
    }, [])

    const allTeamMembers = entity.teamMembers
    const teamMembersSelection = useMemo(() => new Set(allTeamMembers.map(u => u.userName)), [allTeamMembers])

    const isAutor = isUrsLinkAvail("save", entity)

    const { report } = entity.links
    const editable = true


    function getLocalISODate(date) {
        const tzOffset = (new Date()).getTimezoneOffset() * 60000; // offset w milisekundach
        const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, -1);
        return localISOTime.split('T')[0];
    }

    const actions = [
        <Button key="back" onClick={handleBackButtonClick} color="secondary" variant="outlined">
            <Localized id="back">Wróć</Localized>
        </Button>,
        <Button key="publish" disabled={initialEntity.status === "F"} color="info" onClick={() => handlePublishClick()} variant="outlined">
            <Localized id="send-to-commenting">
                <span>Send for commenting</span>
            </Localized>
        </Button>,
        <Button disabled={initialEntity.status === "F"} startIcon={<UploadIcon />} key="import" color="success" onClick={() => handleImportNewVersion()} variant="outlined">
            <Localized id="import-new-version">
                <span>Import new version</span>
            </Localized>
        </Button>
    ]

    return <>
        <Box sx={{
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <ImportedSupplierUrsHeader doc={entity}></ImportedSupplierUrsHeader>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TabList onChange={handleMainTabChange} sx={{
                                    marginTop: 1,
                                }}>
                                    <Tab
                                        key={1}
                                        value="reqs"
                                        label={<Localized id="urs-requirements">Lista wymagań</Localized>} />
                                    <Tab
                                        key={2}
                                        value="description"
                                        label={<Localized id="document-details">Szczegóły dokumentu</Localized>} />
                                    <Tab
                                        key={3}
                                        value="attachements"
                                        label={<Localized id="attachements">Załączniki</Localized>}
                                        disabled={template}
                                    />
                                    <Tab
                                        key={4}
                                        value="versions"
                                        label={<Localized id="versions">_Versions</Localized>}
                                        disabled={template}
                                    />
                                </TabList>
                            </Grid>
                            <Grid item xs={4}>
                                <div>

                                    <If condition={!entity.isTemplate} otherwise={
                                        <TextField
                                            fullWidth
                                            multiline={true}
                                            minRows={4}
                                            onChange={handleDocDesignationChange}
                                            value={docDesignation}
                                            label={<Localized id="urs-description"><span>Opis</span></Localized>}>
                                        </TextField>
                                    }>
                                        <Box display="flex" justifyContent="flex-end" flexDirection="column" gap={2} marginTop={2}>
                                            <Stack justifyContent="flex-end" direction="row" spacing={4}>
                                                <Paper
                                                    elevation={0}
                                                    sx={{
                                                        display: 'flex',
                                                        border: (theme) => `1px solid ${theme.palette.divider}`,
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <StyledToggleButtonGroup
                                                        value={'left'}
                                                        exclusive
                                                        aria-label="text alignment"
                                                    >

                                                        <Tooltip title={<Localized id="urs-action-report">Raport</Localized>}>
                                                            <ToggleButton value="report" aria-label="report" disabled={report === undefined} onClick={() => handlePrintUrsClick()}>
                                                                <PrintIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                        <Tooltip title={<Localized id="urs-action-history">Historia</Localized>}>
                                                            <ToggleButton value="history" aria-label="history" onClick={() => navigate(`/urs/diff/${entity.id}`)}>
                                                                <HistoryIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                        <Tooltip sx={{ display: entity.status !== "P" ? 'flex' : 'none' }} onClick={() => handlePermamentDeleteClick()}
                                                            title={<Localized id="delete">Delete</Localized>}>
                                                            <ToggleButton value="delete" aria-label="delete" >
                                                                <DeleteIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                    </StyledToggleButtonGroup>
                                                </Paper>
                                            </Stack>
                                        </Box>
                                    </If>
                                </div>
                            </Grid>
                        </Grid>
                        <TabPanel value="reqs">

                            <div className="flex flex-row gap-4">
                                <div className={clsx(commentsOpen ? 'basis-3/4' : 'basis-full')}>
                                    <ImportedRequirementsPanel template={template} urs={entity} />
                                </div>

                            </div>
                            <Grid container spacing={4}>
                                <Grid item xs={9}>

                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="description" sx={{ padding: 1 }}>
                            <ImportedUrsDetailsPanel
                                entity={entity}
                                editable={editable}
                                isUrsLinkAvail={isUrsLinkAvail}
                                handleEditUrsNumber={handleEditUrsNumber}
                                handleEditSimpleDescriptionFieldClick={handleEditSimpleDescriptionFieldClick}
                                handleEditDescriptionFieldClick={handleEditDescriptionFieldClick} />
                        </TabPanel>

                        <TabPanel value="attachements">
                            <Attachements resourceId={entity.guid} canDelete={true} canUpload={true} />
                        </TabPanel>
                        <TabPanel value="versions">
                            <DocumentVersionsPanel documentId={entity.id} context="supplier" />
                        </TabPanel>
                    </TabContext>

                </Grid>
            </Grid>
            {dialog === 'editUrsNumber' && <EditDocNumberDialog ursId={entity.id} value={entity.ursNo} onSuccess={() => setDialog('none')} onCancel={() => setDialog('none')} />}
            <Dialog key="aditRequirementDialog" open={dialog === "editRequirement"} fullWidth maxWidth="md">
            </Dialog>
            <Dialog key="sendReminderDialog" open={dialog === "sendReminder"} fullWidth maxWidth="xs">
                <DialogContent>
                    <DialogContentText>
                        <Localized id="urs-confirm-send-reminder">
                            <span>Czy wysłać przypomnienie o bieżących zadaniach do członków zespołu?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmSendReminderClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <Button onClick={handleCancelSendReminderClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            {
                permamentDeleteConfirmationVisible &&
                <PermamentDocumentDeleteDialog
                    documentId={entity.id}
                    onSuccess={onSuccessfullyDeleted}
                    onCancel={onDeleteCanceled}
                />
            }
            {typeof dialog === 'object' && dialog.tag === "confirmTeamMemberDelete" && <DeleteTeamMemberDialog
                id={dialog.teamMemberId}
                userFullName={dialog.user}
                onCancel={handleCancelTeamMemberDeleteClick}
                onSuccess={() => setDialog("none")}
            />}
            <Dialog key="riskAnalysisDialog" open={showRiskAnalysisConfMessage}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="urs-open-risk-analysis-question">
                            <span>Czy przejść do analizy ryzyka?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmRiskAnlasisClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleCancelRiskAnlasisClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog key="saveAsTemplateDialog" open={showSaveAsUrsTemplateDialog} fullWidth maxWidth="md" keepMounted={false}>
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                        <Localized id="urs-save-as-template-question">
                            <span>Zapisz jako szablon</span>
                        </Localized>
                    </DialogTitle>
                    <DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    value={newTemplateNumber}
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    onChange={handleNewTemplateNumberChange}
                                    label={
                                        <Localized id="urs-template-no">
                                            <span>Numer szablonu</span>
                                        </Localized>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={newTemplateDocDesignation}
                                    fullWidth
                                    inputProps={{ maxLength: 2000 }}
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                    onChange={handleNewTemplateDocDesignationChange}
                                    label={
                                        <Localized id="urs-template-description">
                                            <span>Opis</span>
                                        </Localized>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmSaveAsTemplate}>
                        <Localized id="save"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelSaveAsTemplate}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog key="changeTMDeadline" open={dialog === "changeTMDeadline"} maxWidth="xs" keepMounted={false}>
                <DialogContent>
                    <DialogContentText>
                        <If condition={deadlineForAll} otherwise={
                            <Typography>
                                <Localized id="urs-new-deadline-single-user">
                                    <span>Wprowadź nowy termin wykonania zadania dla wybranego użytkownika</span>
                                </Localized>
                            </Typography>
                        }>
                            <Localized id="urs-new-deadline-team">
                                <span>Wprowadź nowy termin wykonania zadania dla całego zespołu</span>
                            </Localized>
                        </If>

                    </DialogContentText>
                    <TextField
                        sx={{
                            marginTop: 2
                        }}
                        type="date"
                        fullWidth
                        value={newDeadline}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            min: getLocalISODate(new Date())
                        }}
                        onChange={handleDeadlineFieldChange}
                        label={<Localized id="deadline"><span>Termin</span></Localized>}
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmChangeTMDeadlineClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            {showPublishMessage &&
                <Dialog key="sendforcommenting" open={true}>
                    <DialogContent>
                        <DialogContentText>
                            <Localized id="">
                                <span>Do you want to send URS for commenting?</span>
                            </Localized>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton loading={saving} onClick={handleConfirmPublishClick}>
                            <Localized id="yes"><span>Tak</span></Localized>
                        </LoadingButton>
                        <LoadingButton loading={saving} onClick={handleCancelPublishClick}>
                            <Localized id="no"><span>Nie</span></Localized>
                        </LoadingButton>
                    </DialogActions>
                </Dialog>


            }
            {
                showImportNewVersion &&
                <ImportSupplierUrsDialog onSucces={onImportSuccess} onCancel={handleHideImportDialog} relatedDocument={entity} />

            }

            {false && <PublishDialog ursId={entity.id} confirmPublish={handleConfirmPublishClick} cancelPublish={handleCancelPublishClick} processing={saving} />}
            <Dialog key="substituteTMDialog" open={dialog === "substituteTM"} maxWidth="md" fullWidth keepMounted={false}>
                <TeamMemberDialogForm ursId={entity.id} mode={"substitute"} excludedUsers={teamMembersSelection}
                    defaultUserName={teamMemberToEdit?.userName ?? ""}
                    onCancelClick={handleDialogCancelClick}
                    onOKClick={(username) => handleConfirmSubstituteTMClick(username)}
                />
            </Dialog>
            <Dialog key="inviteTMDialog" open={dialog === "inviteTM"} maxWidth="md" fullWidth keepMounted={false}>
                <AddExternalTeamMember ursId={entity.id} onCancelClick={handleDialogCancelClick} onOKClick={() => { setDialog('none') }} />
            </Dialog>
            <Dialog key="editTeamMemberDialog" open={dialog === "editTeamMember"} maxWidth="md" fullWidth keepMounted={false}>
                <TeamMemberDialogForm
                    ursId={entity.id}
                    mode={teamMemberToEdit ? "edit" : "create"}
                    excludedUsers={teamMembersSelection}
                    defaultUserName={teamMemberToEdit?.userName ?? ""}
                    onCancelClick={handleDialogCancelClick}
                    onOKClick={() => { setDialog("none") }}
                />
            </Dialog>
            {typeof dialog === 'object' && dialog.tag === 'editDescriptionField' && <EditDescriptionField
                ursId={entity.id}
                fieldName={dialog.field}
                value={dialog.value}
                onCancel={() => setDialog("none")}
                onSuccess={() => setDialog("none")}
            />}
            {typeof dialog === 'object' && dialog.tag === 'editSimpleDescriptionField' && <EditSimpleDescriptionField
                ursId={entity.id}
                fieldName={dialog.field}
                value={dialog.value}
                onCancel={() => setDialog("none")}
                onSuccess={() => setDialog("none")}
            />}
            <>{actionToRun && <ActionRunner
                action={actionToRun}
                onError={() => { }}
                onSuccess={updatedURS => {
                    if (updatedURS.id !== entity.id) {
                        // new version - redirection needed
                        setActionToRun(null)
                        navigate(generatePath("/urs/edit/:id", {
                            id: updatedURS.id,
                        }), { replace: true })
                    } else {
                        setActionToRun(null)
                    }
                }}
                onCancel={() => setActionToRun(null)} />}</>
        </Box>
        <BottomAppBar>
            <ButtonGroup variant="contained" color="secondary">{actions}</ButtonGroup>
        </BottomAppBar>
    </>
}

export const FormNoTabs = (props: FormProps) => {
    const { entity: initialEntity, template } = props
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const update: Updater = template ? updateUrsTemplate : updateURS
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const user = useAppSelector(selectLoggedUser)
    const entity = useUrsState(initialEntity)

    const [dialog, setDialog] = useState<DialogState>("none")
    const handleEditDescriptionFieldClick = useCallback((field: 'purpose' | 'description' | 'area', value: string) => {
        setDialog({ tag: 'editDescriptionField', field, value, ursId: entity.id })
    }, [])
    const handleEditSimpleDescriptionFieldClick = useCallback((field: 'purpose' | 'description' | 'area' | 'title', value: string) => {
        setDialog({ tag: 'editSimpleDescriptionField', field, value, ursId: entity.id })
    }, [])

    const [docDesignation, setDocDesignation] = useState(entity.docDesignation)
    const [newTemplateDocDesignation, setNewTemplateDocDesignation] = useState("")
    const [newTemplateNumber, setNewTemplateNumber] = useState("")

    const [saving, setSaving] = useState(false)
    const [teamMemberToEdit, setTeamMemberToEdit] = useState<TeamMember | undefined>(undefined)

    const [actionToRun, setActionToRun] = useState<ActionLink & { name: string, description: string } | null>(null)
    const [selectedOfferId, setSelectedOfferId] = useState<AppId>("")

    const today = getLocalISODate(new Date().setDate(new Date().getDate() + 7));
    const [newDeadline, setNewDeadline] = useState(today)
    const [deadlineForAll, setDeadlineForAll] = useState<boolean>(false)

    const [commentsOpen, setCommentsOpen] = useState(false)
    const [permamentDeleteConfirmationVisible, setPermamentDeleteConfirmationVisible] = useState<boolean>(false);

    const handleNewTemplateNumberChange: AppTextFieldHandler = useCallback(e => {
        setNewTemplateNumber(e.target.value)
    }, [])

    const handleNewTemplateDocDesignationChange: AppTextFieldHandler = useCallback(e => {
        setNewTemplateDocDesignation(e.target.value)
    }, [])

    const handleDeadlineFieldChange: AppTextFieldHandler = useCallback(e => {
        setNewDeadline(e.target.value)
    }, [])

    const handlePermamentDeleteClick = () => {
        setPermamentDeleteConfirmationVisible(true);
    }

    const onSuccessfullyDeleted = async () => {
        setPermamentDeleteConfirmationVisible(false);
        navigate(`/supplier-imported-urs`, { replace: true });
    };


    const onDeleteCanceled = () => {
        setPermamentDeleteConfirmationVisible(false);
    };

    const handleConfirmSubstituteTMClick = useCallback(async (userName: string | undefined) => {
        try {
            setSaving(true)
            await dispatch(substituteUrsTeamMember({
                ursId: entity.id,
                userName: teamMemberToEdit?.userName ?? "",
                substitution: userName ?? ""
            }))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setTeamMemberToEdit(undefined)
        setDialog("none")
        setSaving(false)
    }, [teamMemberToEdit])

    const handleEditUrsNumber = useCallback(() => {
        setDialog("editUrsNumber")
    }, [])


    const handleDocDesignationChange: AppTextFieldHandler = useCallback(e => setDocDesignation(e.target.value), [])

    const handleDialogCancelClick = useCallback(() => {
        setDialog("none")
    }, [])


    const handleSaveButtonClick = async () => {
        setSaving(true)
        const result = await dispatch(update(entity))

        if (update.fulfilled.match(result)) {
            dispatch(showSuccess("saved"))
        } else {
            dispatch(showError("error"))
        }

        setSaving(false)
    }

    const [showSaveAsUrsTemplateDialog, setShowSaveAsUrsTemplateDialog] = useState(false);

    const [showRiskAnalysisConfMessage, setShowRiskAnalysisConfMessage] = useState(false);
    const [showPublishMessage, setShowPublishMessage] = useState(false);
    const [showImportNewVersion, setShowImportNewVersion] = useState(false);
    const loggedUserCompanyId = useAppSelector(selectUserCompanyId) as string

    const handlePublishClick = async () => {
        setShowPublishMessage(true)
    }
    const handleImportNewVersion = async () => {
        setShowImportNewVersion(true)
    }

    const handleCancelPublishClick = useCallback(() => {
        setShowPublishMessage(false)
    }, [setShowPublishMessage])

    const handleHideImportDialog = useCallback(() => {
        setShowImportNewVersion(false)
    }, [setShowImportNewVersion])

    const onImportSuccess = (id) => {
        navigate(`/supplier-imported-urs/edit/${id}`)
    };


    const handleConfirmPublishClick = async () => {
        setSaving(true)
        try {
            const result = await dispatch(publishAction({
                url: 'api/Urs/publish/' + entity.id,
                companiesIds: [loggedUserCompanyId],
                suppliersIds: []
            }));

            if (publishAction.fulfilled.match(result)) {
                dispatch(showSuccess("saved"))

            }
            else {
                let errorMsg = "error"
                if (result.payload) {
                    if (result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                }
                dispatch(showError(errorMsg))
            }
        }
        catch (error) {
            dispatch(showError("error"))
        }
        finally {
            setShowPublishMessage(false)
            setSaving(false)
        }
    }

    const handleConfirmChangeTMDeadlineClick = async () => {
        try {
            setSaving(true)
            await dispatch(changeDeadline({
                ursId: entity.id,
                teamMemberId: teamMemberToEdit?.id ?? "",
                deadline: newDeadline,
                forAllMembers: deadlineForAll
            }))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
        setSaving(false)
    }

    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const [params, setParams] = useSearchParams()
    const tab = params.get('tab') as MainTabs || 'reqs'
    const setTab = (tab: MainTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const handleMainTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [setTab])


    const handleCancelRiskAnlasisClick = useCallback(() => {
        setShowRiskAnalysisConfMessage(false)
    }, [])

    const handleCancelSaveAsTemplate = useCallback(() => {
        setShowSaveAsUrsTemplateDialog(false)
    }, [])


    const handleConfirmSaveAsTemplate = async () => {
        if (newTemplateDocDesignation) {
            setSaving(true)
            try {
                const result = await dispatch(createTemplateFromUrs({
                    ursId: entity.id,
                    templateNo: newTemplateNumber,
                    description: newTemplateDocDesignation
                }))

                if (createTemplateFromUrs.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    setShowSaveAsUrsTemplateDialog(false)
                    setSaving(false)
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    const handleConfirmRiskAnlasisClick = async () => {
        try {
            setShowRiskAnalysisConfMessage(false)
            navigate(`/riskAnalysis/edit/${entity.id}`)
        }
        catch (error) {

        }
        setShowRiskAnalysisConfMessage(false)
    }

    const handlePrintUrsClick = async () => {
        const { report } = entity.links
        if (report) {
            const { href } = report
            window.open(`${process.env.REACT_APP_REST_API}${href.startsWith("/") ? href.substring(1) : href}?language=${locale}`, '_blank')
        }
    }


    const handleCancelTeamMemberDeleteClick = useCallback(() => {
        setTeamMemberToEdit(undefined)
        setDialog("none")
    }, [])


    const handleConfirmSendReminderClick = async () => {
        try {
            setSaving(true)
            await dispatch(sendReminder(entity.id))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
        setSaving(false)
    }

    const handleCancelSendReminderClick = useCallback(() => {
        setDialog("none")
    }, [])

    const allTeamMembers = entity.teamMembers
    const teamMembersSelection = useMemo(() => new Set(allTeamMembers.map(u => u.userName)), [allTeamMembers])

    const isAutor = isUrsLinkAvail("save", entity)

    const { report } = entity.links
    const editable = true


    function getLocalISODate(date) {
        const tzOffset = (new Date()).getTimezoneOffset() * 60000; // offset w milisekundach
        const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, -1);
        return localISOTime.split('T')[0];
    }

    const actions = [
        <Button key="back" onClick={handleBackButtonClick} color="secondary" variant="outlined">
            <Localized id="back">Wróć</Localized>
        </Button>,
        <Button key="publish" disabled={initialEntity.status === "F"} color="info" onClick={() => handlePublishClick()} variant="outlined">
            <Localized id="send-to-commenting">
                <span>Send for commenting</span>
            </Localized>
        </Button>,
        <Button disabled={initialEntity.status === "F"} startIcon={<UploadIcon />} key="import" color="success" onClick={() => handleImportNewVersion()} variant="outlined">
            <Localized id="import-new-version">
                <span>Import new version</span>
            </Localized>
        </Button>
    ]

    return <>
        <Box sx={{
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <ImportedSupplierUrsHeader doc={entity}></ImportedSupplierUrsHeader>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <TabList onChange={handleMainTabChange} sx={{
                                    marginTop: 1,
                                }}>
                                    <Tab
                                        key={1}
                                        value="reqs"
                                        label={<Localized id="urs-requirements">Lista wymagań</Localized>} />
                                    <Tab
                                        key={2}
                                        value="description"
                                        label={<Localized id="document-details">Szczegóły dokumentu</Localized>} />
                                    <Tab
                                        key={3}
                                        value="attachements"
                                        label={<Localized id="attachements">Załączniki</Localized>}
                                        disabled={template}
                                    />
                                    <Tab
                                        key={4}
                                        value="versions"
                                        label={<Localized id="versions">_Versions</Localized>}
                                        disabled={template}
                                    />
                                </TabList>
                            </Grid>
                            <Grid item xs={4}>
                                <div>

                                    <If condition={!entity.isTemplate} otherwise={
                                        <TextField
                                            fullWidth
                                            multiline={true}
                                            minRows={4}
                                            onChange={handleDocDesignationChange}
                                            value={docDesignation}
                                            label={<Localized id="urs-description"><span>Opis</span></Localized>}>
                                        </TextField>
                                    }>
                                        <Box display="flex" justifyContent="flex-end" flexDirection="column" gap={2} marginTop={2}>
                                            <Stack justifyContent="flex-end" direction="row" spacing={4}>
                                                <Paper
                                                    elevation={0}
                                                    sx={{
                                                        display: 'flex',
                                                        border: (theme) => `1px solid ${theme.palette.divider}`,
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <StyledToggleButtonGroup
                                                        value={'left'}
                                                        exclusive
                                                        aria-label="text alignment"
                                                    >

                                                        <Tooltip title={<Localized id="urs-action-report">Raport</Localized>}>
                                                            <ToggleButton value="report" aria-label="report" disabled={report === undefined} onClick={() => handlePrintUrsClick()}>
                                                                <PrintIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                        <Tooltip title={<Localized id="urs-action-history">Historia</Localized>}>
                                                            <ToggleButton value="history" aria-label="history" onClick={() => navigate(`/urs/diff/${entity.id}`)}>
                                                                <HistoryIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                        <Tooltip sx={{ display: entity.status !== "P" ? 'flex' : 'none' }} onClick={() => handlePermamentDeleteClick()}
                                                            title={<Localized id="delete">Delete</Localized>}>
                                                            <ToggleButton value="delete" aria-label="delete" >
                                                                <DeleteIcon />
                                                            </ToggleButton>
                                                        </Tooltip>
                                                    </StyledToggleButtonGroup>
                                                </Paper>
                                            </Stack>
                                        </Box>
                                    </If>
                                </div>
                            </Grid>
                        </Grid>
                        <TabPanel value="reqs">

                            <div className="flex flex-row gap-4">
                                <div className={clsx(commentsOpen ? 'basis-3/4' : 'basis-full')}>
                                    <ImportedRequirementsFlatPanel template={template} urs={entity} />
                                </div>

                            </div>
                            <Grid container spacing={4}>
                                <Grid item xs={9}>

                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="description" sx={{ padding: 1 }}>
                            <ImportedUrsDetailsPanel
                                entity={entity}
                                editable={editable}
                                isUrsLinkAvail={isUrsLinkAvail}
                                handleEditUrsNumber={handleEditUrsNumber}
                                handleEditSimpleDescriptionFieldClick={handleEditSimpleDescriptionFieldClick}
                                handleEditDescriptionFieldClick={handleEditDescriptionFieldClick} />
                        </TabPanel>

                        <TabPanel value="attachements">
                            <Attachements resourceId={entity.guid} canDelete={true} canUpload={true} />
                        </TabPanel>
                        <TabPanel value="versions">
                            <DocumentVersionsPanel documentId={entity.id} context="supplier" />
                        </TabPanel>
                    </TabContext>

                </Grid>
            </Grid>
            {dialog === 'editUrsNumber' && <EditDocNumberDialog ursId={entity.id} value={entity.ursNo} onSuccess={() => setDialog('none')} onCancel={() => setDialog('none')} />}
            <Dialog key="aditRequirementDialog" open={dialog === "editRequirement"} fullWidth maxWidth="md">
            </Dialog>
            <Dialog key="sendReminderDialog" open={dialog === "sendReminder"} fullWidth maxWidth="xs">
                <DialogContent>
                    <DialogContentText>
                        <Localized id="urs-confirm-send-reminder">
                            <span>Czy wysłać przypomnienie o bieżących zadaniach do członków zespołu?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmSendReminderClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <Button onClick={handleCancelSendReminderClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            {
                permamentDeleteConfirmationVisible &&
                <PermamentDocumentDeleteDialog
                    documentId={entity.id}
                    onSuccess={onSuccessfullyDeleted}
                    onCancel={onDeleteCanceled}
                />
            }
            {typeof dialog === 'object' && dialog.tag === "confirmTeamMemberDelete" && <DeleteTeamMemberDialog
                id={dialog.teamMemberId}
                userFullName={dialog.user}
                onCancel={handleCancelTeamMemberDeleteClick}
                onSuccess={() => setDialog("none")}
            />}
            <Dialog key="riskAnalysisDialog" open={showRiskAnalysisConfMessage}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="urs-open-risk-analysis-question">
                            <span>Czy przejść do analizy ryzyka?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmRiskAnlasisClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleCancelRiskAnlasisClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog key="saveAsTemplateDialog" open={showSaveAsUrsTemplateDialog} fullWidth maxWidth="md" keepMounted={false}>
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                        <Localized id="urs-save-as-template-question">
                            <span>Zapisz jako szablon</span>
                        </Localized>
                    </DialogTitle>
                    <DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    value={newTemplateNumber}
                                    fullWidth
                                    inputProps={{ maxLength: 50 }}
                                    onChange={handleNewTemplateNumberChange}
                                    label={
                                        <Localized id="urs-template-no">
                                            <span>Numer szablonu</span>
                                        </Localized>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={newTemplateDocDesignation}
                                    fullWidth
                                    inputProps={{ maxLength: 2000 }}
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                    onChange={handleNewTemplateDocDesignationChange}
                                    label={
                                        <Localized id="urs-template-description">
                                            <span>Opis</span>
                                        </Localized>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmSaveAsTemplate}>
                        <Localized id="save"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelSaveAsTemplate}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog key="changeTMDeadline" open={dialog === "changeTMDeadline"} maxWidth="xs" keepMounted={false}>
                <DialogContent>
                    <DialogContentText>
                        <If condition={deadlineForAll} otherwise={
                            <Typography>
                                <Localized id="urs-new-deadline-single-user">
                                    <span>Wprowadź nowy termin wykonania zadania dla wybranego użytkownika</span>
                                </Localized>
                            </Typography>
                        }>
                            <Localized id="urs-new-deadline-team">
                                <span>Wprowadź nowy termin wykonania zadania dla całego zespołu</span>
                            </Localized>
                        </If>

                    </DialogContentText>
                    <TextField
                        sx={{
                            marginTop: 2
                        }}
                        type="date"
                        fullWidth
                        value={newDeadline}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            min: getLocalISODate(new Date())
                        }}
                        onChange={handleDeadlineFieldChange}
                        label={<Localized id="deadline"><span>Termin</span></Localized>}
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmChangeTMDeadlineClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            {showPublishMessage &&
                <Dialog key="sendforcommenting" open={true}>
                    <DialogContent>
                        <DialogContentText>
                            <Localized id="">
                                <span>Do you want to send URS for commenting?</span>
                            </Localized>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton loading={saving} onClick={handleConfirmPublishClick}>
                            <Localized id="yes"><span>Tak</span></Localized>
                        </LoadingButton>
                        <LoadingButton loading={saving} onClick={handleCancelPublishClick}>
                            <Localized id="no"><span>Nie</span></Localized>
                        </LoadingButton>
                    </DialogActions>
                </Dialog>


            }
            {
                showImportNewVersion &&
                <ImportSupplierUrsDialog onSucces={onImportSuccess} onCancel={handleHideImportDialog} relatedDocument={entity} />

            }

            {false && <PublishDialog ursId={entity.id} confirmPublish={handleConfirmPublishClick} cancelPublish={handleCancelPublishClick} processing={saving} />}
            <Dialog key="substituteTMDialog" open={dialog === "substituteTM"} maxWidth="md" fullWidth keepMounted={false}>
                <TeamMemberDialogForm ursId={entity.id} mode={"substitute"} excludedUsers={teamMembersSelection}
                    defaultUserName={teamMemberToEdit?.userName ?? ""}
                    onCancelClick={handleDialogCancelClick}
                    onOKClick={(username) => handleConfirmSubstituteTMClick(username)}
                />
            </Dialog>
            <Dialog key="inviteTMDialog" open={dialog === "inviteTM"} maxWidth="md" fullWidth keepMounted={false}>
                <AddExternalTeamMember ursId={entity.id} onCancelClick={handleDialogCancelClick} onOKClick={() => { setDialog('none') }} />
            </Dialog>
            <Dialog key="editTeamMemberDialog" open={dialog === "editTeamMember"} maxWidth="md" fullWidth keepMounted={false}>
                <TeamMemberDialogForm
                    ursId={entity.id}
                    mode={teamMemberToEdit ? "edit" : "create"}
                    excludedUsers={teamMembersSelection}
                    defaultUserName={teamMemberToEdit?.userName ?? ""}
                    onCancelClick={handleDialogCancelClick}
                    onOKClick={() => { setDialog("none") }}
                />
            </Dialog>
            {typeof dialog === 'object' && dialog.tag === 'editDescriptionField' && <EditDescriptionField
                ursId={entity.id}
                fieldName={dialog.field}
                value={dialog.value}
                onCancel={() => setDialog("none")}
                onSuccess={() => setDialog("none")}
            />}
            {typeof dialog === 'object' && dialog.tag === 'editSimpleDescriptionField' && <EditSimpleDescriptionField
                ursId={entity.id}
                fieldName={dialog.field}
                value={dialog.value}
                onCancel={() => setDialog("none")}
                onSuccess={() => setDialog("none")}
            />}
            <>{actionToRun && <ActionRunner
                action={actionToRun}
                onError={() => { }}
                onSuccess={updatedURS => {
                    if (updatedURS.id !== entity.id) {
                        // new version - redirection needed
                        setActionToRun(null)
                        navigate(generatePath("/urs/edit/:id", {
                            id: updatedURS.id,
                        }), { replace: true })
                    } else {
                        setActionToRun(null)
                    }
                }}
                onCancel={() => setActionToRun(null)} />}</>
        </Box>
        <BottomAppBar>
            <ButtonGroup variant="contained" color="secondary">{actions}</ButtonGroup>
        </BottomAppBar>
    </>
}

interface TeamMemberRowProps {
    entity: TeamMember
    author: string
    actions: Partial<Record<UrsActionType, ActionLink>>
    onChangeDeadlineClick: (entity: TeamMember) => void
    onSubstituteTMClick: (entity: TeamMember) => void
    onDeleteClick: (entity: TeamMember, user: string) => void
    onDeleteMemberClick: (entity: TeamMember, user: string) => void
    editable: boolean
}

