import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppId, AppThunkAPIType } from "../../../app/appTypes"
import { getWithAuth, postWithAuth, putWithAuth } from "../../../http"

export interface MailingGroup {
    id: AppId
    name: string
    email: string
    users: MailingGroupUser[]
}

export interface MailingGroupUser {
    id: AppId
    firstName: string
    lastName: string
    email: string
}

export const loadMailingGroups = createAsyncThunk<MailingGroup[], void, AppThunkAPIType>("maling-groups/load-list",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/maling-groups` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as MailingGroup[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const addMailingGroup = createAsyncThunk<MailingGroup, MailingGroup, AppThunkAPIType>
("maling-groups/add", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/maling-groups`,
        payload: data,
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return payload as MailingGroup
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateMailingGroup = createAsyncThunk<MailingGroup, MailingGroup, AppThunkAPIType>
("maling-groups/update", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(putWithAuth({
        url: `api/maling-groups`,
        payload: data,
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        return payload as MailingGroup
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteMailingGroup = createAsyncThunk<string, { mailingGroupId: string; password: string }, AppThunkAPIType>("maling-groups/load-list",
    async ({ mailingGroupId, password }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ url: `api/maling-groups/delete`, payload: { mailingGroupId, password } }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as string
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)
