import { Localized } from "@fluent/react"
import { useAppDispatch } from "../../../app/hooks"
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { AppId, AppTextFieldHandler } from "../../../app/appTypes"
import { SaveButton } from "../../../app/components/SaveButton"
import { CancelButton } from "../../../app/components/CancelButton"
import { SystemDocumentTemplateHeader } from "../api/systemTypes"
import { StructureTreeSelect } from "../../structure/StructureTreeSelect"
import { DocumentLanguages, DocumentTypes } from "../../documents/documentTypes"
import { DocumentTypeSelect } from "../../documents/controls/DocumentTypeSelect"
import { If } from "../../../app/If"
import { DocumentLanguageSelect } from "../../documents/controls/DocumentLanguageSelect"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { createSystemDocumentTemplate } from "../api/systemDocumentTemplatesApi"
import { RequiredError } from "../../../app/components/RequiredError"

export interface CreateSystemDocumentTemplateDialogProps {
    onSucces: (template: SystemDocumentTemplateHeader) => void
    onCancel: () => void
}

export const CreateSystemDocumentTemplateDialog = (props: CreateSystemDocumentTemplateDialogProps) => {
    const dispatch = useAppDispatch()

    const [documentNumber, setDocumentNumber] = useState("")
    const [title, setTitle] = useState("")
    const [titleEN, setTitleEN] = useState("")
    const [docDesignation, setDocDesignation] = useState("")
    const [language, setLanguage] = useState<DocumentLanguages>("MULTI")
    const [docType, setDocType] = useState<DocumentTypes>()
    const [structureId, setStructureId] = useState("")
    const [saving, setSaving] = useState(false)
    const [serverError, setServerError] = useState("")

    const handleStructureSelected = useCallback((structureId: AppId) => {
        if (structureId) {
            setStructureId(structureId);
        }
    }, []);


    const handleTitleChange: AppTextFieldHandler = useCallback(e => {
        setTitle(e.target.value)
    }, [])

    const handleDocDesignationChange: AppTextFieldHandler = useCallback(e => {
        setDocDesignation(e.target.value)
    }, [])

    const handleTitleENChange: AppTextFieldHandler = useCallback(e => {
        setTitleEN(e.target.value)
    }, [])

    const handleDocumentNumberChange: AppTextFieldHandler = useCallback(e => {
        setDocumentNumber(e.target.value)
    }, [])


    const handleLanguageChange = useCallback((language: DocumentLanguages | "") => {
        if (language) {
            setLanguage(language)
        }
    }, []);

    const handleDocTypeChange = useCallback((docType: DocumentTypes) => {
        if (docType) {
            setDocType(docType)
        }
    }, []);

    const handleSaveClick = async () => {
        
        if (documentNumber && title && structureId && docType) {
            setSaving(true)
            try {
                const result =
                    await dispatch(createSystemDocumentTemplate({
                        title: title,
                        title_en: titleEN,
                        docDesignation: docDesignation,
                        documentNumber: documentNumber,
                        structureId: structureId,
                        type: docType,
                        language: language
                    }))

                if (createSystemDocumentTemplate.fulfilled.match(result)) {
                    const template = result.payload as SystemDocumentTemplateHeader;
                    dispatch(showSuccess("saved"))
                    props.onSucces(template)
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    setServerError(errorMsg)
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    return (
        <Dialog open={true} fullWidth maxWidth='md'>
            <DialogTitle><Localized id='document-template-create'>_New template</Localized></DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12}>
                        <StructureTreeSelect defaultStructureId={structureId} onStructureSelected={handleStructureSelected} />
                    </Grid>
                    <Grid item xs={4}>
                        <DocumentTypeSelect required={true} onChange={handleDocTypeChange} />
                    </Grid>
                    <Grid item xs={4}>
                        <DocumentLanguageSelect defaultValue="MULTI" onChange={handleLanguageChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            helperText={documentNumber === '' && <RequiredError />}
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={documentNumber}
                            error={documentNumber === ''}
                            inputProps={{ maxLength: 50 }}
                            onChange={handleDocumentNumberChange}
                            label={<Localized id=""><span>Numer dokumentu</span></Localized>}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <TextField
                            helperText={title === '' && <RequiredError />}
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={title}
                            error={title === ''}
                            onChange={handleTitleChange}
                            label={<Localized id="document-title"><span>_Tytuł</span></Localized>}
                        />
                    </Grid>
                    <If condition={language == "MULTI"}>
                        <Grid item xs={12}>
                            <TextField
                                helperText={titleEN === '' && <RequiredError />}
                                required
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={titleEN}
                                error={titleEN === ''}
                                onChange={handleTitleENChange}
                                label={<Localized id="document-title-en"><span>_Tytul en</span></Localized>}
                            />
                        </Grid>
                    </If>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={docDesignation}
                            onChange={handleDocDesignationChange}
                            label={<Localized id="document-designation"><span>_document-designation</span></Localized>}
                        />
                    </Grid>
                </Grid>
                {
                    serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                        <Localized id={serverError}>
                            <span>Server error</span>
                        </Localized>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <SaveButton loading={saving} onClick={handleSaveClick}></SaveButton>
                <CancelButton loading={saving} onClick={props.onCancel}></CancelButton>
            </DialogActions>
        </Dialog>
    );
}
