import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'
import { Localized } from '@fluent/react'
import { useAppSelector } from '../../app/hooks';
import { MyTask, selectAllMyTasks } from './myTasksSlice';
import { TaskTypeLabel } from './TaskTypeLabel';
import { LoadMyTasks } from '../../app/AppDataLoader';
import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { selectModule } from '../modules/moduleSlice';
import { Link, useNavigate } from 'react-router-dom';
import { LoadingContainer } from '../../app/LoadingContainer';


export const  MyTasks = () => {
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const allMyTasks = useAppSelector(selectAllMyTasks)  
  const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const navigate = useNavigate()

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const GoToCell = ({ row }: { row: MyTask }) => {
    let link = "";
    if (row.testId) {     
      link = `${row.stage}/test/result/${row.processId}/${row.testId}`;      
    }
    else if (row.processId) {
      link = `/process/stage/${row.stage}/${row.processId}`;
    } else if (row.ursId) {
      link = `/urs/edit/${row.ursId}`
    }
    return <>
        <Button component={Link} to={link} color="secondary" variant="outlined" >
              <Localized id="tasks-go-to">
                  <span>_Przejdz</span> 
              </Localized>
        </Button>
    </>        
  }

    useEffect(() => {
        if (currentModule === null) {
            navigate('/modules')
        }
    }, [currentModule])

  return <LoadMyTasks component={<LoadingContainer/>}>
    <TableContainer component={Paper}> 
      <Table sx={{minWidth: 750}}>
        <TableHead >
          <TableRow>
              <TableCell >                              
                <Localized id='tasks-task-type'>_Typ zadania</Localized>
              </TableCell> 
              <TableCell >                              
                <Localized id='tasks-task-description'>_Opis</Localized>
              </TableCell>                      
              <TableCell sx={{width: "160px"}}> 
              </TableCell>                                                                              
          </TableRow>
        </TableHead>
        <TableBody>
          {allMyTasks
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {                                    
              return (
                <TableRow
                  hover                      
                  tabIndex={-1}
                  key={row.id}                      
                > 
                  <TableCell><TaskTypeLabel type={row.taskType}/></TableCell>                       
                  <TableCell>{row.taskDescription}</TableCell>                                                           
                  <TableCell>
                    <GoToCell row = {row}/>
                  </TableCell>                      
                </TableRow>
              );
            })}
          
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={allMyTasks.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </LoadMyTasks>
}
