import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface AppDoc {
    blocks: AppBlock[]
    description: string,
    structureId: string
    title: string
    ursNumber: string,
    relatedCompanyName: string
    relatedDocumentId: string
}

export type AppRowType = 'th' | 'tr'

export interface AppRow {
    type: AppRowType
    cols: string[]
}

export type AppBlock
    = { type: 'h'
    , content: string
    }
    | { type: 'p'
        content: string
    }
    | { type: 'table'
    , colsCount: number
    , rows: AppRow[]
    }


export interface ImportedUrsState {
    data: AppDoc | null
}
const initialState: ImportedUrsState = { data: null }

export const importedUrsSlice = createSlice({
    name: 'importedUrs',
    initialState,
    reducers: {
        setImportedDoc: (state, action: PayloadAction<AppDoc>) => {
            state.data = action.payload
        },
    },
})

export const { setImportedDoc } = importedUrsSlice.actions

export const selectImportedUrs = (state: RootState) => state.importedUrs

export default importedUrsSlice.reducer
