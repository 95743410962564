import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

interface SaveButtonProps {
    loading: boolean;
    disabled?: boolean;
    onClick: () => void;
}

export const SaveButton: React.FC<SaveButtonProps> = ({ loading, disabled = false, onClick }) => {
    return (
        <LoadingButton
            loading={loading}
            disabled={disabled}
            size="small"
            color="success"
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={onClick}
        >
            <Localized id="save">Zapisz</Localized>
        </LoadingButton>
    );
};
