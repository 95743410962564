import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Grid, CircularProgress, Alert } from '@mui/material';
import { Localized } from '@fluent/react';
import { loadSpecsForStructure, Spec } from '../../specs/specSlice';
import { loadSupplierDevicesForStructure, SupplierDevice } from '../../supplierdevices/supplierDeviceSlice';
import { MatchingTargetData, UrsOffer } from '../ursOfferSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../app/hooks';
import { CancelButton } from '../../../app/components/CancelButton';
import { SaveButton } from '../../../app/components/SaveButton';

export interface MatchingTargetDialogProps {
    draftEntity: UrsOffer;
    saving: boolean;
    onConfirm: (data: MatchingTargetData) => void;
    onClose: () => void;
}

const MatchingTargetDialog: React.FC<MatchingTargetDialogProps> = ({ draftEntity, saving, onClose, onConfirm }) => {
    const dispatch = useAppDispatch();

    const [selectedType, setSelectedType] = useState<'offer' | 'fds' | 'fs&ds' | ''>(
        draftEntity.deviceSpecificationId ? 'fds' :
            draftEntity.supplierDeviceId ? 'offer' :
                draftEntity.fsSpecificationId && draftEntity.dsSpecificationId ? 'fs&ds' : ''
    );

    const [allSpecifications, setAllSpecifications] = useState<Spec[]>([]);
    const [allOfferTemplates, setAllOfferTemplates] = useState<SupplierDevice[]>([]);

    const [selectedOffer, setSelectedOffer] = useState(draftEntity.supplierDeviceId ?? "");
    const [selectedFds, setSelectedFds] = useState(draftEntity.deviceSpecificationId ?? "");
    const [selectedFs, setSelectedFs] = useState(draftEntity.fsSpecificationId ?? "");
    const [selectedDs, setSelectedDs] = useState(draftEntity.dsSpecificationId ?? "");

    const allFdsSpecs = allSpecifications.filter(spec => spec.type === "FDS");
    const allFsSpecs = allSpecifications.filter(spec => spec.type === "FS");
    const allDsSpecs = allSpecifications.filter(spec => spec.type === "DS");

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const isConfirmDisabled =
        !selectedType ||
        (selectedType === 'offer' && !selectedOffer) ||
        (selectedType === 'fds' && !selectedFds) ||
        (selectedType === 'fs&ds' && (!selectedFs || !selectedDs));

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setIsError(false);
            setError(null);

            try {
                if (draftEntity.deviceTypeId) {
                    const specs = await dispatch(loadSpecsForStructure(draftEntity.deviceTypeId)).then(unwrapResult);
                    setAllSpecifications(specs);

                    const templates = await dispatch(loadSupplierDevicesForStructure(draftEntity.deviceTypeId)).then(unwrapResult);
                    setAllOfferTemplates(templates);
                }

            } catch (err) {
                setIsError(true);
                setError(err instanceof Error ? err.message : 'An unknown error occurred');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, draftEntity.deviceTypeId]);

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
        setSelectedOffer("");
        setSelectedFds("");
        setSelectedFs("");
        setSelectedDs("");
    };

    const handleConfirm = () => {
        const data: MatchingTargetData = {
            type: selectedType,
            offerId: selectedOffer || null,
            fdsId: selectedFds || null,
            fsId: selectedFs || null,
            dsId: selectedDs || null,
        };
        onConfirm(data);
    };


    return (
        <Dialog fullWidth maxWidth="md" open={true}>
            <DialogTitle>
                <Localized id="urs-offer-supplier-choose-device">Select device</Localized>
            </DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <>
                        {isError && <Alert severity="error">{error}</Alert>}

                        <FormControl required fullWidth sx={{ marginTop: 2 }}>
                            <InputLabel shrink id="select-type"><Localized id='select-type'>Select type</Localized></InputLabel>
                            <Select
                                value={selectedType}
                                onChange={handleTypeChange}
                                sx={{ marginTop: "10px" }}
                            >
                                <MenuItem value="offer"><Localized id="offer">Offer</Localized></MenuItem>
                                <MenuItem value="fds"><Localized id="fds">FDS Specification</Localized></MenuItem>
                                <MenuItem value="fs&ds"><Localized id="fs-ds">FS & DS Specification</Localized></MenuItem>
                            </Select>
                        </FormControl>

                        {selectedType === 'offer' && (
                            <FormControl required fullWidth sx={{ marginTop: 2 }}>
                                <InputLabel shrink id="offer"><Localized id='offer'>Offer</Localized></InputLabel>
                                <Select
                                    required={true}
                                    displayEmpty
                                    value={selectedOffer}
                                    onChange={(e) => setSelectedOffer(e.target.value)}
                                    sx={{ marginTop: "10px" }}
                                >
                                    {allOfferTemplates.map(({ id, name }) => (
                                        <MenuItem key={id} value={id.toString()}>{name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {selectedType === 'fds' && (
                            <FormControl required fullWidth sx={{ marginTop: 2 }}>
                                <InputLabel shrink id="fds"><Localized id='fds'>FDS Specification</Localized></InputLabel>
                                <Select
                                    required={true}
                                    displayEmpty
                                    value={selectedFds}
                                    onChange={(e) => setSelectedFds(e.target.value)}
                                    sx={{ marginTop: "10px" }}
                                >
                                    {allFdsSpecs.map(({ id, name }) => (
                                        <MenuItem key={id} value={id.toString()}>{name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {selectedType === 'fs&ds' && (
                            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                                <Grid item xs={6}>
                                    <FormControl required fullWidth>
                                        <InputLabel shrink id="fs"><Localized id='fs'>FS Specification</Localized></InputLabel>
                                        <Select
                                            required={true}
                                            displayEmpty
                                            value={selectedFs}
                                            onChange={(e) => setSelectedFs(e.target.value)}
                                            sx={{ marginTop: "10px" }}
                                        >
                                            {allFsSpecs.map(({ id, name }) => (
                                                <MenuItem key={id} value={id.toString()}>{name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl required fullWidth>
                                        <InputLabel shrink id="ds"><Localized id='ds'>DS Specification</Localized></InputLabel>
                                        <Select
                                            required={true}
                                            displayEmpty
                                            value={selectedDs}
                                            onChange={(e) => setSelectedDs(e.target.value)}
                                            sx={{ marginTop: "10px" }}
                                        >
                                            {allDsSpecs.map(({ id, name }) => (
                                                <MenuItem key={id} value={id.toString()}>{name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <SaveButton loading={saving} onClick={handleConfirm} disabled={isConfirmDisabled} />
                <CancelButton loading={saving} onClick={onClose}></CancelButton>
            </DialogActions>
        </Dialog>
    );
};

export default MatchingTargetDialog;
