import { forwardRef } from "react"
import { Link, LinkProps } from "react-router-dom"

export interface AppLinkProps {
    to: {
        pathname: string,
        search?: string | undefined
        hash?: string | undefined
        state?: object
    },
}

export const AppLink = forwardRef<any, Omit<LinkProps, "to"> & AppLinkProps>(
    (props, ref) => {
        const { to, ...rest } = props
        const { pathname, search: querySearch, hash, state } = to

        return <Link ref={ref} state={state} {...rest} to={{
            pathname,
            search: querySearch,
            hash,
        }} />
    }
)
