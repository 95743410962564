import { Localized } from "@fluent/react"
import { Box, Button, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { AppId } from "../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import AddIcon from '@mui/icons-material/Add'
import { URSRequirement, Urs, allURSRequirements, createURSRequirement, UrsRequirementType, UrsGroup, addUrsRequirementsFromCatalog } from "./UrsSlice"
import { selectAllURSComments, selectURSComments, URSComment } from "./URSCommentSlice"
import { store } from "../../app/store"
import { DeleteRequirementDialog } from "./dialogs/DeleteRequirementDialog"
import { EditRequirementDialog } from "./dialogs/EditRequirementDialog"
import { AttachementsDialog } from "./dialogs/AttachementsDialog"
import { CommentsDialog } from "./Comments"
import { RequirementDialogBrowser } from "../requirements/RequirementDialogBrowser"
import { selectRequirementById } from "../requirements/RequirementsDictSlice"
import { CreateMainGroupDialog } from "./dialogs/CreateMainGroupDialog"
import { CreateSubGroupDialog } from "./dialogs/CreateSubGroupDialog"
import { EditMainGroupDialog } from "./dialogs/EditMainGroupDialog"
import { DeleteGroupDialog } from "./dialogs/DeleteGroupDialog"
import { EditGroupDialog } from "./dialogs/EditGroupDialog"
import { selectLoggedUser } from "../user/userSlice"
import { AddRequirementDialog } from "./dialogs/AddRequirementDialog"
import { AddChildRequirementDialog } from "./dialogs/AddChildRequirmentDialog"
import { AppBlock, AppRow } from "../ursOffer/importedUrsSlice"
import { BlockMeta } from "./ImportedSupplierUrsPreview"

export interface ImportedRequirementsPanelProps {
    urs: Urs
    template: boolean
}
export const NoRequirements = () => {
    return <Typography gutterBottom component="div" color="textSecondary" variant="h5">
        <Localized id="urs-no-requirements">Brak wymagań.</Localized>
    </Typography>
}
type DialogState =
    | { tag: 'none' }
    | { tag: 'add', groupId: AppId }
    | { tag: 'add-from-catalog'
      , groupId: AppId
      , type: UrsRequirementType
      , selection: Set<AppId>
      }
    | { tag: 'add-child-requirment', parentRequirement: URSRequirement}
    | { tag: 'edit', id: AppId, group: UrsGroup | null, requirement: URSRequirement, rt: UrsRequirementType }
    | { tag: 'copy', srcId: AppId, group: string, typeId: AppId }
    | { tag: 'comments', requirement: URSRequirement }
    | { tag: 'attachements', requirement: URSRequirement }
    | { tag: 'delete', entity: URSRequirement }
    | { tag: 'add-main-group', id: AppId }
    | { tag: 'add-sub-group', id: AppId, groupId: string, groupName: string }
    | { tag: 'edit-main-group', groupId: AppId, groupCode: string, groupName: string }
    | { tag: 'delete-main-group', groupId: AppId }
    | { tag: 'edit-sub-group', groupId: AppId, groupCode: string, groupName: string }
    | { tag: 'delete-sub-group', groupId: AppId }

export const ImportedRequirementsFlatPanel = ({ urs, template }: ImportedRequirementsPanelProps) => {
    const { id: ursId } = urs
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectLoggedUser)
    const canUpload = urs.links.save !== undefined
    const { deviceTypeId } = urs
    const [contextTypeId, setContextTypeId] = useState<AppId | null>(() => {
        if (urs.types.length > 0) {
            const [someItem] = urs.types
            return someItem.id
        } else {
            return null
        }
    })
    const [dialog, setDialog] = useState<DialogState>({ tag: 'none' })
    const comments = useAppSelector(state => {
        if (selectURSComments(state).ursId === ursId) {
            return selectAllURSComments(state)
        } else {
            return []
        }
    })

    // CALLBACKs
    const handleHideDialog = useCallback(() => setDialog({ tag: 'none' }), [])
    const handleRequirementCreation = useCallback((urs: Urs) => {
        if (contextTypeId === null) {
            setContextTypeId(urs.types[0]?.id)
        }
        setDialog({ tag: 'none' })
    }, [contextTypeId])
    const handleOKReqBrowserButtonClick = async (selection: Set<AppId>, sectionGuid: string) => {
        dispatch(addUrsRequirementsFromCatalog({
            ursId, 
            sectionGuid, 
            catalogIds: selection, }))
        if (selection.size > 0 && contextTypeId === null) {
            const someItem = selection.values().next()
            const firstRequirementId = someItem.value
            const someEntity = selectRequirementById(store.getState(), firstRequirementId)
            if (someEntity) {
                setContextTypeId(someEntity.typeId)
            }
        }
        handleHideDialog()
    }
    const userCanSendComment = useCallback(() => {
        return false
    }, [urs])
    const userCanApproveComment = useCallback(() => {
        return false
    }, [urs])
    const userCanEditComment = useCallback((comment: URSComment) => {
        return false
    }, [urs, user])

    // CALLBACKs
    
    const contextType = urs.types.find(x => x.id === contextTypeId)

    const handleMainGroupAdded : (data: Urs) => void = useCallback(data => {
        if (contextTypeId === null) {
            setContextTypeId(data.types[0]?.id)
        }
        setDialog({ tag: 'none' })
    }, [contextTypeId])

    function handleDeleteGroup(data: Urs): void {
        const currentType = data.types.find(x => x.id === contextTypeId)
        if (!currentType) {
            if (data.types.length > 0) {
                setContextTypeId(data.types[0].id)
            } else {
                setContextTypeId(null)
            }
        }
        setDialog({ tag: 'none' })
    }

    return <Box sx={{
        display: 'flex',
        height: 'calc(100vh - 340px)',
    }}>
        <Stack sx={{
            paddingLeft: 4,
            width: '100%',
        }} direction="column" spacing={2}>
            {contextType === undefined ? <>
                <Stack direction="row" spacing={4}>
                    <NoRequirements />
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => setDialog({ tag: 'add-main-group', id: ursId })}
                    >
                        <Localized id='urs-add-main-group'>Dodaj grupę główną</Localized>
                    </Button>
                </Stack>
            </> :
                <NonEmptyRequirementsPanel  template={template} urs={urs} setDialog={setDialog} />}
        </Stack>
        {dialog.tag === 'add' && <AddRequirementDialog 
                                        sectionId={dialog.groupId} 
                                        onCancel={handleHideDialog} 
                                        onSuccess={handleRequirementCreation} 
                                        />}
        {dialog.tag === 'add-child-requirment' && <AddChildRequirementDialog 
            parentRequirement={dialog.parentRequirement} 
            onCancel={handleHideDialog} 
            onSuccess={handleRequirementCreation} 
        />}
        {dialog.tag === 'edit' && <EditRequirementDialog 
                                        comments={comments}
                                        requirementGroup={dialog.group} 
                                        requirement={dialog.requirement}
                                        rootSection={dialog.rt}
                                        onSuccess={handleHideDialog}
                                        onCancel={handleHideDialog}
                                        canSendComments={userCanSendComment}
                                        canResolveComments={userCanApproveComment}
                                        canEditComment={userCanEditComment}
                                        isOfferReady={urs.supplierId !== null || urs.internalSupplierId !== null}
                                    />}
        {dialog.tag === 'delete' && <DeleteRequirementDialog typeId={dialog.entity.typeId ?? ''} group={dialog.entity.group ?? ''} entity={dialog.entity} onSuccess={handleHideDialog} onCancel={handleHideDialog} />}
        {dialog.tag === 'attachements' && <AttachementsDialog
            typeId={dialog.requirement.typeId ?? ''}
            group={dialog.requirement.group ?? ''}
            onClose={handleHideDialog}
            requirementId={dialog.requirement.id}
            requirement={dialog.requirement}
            canUpload={canUpload}
            canDelete={canUpload}
        />}
        {dialog.tag === 'comments' && <CommentsDialog
            comments={comments}
            ursId={ursId}
            requirement={dialog.requirement}
            onClose={handleHideDialog}
            isCommentEnabled={userCanSendComment}
            isResolvingEnabled={userCanApproveComment}
            isEditEnabled={userCanEditComment}
        />}
        {dialog.tag === 'add-from-catalog' && <RequirementDialogBrowser
            cancel={handleHideDialog}
            performAction={(selection) => handleOKReqBrowserButtonClick(selection, dialog.groupId)}
            deviceTypeId={deviceTypeId}
            disabled={dialog.selection}
            lang={urs.language ?? 'PL'}
            defaultTypeId={dialog.type && dialog.type.sysSectionId ? dialog.type.sysSectionId.toString() : undefined}
        />}
        {dialog.tag === 'add-main-group' && <CreateMainGroupDialog ursId={dialog.id} onCancel={handleHideDialog} onSucces={handleMainGroupAdded} />}
        {dialog.tag === 'add-sub-group' && <CreateSubGroupDialog ursId={dialog.id} parentGroupId={dialog.groupId} parentGroupName={dialog.groupName} onCancel={handleHideDialog} onSucces={handleHideDialog} />}
        {dialog.tag === 'edit-main-group' && <EditMainGroupDialog groupId={dialog.groupId} name={dialog.groupName} code={dialog.groupCode} onCancel={handleHideDialog} onSucces={handleMainGroupAdded} />}
        {dialog.tag === 'edit-sub-group' && <EditGroupDialog groupId={dialog.groupId} name={dialog.groupName} code={dialog.groupCode} onCancel={handleHideDialog} onSucces={handleHideDialog} />}
        {dialog.tag === 'delete-main-group' && <DeleteGroupDialog id={dialog.groupId} onCancel={handleHideDialog} onSuccess={handleDeleteGroup} />}
    </Box>
}

interface NonEmptyRequirementsPanelProps extends ImportedRequirementsPanelProps {
    setDialog: (dialog: DialogState) => void
}
const NonEmptyRequirementsPanel = ({ urs, setDialog, template }: NonEmptyRequirementsPanelProps) => {
    const { id: ursId } = urs

    const dispatch = useAppDispatch()

    // CALLBACKs
    const handleAddChildRequirmentClick = useCallback(( parentRequirement: URSRequirement) =>
        setDialog({ tag: 'add-child-requirment', parentRequirement }), [])

    const handleEditRequirementClick = useCallback((id: AppId, group: UrsGroup | null, requirement: URSRequirement, rt: UrsRequirementType) =>
        setDialog({ tag: 'edit', id, group, requirement, rt }), [])
    const handleCopyRequirementClick = useCallback(async (id: AppId, group: UrsGroup | null, requirement: URSRequirement, rt: UrsRequirementType) => {
        let src: URSRequirement | undefined
        for (const req of allURSRequirements(urs)) {
            if (req.id === id) {
            src = req
            break
            }
        }
        if (src) {
            const newUrs = await dispatch(createURSRequirement({ ...src, id: '' })).unwrap()
            setDialog({ tag: 'edit', id: newUrs.id, group, requirement, rt })
        }
    }, [urs])
    const handleDeleteRequirementClick = useCallback((entity: URSRequirement) =>
        setDialog({ tag: 'delete', entity }), [])
    const handleCommentsClick = useCallback((requirement: URSRequirement) =>
        setDialog({ tag: 'comments', requirement }), [])
    const handleAttachementsClick = useCallback((requirement: URSRequirement) =>
        setDialog({ tag: 'attachements', requirement }), [])
    const handleAddReqFromBrowserButtonClick = useCallback((groupId: AppId, rt: UrsRequirementType) => {
        const allCopied: Set<AppId> = new Set()
        for (const r of allURSRequirements(urs)) {
            if (r.requirementId !== null) {
                allCopied.add(r.requirementId.toString())
            }
        }
        setDialog({ tag: 'add-from-catalog', groupId, type: rt, selection: allCopied })
    }, [urs])
    // CALLBACKs

    const ursRequirements = urs.types[0].requirements
    let blocks: AppBlock[] = []
    let metas: BlockMeta[] = []
    for (let i = 0; i < ursRequirements.length; i++) {
        const { jsonContent } = ursRequirements[i];
        if (jsonContent) {
            const meta: BlockMeta = JSON.parse(jsonContent) as BlockMeta
            switch (meta.type) {
                case 'h':
                    blocks.push({
                        type: 'h',
                        content: meta.content,
                    })
                    break
                case 'p':
                    blocks.push({
                        type: 'p',
                        content: meta.content,
                    })
                    break
                case 'th':
                case 'tr':
                    if (i > 0) {
                        const prev = metas[i-1]
                        if ((prev.type === 'th' || prev.type === 'tr') && prev.tableId === meta.tableId) {
                            const tab = blocks[blocks.length-1]
                            if (tab.type === 'table') {
                                tab.rows.push({
                                    type: meta.type,
                                    cols: meta.cols,
                                })
                            } else {
                                console.log("[BUG] expected 'table'")
                            }
                        } else {
                            blocks.push({
                                type: 'table',
                                colsCount: meta.cols.length,
                                rows: [{
                                    type: meta.type,
                                    cols: meta.cols,
                                }]
                            })
                        }
                    } else {
                        blocks.push({
                            type: 'table',
                            colsCount: meta.cols.length,
                            rows: [{
                                type: meta.type,
                                cols: meta.cols,
                            }]
                        })
                    }
                    break
            }
            metas.push(meta)
        }
    }

    return <Container component={Paper} sx={{
        height: 'calc(100vh - 300px)',
        overflow: 'auto',
    }} maxWidth='lg'>
        {blocks.map((b, i) => <RenderBlock block={b} />)}
    </Container>
}

const RenderBlock = (props: { block : AppBlock }) => {
    const { block } = props
    switch (block.type) {
        case 'h':
            return <Typography variant="h6">
                <div dangerouslySetInnerHTML={{ __html: block.content }}></div></Typography>
        case 'p':
        return <Typography variant="body1">
                <div dangerouslySetInnerHTML={{ __html: block.content }}></div>
            </Typography>
        case 'table':
            return <AppTable colsCount={block.colsCount} rows={block.rows} />
    }
}

function AppTable(props: { colsCount: number, rows: AppRow[] }) {
    const { colsCount, rows } = props
    const firstTrIdx = rows.findIndex(r => r.type === 'tr')
    const ths = rows.slice(0, firstTrIdx)
    const trs = rows.slice(firstTrIdx)
    return <TableContainer sx={{
        my: 1,
    }} component={Paper}>
        <Table>
            {ths.length > 0 && <TableHead>
                {ths.map(row => <TableRow>
                    {row.cols.map(col => <TableCell sx={{ minWidth: '150px' }}><span dangerouslySetInnerHTML={{ __html: col}}></span></TableCell>)}
                </TableRow>)}
            </TableHead>}
            <TableBody>
                {trs.map(row => <TableRow>
                    {row.cols.map(col => <TableCell>
                        <p dangerouslySetInnerHTML={{ __html: col }}></p>
                    </TableCell>)}
                </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>;
}
