import { Localized, useLocalization } from '@fluent/react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { loadUrsOfferList, UrsOfferHeader } from './ursOfferSlice'
import { selectSettings } from '../settings/settingsSlice'
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { useNavigate } from 'react-router-dom'
import { StructureName } from '../device/Structure'
import { unwrapResult } from '@reduxjs/toolkit'
import { useEffect, useMemo, useState } from 'react'
import { selectModule } from '../modules/moduleSlice'
import { LoadingContainer } from '../../app/LoadingContainer'
import { If } from '../../app/If'
import { DocumentFilters } from '../documents/utils/types'
import { useLocalStorage } from '../../useLocalStorage'
import { filtersToQueryString } from '../documents/utils/helper'
import { DocumentFilterPanel } from '../documents/utils/DocumentFilterPanel'
import NewSmallBadge from '../../app/components/NewSmallBadge'
import { selectContextStructure } from '../structure/contextStructureSlice'
import { selectAllStructure, selectStructureAndChildren } from '../device/structureRelationshipSlice'

const defaultFilters: DocumentFilters = {
    date: { from: "all", to: "all" },
    statusOptions: [
        {
            uniqueName: "N",
            name: "_Komentowanie",
            translationCode: "urs-offer-status-n",
            selected: false
        },
        {
            uniqueName: "R",
            name: "_Zrezygnowano",
            translationCode: "urs-offer-status-r",
            selected: false
        },
        {
            uniqueName: "A",
            name: "_Zakończono komentowanie",
            translationCode: "urs-offer-status-a",
            selected: false
        },
        {
            uniqueName: "S",
            name: "_Wybrano",
            translationCode: "urs-offer-status-s",
            selected: false
        },
        {
            uniqueName: "E",
            name: "_W ocenie",
            translationCode: "urs-offer-status-e",
            selected: false
        },
        {
            uniqueName: "F",
            name: "_Zakończono",
            translationCode: "urs-offer-status-f",
            selected: false
        },
        {
            uniqueName: "O",
            name: "_Opiniowanie",
            translationCode: "urs-offer-status-o",
            selected: false
        },
        {
            uniqueName: "D",
            name: "_???",
            translationCode: "urs-offer-status-d",
            selected: false
        }
    ],
    myRoleOptions: [
        {
            uniqueName: "IN",
            name: "_Jestem w zespole",
            translationCode: "filters-im-in-the-team",
            checkOther: true,
            selected: false
        },
        {
            uniqueName: "PROCESS_OWNER",
            name: "_Twórca",
            translationCode: "team-role-process-owner",
            selected: false
        },
        {
            uniqueName: "EDITOR",
            name: "_Edytor",
            translationCode: "team-role-editor",
            selected: false
        }
    ]
}

export const UrsOfferList = () => {
    const dispatch = useAppDispatch()
    const { l10n } = useLocalization()
    const navigate = useNavigate()
    const { locale } = useAppSelector(selectSettings);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [allOffers, setAllOffers] = useState<UrsOfferHeader[]>([]);
    const currentModule = useAppSelector(state => selectModule(state).currentModule) 
    const { data: structure } = useAppSelector(selectContextStructure)
    const relationship = useAppSelector(state => {
        if (structure && structure.mode === 'manual') {
            return selectStructureAndChildren(state, structure.structId)
        } else {
            return selectAllStructure(state)
        }
    })

    const [filters, setFilters] = useLocalStorage<DocumentFilters>("URSRequestListFilters", defaultFilters)
    const f = useMemo(() => filtersToQueryString(filters), [filters])

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadUrsOfferList({
            moduleId: currentModule?.sysModuleId ?? "",
            filter: f,
        }))
            .then(unwrapResult)
            .then(setAllOffers)
            .finally(() => setIsLoading(false));
    }, [])

    const offers = useMemo(() => {
        if (structure) {
            return allOffers.filter(({ deviceTypeId }) => relationship.has(deviceTypeId));
        } else {
            return allOffers
        }
    }, [allOffers, relationship, structure])

    const isToday = (dateString: string) => {
        const date = new Date(dateString);
        const today = new Date();

        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };
    
    const reload = () => {
        setIsLoading(true)
        dispatch(loadUrsOfferList({
            moduleId: currentModule?.sysModuleId ?? "",
            filter: f,
        }))
            .then(unwrapResult)
            .then(setAllOffers)
            .finally(() => setIsLoading(false));

    }

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleGoToClick = (id) => {
        navigate(`/ursOffers/edit/${id}`)
    };

    const columns: GridColDef<UrsOfferHeader, any, any>[] = [
        {
            field: 'ursNo',
            headerName: l10n.getString("urs-number", null, "_Numer URS"),
            minWidth: 150
        },
        {
            field: 'title',
            headerName: l10n.getString("document-title", null, "_Document title"),
            minWidth: 300,
            flex: 1
        },
        {
            field: 'deviceTypeId',
            headerName: l10n.getString("urs-device-type", null, "_Typ urzadzenia"),
            flex: 1,
            renderCell:(params) =>{
                return (
                    <StructureName structureId={params.row.deviceTypeId} />
                )
            }
        },
        {
            field: 'companyName',
            headerName: l10n.getString("urs-client", null, "_Klient"),
            width: 300
        },
        {
            field: 'createDate',
            width: 150,
            headerName: l10n.getString('spec-created', null, 'Data utworzenia'),
            valueGetter: (_, row) => row.createDate ? new Date(row.createDate) : null,
            renderCell: (params) => {
                if (!params.value) return "";
                const formattedDate = params.value.toISOString().split('T')[0];
                const isNew = isToday(params.row.createDate);

                return (
                    <>
                        {formattedDate}
                        {isNew && <NewSmallBadge />}
                    </>
                );
            },
        },
        {
            field: 'status',
            headerName: l10n.getString("urs-status", null, "_Status"),
            width: 300,
            valueGetter: (_, row) => {
                var status = row.status.toLowerCase();

                return l10n.getString("urs-offer-status-" + status, null, "_Status")
            }
        },
        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <Button onClick={() => handleGoToClick(params.row.id)} color="secondary" variant="outlined">
                        <Localized id="go-to">
                            <span>go-to</span>
                        </Localized>
                    </Button>
                )
            }
        }
    ]

    return <>
        <DocumentFilterPanel filters={filters} onFilterChange={setFilters} reload={reload}/>
        <If condition={!isLoading} otherwise={<LoadingContainer />}>
            <TableContainer component={Paper}>
                <DataGrid localeText={getCustomLocaleText()}
                    style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                    rows={offers}
                    columns={columns}
                    initialState={{
                        sorting: { sortModel: [{ field: 'createDate', sort: 'desc' }] }
                    }}
                    onRowDoubleClick={({ row }) => handleGoToClick(row.id)}
                />
            </TableContainer>
        </If>
    </>
}
