import { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectAllStructureNodes, selectStructure, selectStructureNodeById } from "../device/structureSlice"
import { AppId } from "../../app/appTypes"
import { alpha, Box, Button, Stack, styled, Typography } from "@mui/material"
import { Localized } from "@fluent/react"
import { SimpleTreeView, TreeItem, treeItemClasses, TreeItemProps } from "@mui/x-tree-view"
import React from "react"
import { StructureName } from "../device/Structure"
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Settings from '@mui/icons-material/Settings'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

const CustomTreeItem = React.forwardRef(
    (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
        <TreeItem {...props} ref={ref} />
    ),
)

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    [`& .${treeItemClasses.label}:hover`]: {
        '& button': {
            visibility: 'visible',
        }
    },
    [`& .${treeItemClasses.label}`]: {
        '& button': {
            visibility: 'hidden',
        }
    },
}));

export interface StructureTreeProps {
    defaultStructureId?: AppId
    expandedOnOpen: boolean
    onNodeSelected: (nodeId: AppId) => void
}

export const SimpleStructureComponent = ({ defaultStructureId, expandedOnOpen = false, onNodeSelected }: StructureTreeProps) => {
    const dispatch = useAppDispatch()
    const rootStructures = useAppSelector(state => selectAllStructureNodes(state).filter(x => x.parentStructureId === null))
    const allNonLeafStructureIds = useAppSelector(
        state => selectAllStructureNodes(state).filter(x => x.subStructureIds.length > 0)
            .map(({ id }) => id))
    //const { selected } = useAppSelector(state => selectStructure(state).tree)
    const [selected, setSelected] = useState<string | null>(defaultStructureId || null);

    const [expanded, setExpanded] = useState<AppId[]>(expandedOnOpen === true ? allNonLeafStructureIds : [])

    const handleExpandAllButtonClick = useCallback(() => {
        setExpanded(allNonLeafStructureIds)
    }, [allNonLeafStructureIds])

    const handleCollapseAllButtonClick = useCallback(() => {
        setExpanded([])
    }, [])
    const handleNodeToggle = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds)
    }, [])
    const handleNodeSelect = useCallback((_, nodeId: string | null) => {
        if (nodeId) {
            setSelected(nodeId)
            onNodeSelected(nodeId)
        }
    }, [dispatch])

    useEffect(() => {
        if (selected) {
        
            const selectedElement = document.querySelector('[aria-selected="true"]');
    
            if (selectedElement) {
                selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [selected]);
    
    
    return <>
        <Stack spacing={2}>
            <Box>
                <Button onClick={handleExpandAllButtonClick}>
                    <Localized id="structure-expand-all">
                        <span>Rozwiń wszystko</span>
                    </Localized>
                </Button>
                <Button onClick={handleCollapseAllButtonClick}>
                    <Localized id="structure-collapse-all">
                        <span>Zwiń wszystko</span>
                    </Localized>
                </Button>
            </Box>
            <SimpleTreeView
                slots={{ collapseIcon: ArrowDropDownIcon, expandIcon: ArrowRightIcon }}
                onExpandedItemsChange={handleNodeToggle}
                expandedItems={expanded}
                selectedItems={selected}
                multiSelect={false}
                onSelectedItemsChange={handleNodeSelect}
            >
                {rootStructures.map(struct =>
                    <StructureTreeItem
                        key={struct.id}
                        nodeId={struct.id}
                    />)}
            </SimpleTreeView>
        </Stack>
    </>;
}


interface StructureTreeItemProps {
    nodeId: AppId
}

const StructureTreeItem = ({ nodeId }: StructureTreeItemProps) => {
    const structure = useAppSelector(state => selectStructureNodeById(state, nodeId))
    const itemRef = React.useRef<HTMLLIElement | null>(null);

    if (structure === undefined) return null

    const { subStructureIds } = structure
    const hasAnyChild = subStructureIds.length > 0

    return <StyledTreeItem
        ref={itemRef}
        itemId={nodeId}
        label={
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                p: 0.5,
                pr: 0,
            }}>

                <Box component={hasAnyChild ? FolderOpenIcon : Settings} />
                <Typography sx={{ marginLeft: 1, marginRight: 4, }}>
                    <StructureName structureId={structure.id} />
                </Typography>
            </Box>
        }>
        {subStructureIds.map(id => <StructureTreeItem
            key={id}
            nodeId={id}
        />)}
    </StyledTreeItem>
}