import { useEffect } from "react"
import { AppId } from "../../app/appTypes"
import { useAppDispatch } from "../../app/hooks"
import { setStructureAuto } from "./contextStructureSlice"

export const StructureContextSetter = ({ structureId }: { structureId: AppId }) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setStructureAuto(structureId))
    }, [])

    return null
}
