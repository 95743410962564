import { Box, Paper, Tab, Tabs } from "@mui/material";
import { Localized } from "@fluent/react"

import { SubscriptionCard } from "./cards/SubscriptionCard";
import { useState } from "react";
import { CompanySetting } from "./companySettingsAPI";
import { CompanyUrsRequestMatchingSettingCard } from "./cards/CompanyUrsRequestMatchingSettingCard";


interface GeneralSettingsProps {
    companySettings: CompanySetting[];
    onUpdate: (updatedSettings: CompanySetting[]) => void;
}

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({ companySettings, onUpdate }) => {
    const [selectedTab, setSelectedTab] = useState(0);


    return (
        <Paper elevation={3} sx={{ width: "100%", height: "100%", display: "flex", flexGrow: 1 }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
                sx={{ borderRight: 1, borderColor: "divider", minWidth: 200, height: "100%" }}
            >
                <Tab
                    key={0}
                    label={<Localized id="company-settings-general">
                        <span>_general</span>
                    </Localized>}
                />
                <Tab
                    key={1}
                    label={<Localized id="company-settings-urs-request">
                        <span>_urs</span>
                    </Localized>} />
            </Tabs>
            <Box sx={{ flexGrow: 1, p: 2, display: "flex", flexDirection: "column" }}>
                {selectedTab === 0 && (
                    <SubscriptionCard />
                )}

                {selectedTab === 1 && (
                    <CompanyUrsRequestMatchingSettingCard companySettings={companySettings} onUpdate={onUpdate} />
                )}
            </Box>

        </Paper>

    )
}