import { Localized, useLocalization } from "@fluent/react"
import { useCallback, useEffect, useState } from "react"
import { Box, Button, ButtonGroup, Dialog, FormControl, IconButton, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { unwrapResult } from "@reduxjs/toolkit"
import { useNavigate } from "react-router-dom"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectSettings } from "../../settings/settingsSlice"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { AdminDocumentEdit } from "./AdminDocumentEdit"
import { DocumentStatusLabel } from "../DocumentStatusLabel"
import { DocumentHeader, DocumentStatus, loadAllDocumentsByFilters } from "../documentsApi"
import { QuickSearchToolbar } from "../../../app/QuickSearchToolbar"
import SyncIcon from '@mui/icons-material/Sync';
import { DateMenuFilter } from "../../../app/DateMenuFilter"
import { Company, loadCompanies } from "../../company/companiesSlice"
import { DocumentDetailsDialog } from "./DocumentDetailsDialog"
import { DocumentResetWorkflowDialog } from "./DocumentResetWorkflowDialog"
import produce from "immer"
import { DocumentTypes } from "../documentTypes"

export const DocumentsOverview = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [documents, setDocuments] = useState<DocumentHeader[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<DocumentHeader>();

    const currentDate = new Date();
    var dateFromFilter = new Date();
    dateFromFilter.setDate(currentDate.getDate() - 30);
    const [allDate, setAllDate] = useState<boolean>(true);
    const [dateFrom, setDateFrom] = useState<Date>(dateFromFilter);
    const [dateTo, setDateTo] = useState<Date>(new Date());

    const [company, setCompany] = useState<number | "all">("all");
    const [documentType, setDocumentType] = useState<DocumentTypes | "all">("all");
    const [documentStatus, setDocumentStatus] = useState<DocumentStatus | "all">("all");

    const [documentDialog, setDocumentDialog] = useState<string>("none");
    const [resetWorkflowDialog, setResetWorkflowDialog] = useState<string>("none");

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadAllDocumentsByFilters({ allDate, dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString(), companyId: company, type: documentType, status: documentStatus }))
            .then(unwrapResult)
            .then(setDocuments)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));
        dispatch(loadCompanies())
            .then(unwrapResult)
            .then(setCompanies)
            .catch((error) => console.error(error))
    }, [])


    const handleOpenEditDialog = useCallback(() => {
        setEditDialogOpen(true)
    }, [setEditDialogOpen])

    const handleOpenDocumentDetailsDialog = (id:string) => {
        setDocumentDialog(id);
    }

    const handleOpenResetWorkflowDialog = (id:string) => {
        setResetWorkflowDialog(id);
    }

    const handleSubmit = (date) => {
        if(date.dateFrom === "all") {
            setAllDate(true);
        } else {
            setAllDate(false);
            setDateFrom(date.dateFrom);
            setDateTo(date.dateTo);
        }
    }

    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
    };

    const handleDocumentTypeChange = (event) => {
        setDocumentType(event.target.value);
    };

    const handleDocumentStatusChange = (event) => {
        setDocumentStatus(event.target.value);
    };

    const handleRefreshClick = async () => {
        setIsLoadingData(true);
        dispatch(loadAllDocumentsByFilters({ allDate, dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString(), companyId: company, type: documentType, status: documentStatus }))
            .then(unwrapResult)
            .then(setDocuments)
            .catch((error) => console.error(error))
            .finally(() => setIsLoadingData(false));
    }

    const updateDocument = (document:DocumentHeader) => {
        setResetWorkflowDialog("none");
        setDocuments(produce(draft => {
            const index = draft.findIndex(f => f.id === document.id);
            if(index !== -1) {
                draft[index] = document;
            }
        }));
    }


    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleEditRow = useCallback((entity: DocumentHeader) => {
        setSelectedRow(entity)
        setEditDialogOpen(true)
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'no',
            headerName: l10n.getString('number', null, 'Numer'),
            width: 200,
        },
        {
            field: 'title',
            headerName: l10n.getString('title', null, 'Title'),
            width: 300,
        },
        {
            field: 'companyName',
            headerName: l10n.getString('company', null, 'Firma'),
            width: 300,
        },
        {
            field: 'createdByFullName',
            headerName: l10n.getString('created-by', null, 'Utworzył'),
            width: 300,
        },
        {
            field: 'createDate',
            headerName: l10n.getString('project-create-date', null, 'Data Utworzenia'),
            width: 200,
            valueGetter: (_, row) => {
                return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
            }
        },

        {
            field: 'status',
            headerName: l10n.getString('project-status', null, 'Status'),
            width: 150,
            renderCell: (params) => (
                <div>
                    <DocumentStatusLabel status={params.row.status ?? ""} />
                </div>
            )
        },
        {
            field: 'actions', type: 'actions', width: 300,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        {/* <Button onClick={() => handleEditRow(params.row)} color="secondary" variant="outlined">
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button> */}
                        <Button onClick={() => handleOpenDocumentDetailsDialog(params.row.id)} color="secondary" variant="outlined">
                            <Localized id="details">
                                <span>Szczegóły</span>
                            </Localized>
                        </Button>
                        <Button onClick={() => handleOpenResetWorkflowDialog(params.row.id)} color="error" variant="outlined">
                            <Localized id="documents-overview-reset-wfl">
                                <span>Resetuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ];

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return (
        <>
            <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                <FormControl>
                    <InputLabel id="select-company-label"><Localized id="company">Firma</Localized></InputLabel>
                    <Select
                        size="small"
                        value={company}
                        onChange={handleCompanyChange}
                        labelId="select-company-label"
                        label={<Localized id="company">Firma</Localized>}
                        sx={{ width: '200px' }}
                    >
                        <MenuItem value={'all'}>
                            <Localized id="all">All</Localized>
                        </MenuItem>
                        {companies.map((option) =>
                            <MenuItem value={option.id}>{option.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="select-type-label"><Localized id="type">Typ</Localized></InputLabel>
                    <Select
                        size="small"
                        value={documentType}
                        onChange={handleDocumentTypeChange}
                        labelId="select-type-label"
                        label={<Localized id="type">Typ</Localized>}
                        sx={{ width: '200px' }}
                    >
                        <MenuItem value={'all'}>
                            <Localized id="all">All</Localized>
                        </MenuItem>
                        <MenuItem value={'RISKANALYSIS'}>
                            <Localized id="risk-analysis">Analiza ryzyka</Localized>
                        </MenuItem>
                        <MenuItem value={'URS'}>URS</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="select-status-label"><Localized id="status">Status</Localized></InputLabel>
                    <Select
                        size="small"
                        value={documentStatus}
                        onChange={handleDocumentStatusChange}
                        labelId="select-status-label"
                        label={<Localized id="status">Status</Localized>}
                        sx={{ width: '200px' }}
                    >
                        <MenuItem value={'all'}>
                            <Localized id="all">All</Localized>
                        </MenuItem>
                        <MenuItem value={'N'}>
                            <Localized id="document-status-n">Nowy</Localized>
                        </MenuItem>
                        <MenuItem value={'D'}>
                            <Localized id="document-status-d">Zaopiniowany</Localized>
                        </MenuItem>
                        <MenuItem value={'I'}>
                            <Localized id="document-status-i">Do zatwierdzenia</Localized>
                        </MenuItem>
                        <MenuItem value={'H'}>
                            <Localized id="document-status-h">Zawieszony</Localized>
                        </MenuItem>
                        <MenuItem value={'F'}>
                            <Localized id="document-status-f">Zakończony</Localized>
                        </MenuItem>
                        <MenuItem value={'C'}>
                            <Localized id="document-status-c">Korekta</Localized>
                        </MenuItem>
                        <MenuItem value={'A'}>
                            <Localized id="document-status-a">Zatwierdzony</Localized>
                        </MenuItem>
                        <MenuItem value={'P'}>
                            <Localized id="document-status-p">Opublikowany</Localized>
                        </MenuItem>
                        <MenuItem value={'E'}>
                            <Localized id="document-status-e">Zaimportowany</Localized>
                        </MenuItem>
                        <MenuItem value={'O'}>
                            <Localized id="document-status-o">Opiniowanie</Localized>
                        </MenuItem>
                        <MenuItem value={'R'}>
                            <Localized id="document-status-r">Odrzucony</Localized>
                        </MenuItem>
                    </Select>
                </FormControl>
                <DateMenuFilter defaultOption="all" onSubmit={handleSubmit}/>
                <IconButton
                    disabled={isLoadingData}
                    onClick={handleRefreshClick}>
                    <SyncIcon />
                </IconButton>
            </Stack>
            <Box sx={{ width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            style={{ minHeight: 100, height: 'calc(100vh - 220px)' }}
                            localeText={getCustomLocaleText()}
                            rows={documents}
                            columns={columns}
                            slots={{ toolbar: QuickSearchToolbar }}
                        />
                    </div>
                </div>
            </Box>
            <Dialog open={documentDialog !== "none"} fullWidth maxWidth="xl" keepMounted={false}>
                <DocumentDetailsDialog documentId={documentDialog} document={documents.find(f => f.id === documentDialog)} onCancel={() => setDocumentDialog("none")} />
            </Dialog>
            <Dialog open={resetWorkflowDialog !== "none"}>
                <DocumentResetWorkflowDialog documentId={resetWorkflowDialog} onSuccess={updateDocument} onCancel={() => setResetWorkflowDialog("none")}/>
            </Dialog>
            {
                editDialogOpen && selectedRow &&
                <AdminDocumentEdit document={selectedRow} onSucces={function (item: DocumentHeader): void {
                    throw new Error("Function not implemented.")
                }} onCancel={function (): void {
                    throw new Error("Function not implemented.")
                }}></AdminDocumentEdit>
            }

        </>
    )
}
