import { useCallback, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    TextField,
    Button,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { SimpleStructureComponent } from "./SimpleStructureComponent";
import { AppId } from "../../app/appTypes";
import { getStructureName } from "../device/Structure";
import { ArrowDropDown } from "@mui/icons-material";
import { RequiredError } from "../../app/components/RequiredError";
import { Localized } from "@fluent/react";

export interface StructureTreeSelectProps {
    defaultStructureId?: AppId
    onStructureSelected: (structureId: AppId) => void
}

export const StructureTreeSelect = ({ defaultStructureId, onStructureSelected }: StructureTreeSelectProps) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<string | null>(defaultStructureId || null);
    const [tempSelected, setTempSelected] = useState<string | null>('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmSelection = () => {
        if (tempSelected) {
            setSelected(tempSelected);
            onStructureSelected(tempSelected);
        }
        handleClose();
    };

    const handleNodeSelected = useCallback((nodeId: AppId) => {
        setTempSelected(nodeId);
    }, []);

    return (
        <Box>
            <TextField
                label={<Localized id="structure"><span>_structure</span></Localized>}
                required
                error={selected === null}
                helperText={selected === '' && <RequiredError />}
                value={selected ? `${getStructureName(selected)}` : ""}
                onClick={handleOpen}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleOpen}>
                                <ArrowDropDown />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
            />
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Localized id="structure-select-item"><span>_select item</span></Localized>
                </DialogTitle>
                <DialogContent dividers>
                    <SimpleStructureComponent defaultStructureId={defaultStructureId} onNodeSelected={handleNodeSelected} expandedOnOpen={true} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmSelection} variant="contained" color="primary" size="small">
                        <Localized id="select"><span>_select</span></Localized>
                    </Button>
                    <Button onClick={handleClose} color="secondary" size="small">
                        <Localized id="cancel"><span>_cancel</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
