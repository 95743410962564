import React, { useState } from "react";
import { Paper, Tabs, Tab, Box } from "@mui/material";
import { CompanyPasswordSettingCard } from "./cards/CompanyPasswordSettingCard";
import { CompanyLoginSettingCard } from "./cards/CompanyLoginSettingCard";
import { CompanySetting } from "./companySettingsAPI";
import { Localized } from "@fluent/react";

interface SecuritySettingsTabPanelProps {
    companySettings: CompanySetting[];
    onUpdate: (updatedSettings: CompanySetting[]) => void;
}

export const SecuritySettingsTabPanel: React.FC<SecuritySettingsTabPanelProps> = ({ companySettings, onUpdate }) => {
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <Paper elevation={3} sx={{ width: "100%", height: "100%", display: "flex", flexGrow: 1 }}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedTab}
                onChange={(_, newValue) => setSelectedTab(newValue)}
                sx={{ borderRight: 1, borderColor: "divider", minWidth: 200, height: "100%" }}
            >
                <Tab
                    key={0}
                    label={<Localized id="company-settings-login">
                        <span>_login</span>
                    </Localized>}
                />
                <Tab
                    key={1}
                    label={<Localized id="company-settings-password">
                        <span>_haslo</span>
                    </Localized>} />
            </Tabs>

            <Box sx={{ flexGrow: 1, p: 2, display: "flex", flexDirection: "column" }}>
                {selectedTab === 0 && (
                    <CompanyLoginSettingCard
                        companySettings={companySettings}
                        onUpdate={onUpdate}
                    />
                )}

                {selectedTab === 1 && (
                    <CompanyPasswordSettingCard
                        companySettings={companySettings}
                        onUpdate={onUpdate}
                    />
                )}
            </Box>
        </Paper>
    );
};