import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { DocumentLanguages } from '../documentTypes';
import { Localized } from '@fluent/react';


interface DocumentLanguageSelectProps {
    defaultValue?: DocumentLanguages | "";
    required?: boolean;
    onChange?: (value: DocumentLanguages) => void;
}

export const DocumentLanguageSelect: React.FC<DocumentLanguageSelectProps> = ({
    defaultValue = "",
    required = false,
    onChange,
}) => {
    const [selected, setSelected] = useState<DocumentLanguages | "">(defaultValue);
    const languages: DocumentLanguages[] = [
        'MULTI', 'EN', 'PL'
    ];

    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value as DocumentLanguages | "";
        if (value) {
            setSelected(value);
            if (onChange && value) {
                onChange(value);
            }
        }
    };

    return (
        <FormControl fullWidth required={required} error={required && selected === ""} >
            <InputLabel shrink id="document-type-label">Typ dokumentu</InputLabel>
            <Select
                labelId="document-type-label"
                id="document-type-select"
                value={selected}
                label={<Localized id="document-language"><span>_document-language</span></Localized>}
                onChange={handleChange}
                required={required}
                displayEmpty
            >
                {languages.map((value) => (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
