import { Localized, useLocalization } from "@fluent/react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useEffect } from "react"
import { Button, ButtonGroup, Fab, Paper, TableContainer } from "@mui/material"
import { FlexDiv } from "../../app/Utils"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { loadProjects, selectAllProjects, selectProjects } from "./projectSlice"
import { ProjectStatusLabel } from "./ProjectStatusLabel"
import { LoadingContainer } from "../../app/LoadingContainer"
import { useNavigate } from "react-router-dom"
import { selectSettings } from "../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { QuickSearchToolbar } from "../../app/QuickSearchToolbar"
import { ErrorContainer } from "../../app/ErrorContainer"

export const ProjectsList = () => {
    const { locale } = useAppSelector(selectSettings);
    const { loaded, error } = useAppSelector(selectProjects)
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const rows = useAppSelector(state => selectAllProjects(state))

    useEffect(() => {
        if (!loaded) {
            dispatch(loadProjects())
        }
    }, [dispatch, loaded])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleGoToClick = (id) => {
        navigate(`/projects/edit/${id}`)
    };

    const columns: GridColDef[] = 
    [
        { 
            field: 'no', 
            width: 200,
            headerName:l10n.getString('project-no',null,'Numer projektu'),  

        },
        { 
            field: 'name', 
            headerName: l10n.getString('project-title',null,'Tytuł projektu'),   
            flex:1 
        },
        { 
            field: 'companyName', 
            headerName: l10n.getString('project-company-name',null,'Nazwa firmy'),   
            width: 400
        },
        { 
            field: 'createDate', 
            type:'date',    
            headerName: l10n.getString('project-create-date',null,'Data utworzenia'),   
            width: 150,
            valueGetter: (_, row) => new Date(row.createDate),
        },
        { 
            field: 'status', 
            headerName: l10n.getString('project-status',null,'Status'),   
            width: 150,
            renderCell:(params) => (
                <div>
                    <ProjectStatusLabel status={params.value ?? ""}/>
                </div>
                
            )
        },
        { 
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return(
                <ButtonGroup>
                    <Button onClick={() => handleGoToClick(params.id)} color="secondary" variant="outlined">
                            <Localized id="go-to">
                            <span>Przejdź</span> 
                        </Localized>
                    </Button>
                </ButtonGroup>
                )
            }
        }
    ];

    if (error) {
        return <>
            <ErrorContainer error={error} />
        </>
    }
    
    if (!loaded) {
        return <>
            <LoadingContainer/>
        </>
    }

    return (
        <>
        <TableContainer component={Paper}>
            <DataGrid 
                localeText={getCustomLocaleText()}
                rows={rows} columns={columns} 
                style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                slots={{ toolbar: QuickSearchToolbar }}
                onRowDoubleClick={({ row }) => handleGoToClick(row.id)}
            />
        </TableContainer>
        <FlexDiv>
            <Fab component={Link} to="/projects/create" color="secondary" sx={{marginTop:2}}>
            <AddIcon />
            </Fab>
        </FlexDiv>
      </>
    )
}
