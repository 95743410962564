import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { DocumentTypes } from '../documentTypes';
import { DocumentTypesLabel } from '../components/DocumentTypesLabel';
import { Localized } from '@fluent/react';


interface DocumentTypeSelectProps {
  defaultValue?: DocumentTypes | "";
  required?: boolean;
  onChange?: (value: DocumentTypes) => void;
}

export const DocumentTypeSelect: React.FC<DocumentTypeSelectProps> = ({
  defaultValue = "",
  required = false,
  onChange,
}) => {
  const [selectedType, setSelectedType] = useState<DocumentTypes | "">(defaultValue);
  const documentTypesList: DocumentTypes[] = [
    "URS",
    "RISK_ANALYSIS",
    "DQ", "IQ", "PQ", "OQ", "FAT", "SAT", "FS", "DS", "CL",
  ];

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as DocumentTypes | "";
    if (value) {
      setSelectedType(value);
      if (onChange) {
        onChange(value);
      }
    }
  };

  return (
    <FormControl fullWidth required={required} error={required && selectedType === ""} >
      <InputLabel shrink id="document-type-label">Typ dokumentu</InputLabel>
      <Select
        labelId="document-type-label"
        id="document-type-select"
        value={selectedType}
        label={<Localized id="document-type"><span>_"document-type</span></Localized>}
        onChange={handleChange}
        required={required}
        displayEmpty
      >
        {documentTypesList.map((type) => (
          <MenuItem key={type} value={type}>
            <DocumentTypesLabel docType={type} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
