import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { Button, ButtonGroup, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Fab,  Grid,  Paper, Tab, TableContainer } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { Localized, useLocalization } from "@fluent/react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectSettings } from "../../settings/settingsSlice"
import { ErrorContainer } from "../../../app/ErrorContainer"
import { APIError, AppId } from "../../../app/appTypes"
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { FlexDiv } from "../../../app/Utils"
import AddIcon from '@mui/icons-material/Add'
import { CompanySetting, CompanySupplierAssigmentSetting, deleteSupplierAssigmentsSettings, loadCompanySettings, loadSupplierAssigmentsSettings } from "./companySettingsAPI"
import { unwrapResult } from "@reduxjs/toolkit"
import { MailingGroupList } from "../mailingGroups/MailingGroupList"
import { AddEditCompanySettingDialog, AddEditCompanySettingsDialogProps } from "./AddEditCompanySettingDialog"
import produce from "immer"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { GeneralSettings } from "./GeneralSettings"
import { SecuritySettingsTabPanel } from "./SecuritySettingsTabPanel"

type FormTabs = "GeneralSettings" | "UrsRequestSettings" | "MailingGroups" | "SecuritySettings"

type DialogState =
    | { type: "none" }
    | { type: "createUpdateSetting", settingId: AppId | undefined }
    | { type: "createUpdateSupplierAssigmentSetting", supplierAssigmentSettingId: AppId | undefined }

type SupplierAssigmentDialogState =
    | { type: "none" }
    | { type: "createSupplierAssigmentSetting", props: AddEditCompanySettingsDialogProps }
    | { type: "editSupplierAssigmentSetting", props: AddEditCompanySettingsDialogProps }


export const CompanySettings = () => {
    const dispatch = useAppDispatch();

    const [params, setParams] = useSearchParams()
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const tab = params.get('tab') as FormTabs || 'GeneralSettings'
    const [loadingError, setError] = useState<APIError | undefined>(undefined)
    const [loadingErrorOccured, setLoadingErrorOccured] = useState<boolean>(false)
    const [companySettings, setCompanySettings] = useState<CompanySetting[]>([]);
    const [supplierAssigmentSettings, setSupplierAssigmentSettings] = useState<CompanySupplierAssigmentSetting[]>([]);
    const [dialog, setDialog] = useState<DialogState>({ type: "none" })
    const [supplierAssigmentDialog, setSupplierAssigmentDialog] = useState<SupplierAssigmentDialogState>({ type: "none" })
    const [saving, setSaving] = useState<boolean>(false);
    const [deleteSetting, setDeleteSetting] = useState<false | string>(false);

    const setTab = (tab: FormTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadCompanySettings())
            .then(unwrapResult)
            .then(setCompanySettings)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false))
        dispatch(loadSupplierAssigmentsSettings())
            .then(unwrapResult)
            .then(result => {
                setSupplierAssigmentSettings(result);
            }).catch((error) => console.error(error))
    }, [dispatch]);


    const handleDeleteSettingClick = useCallback((id: string) => {
        setDeleteSetting(id)
    }, [])

    const handleConfirmDeleteSettingClick = async () => {
        if (deleteSetting !== false) {
            try {
                setSaving(true)
                const result = await dispatch(deleteSupplierAssigmentsSettings(deleteSetting));
                if (deleteSupplierAssigmentsSettings.fulfilled.match(result)) {
                    setSupplierAssigmentSettings(
                        produce((draft) => {
                            if (draft !== undefined) {
                                const existingIndex = draft.findIndex((group) => group.id === deleteSetting);

                                if (existingIndex !== -1) {
                                    draft.splice(existingIndex, 1);
                                }
                            }
                        })
                    );
                    dispatch(showSuccess("saved"))
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"));
            }
            finally {
                setSaving(false)
                setDeleteSetting(false)
            }
        }
        setDeleteSetting(false)
    }

    const handleCancelDeleteSettingClick = async () => {
        setDeleteSetting(false)
    }

    const handleAddSupplierAssigmentSetting = useCallback(() => {
        setSupplierAssigmentDialog({
            type: "createSupplierAssigmentSetting",
            props: {
                companySupplierAssigmentSetting: undefined,
                mode: "create",
                onSuccessfullySaved: (newSetting: CompanySupplierAssigmentSetting) => {
                    setDialog({ type: "none" });
                },
                onCancel: () => setDialog({ type: "none" })
            }
        });
    }, [setDialog]);

    const handleEditSupplierAssigmentSetting = useCallback((companySupplierAssigmentSetting: CompanySupplierAssigmentSetting) => {
        setSupplierAssigmentDialog({
            type: "editSupplierAssigmentSetting",
            props: {
                companySupplierAssigmentSetting,
                mode: "edit",
                onSuccessfullySaved: (newSetting: CompanySupplierAssigmentSetting) => {
                    setDialog({ type: "none" });
                },
                onCancel: () => setDialog({ type: "none" })
            }
        });
    }, [setDialog]);

    const DialogContentDispatcher = ({ state, setter }: { state: SupplierAssigmentDialogState, setter: Dispatch<SetStateAction<SupplierAssigmentDialogState>> }) => {
        const dispatch = useAppDispatch()

        const onSuccess = useCallback((companySupplierAssigmentSetting: CompanySupplierAssigmentSetting) => {
            setter({ type: "none" })
            setSupplierAssigmentSettings(
                produce((draft) => {
                    if (draft !== undefined) {
                        const existingIndex = draft.findIndex((group) => group.id === companySupplierAssigmentSetting.id);

                        if (existingIndex !== -1) {
                            draft[existingIndex] = { ...draft[existingIndex], ...companySupplierAssigmentSetting };
                        } else {
                            draft.push(companySupplierAssigmentSetting);
                        }
                    }
                })
            );
        }, [setter, dispatch])

        const onCancel = useCallback(() => {
            setter({ type: "none" })
        }, [setter])

        switch (state.type) {
            case "none":
                return null

            case "createSupplierAssigmentSetting":
                return <AddEditCompanySettingDialog
                    companySupplierAssigmentSetting={undefined}
                    mode={'create'}
                    onSuccessfullySaved={onSuccess}
                    onCancel={onCancel}
                />

            case "editSupplierAssigmentSetting":
                return <AddEditCompanySettingDialog
                    companySupplierAssigmentSetting={state.props.companySupplierAssigmentSetting}
                    mode={'edit'}
                    onSuccessfullySaved={onSuccess}
                    onCancel={onCancel}
                />
        }
    }


    const supplierAssigmentcolumns: GridColDef<CompanySupplierAssigmentSetting, any, any>[] = [
        {
            field: 'event',
            headerName: l10n.getString("company-settings-event", null, "_Event"),
            width: 300
        },

        {
            field: 'user',
            headerName: l10n.getString("user", null, "_User"),
            flex: 1
        },
        {
            field: 'role',
            headerName: l10n.getString("user-role", { num: 1 }, "_Role"),
            width: 200
        },
        {
            field: 'notifyUser',
            headerName: l10n.getString("active", null, "_active"),
            width: 100,
            renderCell: (params) => (
                <Checkbox
                    checked={params.value}
                    disabled
                    inputProps={{ 'aria-label': 'Is Active Checkbox' }}
                />
            ),
        },
        {
            field: 'notificationMethod',
            headerName: l10n.getString("notification-method", null, "_Notification method"),
            width: 300
        },
        {
            field: 'modifyDate',
            headerName: l10n.getString("edition-date", null, "_Modify Date"),
            flex: 1,
            valueGetter: (_, row) => {
                return new Date(row.modifyDate).toLocaleString();
            }
        },
        {
            field: 'actions', type: 'actions', width: 300,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 100 }}
                            onClick={() => handleEditSupplierAssigmentSetting(params.row)}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                        <Button
                            style={{ width: 100 }}
                            onClick={() => handleDeleteSettingClick(params.row.id)}
                            color="error"
                            variant="outlined" >
                            <Localized id="delete">
                                <span>Usuń</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    if (loadingErrorOccured) {
        return <>
            <ErrorContainer error={loadingError} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                key={0}
                                value={"GeneralSettings"}
                                label={<Localized id="company-settings-general-settings">_General Settings</Localized>} />
                            <Tab
                                key={1}
                                value={"SecuritySettings"}
                                label={<Localized id="company-settings-security-settings">_Security</Localized>} />
                            <Tab
                                key={2}
                                value={"MailingGroups"}
                                label={<Localized id="company-settings-mailing-groups">_Mailing groups</Localized>} />
                            <Tab
                                key={3}
                                value={"UrsRequestSettings"}
                                label={<Localized id="company-settings-urs-request">URS Request settings</Localized>} />
                        </TabList>
                        <TabPanel value="GeneralSettings">
                            <GeneralSettings
                                companySettings={companySettings}
                                onUpdate={(updatedSettings) => setCompanySettings(updatedSettings)}
                            />
                        </TabPanel>
                        <TabPanel value="SecuritySettings">
                            <SecuritySettingsTabPanel
                                companySettings={companySettings}
                                onUpdate={(updatedSettings) => setCompanySettings(updatedSettings)}
                            />
                        </TabPanel>
                        <TabPanel value="MailingGroups">
                            <MailingGroupList />
                        </TabPanel>
                        <TabPanel value="UrsRequestSettings">
                            <TableContainer component={Paper}>
                                <DataGrid localeText={getCustomLocaleText()}
                                    style={{ minHeight: 100, height: 'calc(100vh - 300px)' }}
                                    rows={supplierAssigmentSettings}
                                    columns={supplierAssigmentcolumns}
                                />
                            </TableContainer>
                            <FlexDiv>
                                <Fab sx={{
                                    marginTop: 2,
                                }}
                                    onClick={handleAddSupplierAssigmentSetting}
                                    color="secondary"
                                >
                                    <AddIcon />
                                </Fab>
                            </FlexDiv>
                            <Dialog open={deleteSetting !== false}>
                                <DialogContent>
                                    <DialogContentText>
                                        <Localized id="company-settings-delete">
                                            <span>Czy na pewno chcesz usunąć to ustawienie?</span>
                                        </Localized>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <LoadingButton loading={saving} onClick={handleConfirmDeleteSettingClick}>
                                        <Localized id="yes"><span>Tak</span></Localized>
                                    </LoadingButton>
                                    <LoadingButton loading={saving} onClick={handleCancelDeleteSettingClick}>
                                        <Localized id="no"><span>Nie</span></Localized>
                                    </LoadingButton>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={supplierAssigmentDialog.type !== "none"} fullWidth maxWidth="lg" keepMounted={false}>
                                <DialogContentDispatcher state={supplierAssigmentDialog} setter={setSupplierAssigmentDialog} />
                            </Dialog>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </>
    )
}
