import { Localized } from "@fluent/react";
import { Typography } from "@mui/material";

export const RequiredError = () => (
    <Typography color="error" variant="caption" role="alert">
        <Localized id="field-is-required">
            <span>Pole jest wymagane</span>
        </Localized>
    </Typography>
);
