
import { Avatar, Grid, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { selectLoggedUser, selectUserCompanyId, selectUserCompanyName } from './userSlice';
import { ReactComponent as ALuk } from './avatar.svg';
import { ReactComponent as APrim } from './1.svg';
import { ReactComponent as AOther } from './2.svg';
import { selectCompanyById } from '../company/companiesSlice';
import { useNavigate } from 'react-router-dom';
import { MoreVert } from "@mui/icons-material";
import { useState } from 'react';
import { Localized } from '@fluent/react';
import PersonIcon from '@mui/icons-material/Person';

export const AvatarImage = ({ user }: { user: string }) => {
    if (user.toLowerCase() === 'lukasz') {
        return <ALuk />;
    }
    else if (user.toLowerCase() === 'primoz') {
        return <APrim />
    }
    return <AOther />;
}

export const LoggedUserCard = () => {
    const navigate = useNavigate();
    const loggedUser = useAppSelector(selectLoggedUser);
    const companyId = useAppSelector(selectUserCompanyId) as string;
    const companyName = useAppSelector(selectUserCompanyName) as string;
    const company = useAppSelector(state => selectCompanyById(state, companyId));

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileClick = () => {
        navigate("/user-profile");
        handleClose();
    };

    return (
        <Grid sx={{ display: 'flex' }} alignItems="center">
            <Grid item>
                <Avatar
                    alt="I"
                    sx={{ width: 40, height: 40 }}>
                </Avatar>
            </Grid>
            <Grid item sx={{ marginLeft: 1 }}>
                <Typography variant="button" noWrap component="div" >
                    {loggedUser}
                </Typography>
                <Typography variant="caption" noWrap component="div" >
                    {companyName}
                </Typography>
            </Grid>
            <Grid item>
                <Tooltip title={<Localized id="options">_options</Localized>}>
                    <IconButton onClick={handleMenuClick} size="small" color="inherit">
                        <MoreVert />
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleProfileClick}>
                        <ListItemIcon>
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        <Localized id="menu-user-profile">
                            <span>_user profile</span>
                        </Localized>
                    </MenuItem>
                </Menu>
            </Grid>
        </Grid>
    );
};
