import { useCallback, useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { AppId } from './appTypes';
import type { RootState, AppDispatch } from './store';
import { enUS, plPL } from "@mui/x-data-grid/locales";
import { selectSettings } from '../features/settings/settingsSlice';
import { setStructureAuto } from '../features/structure/contextStructureSlice';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDocNavigator = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    return useCallback((url: string, structureId: AppId, options?: NavigateOptions) => {
        dispatch(setStructureAuto(structureId))
        navigate(url, options)
    }, [])
}

export const useQuery = () => {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

export const useQueryId = (): AppId | undefined => {
    const query = useQuery()

    if (query.has("id")) {
        return query.get("id") ?? undefined
    } else {
        return undefined
    }
}

export const useQueryProjectId = (): AppId | undefined => {
    const query = useQuery()

    if (query.has("projectId")) {
        return query.get("projectId") ?? undefined
    } else {
        return undefined
    }
}

export const useQueryBusinessCaseId = (): AppId | undefined => {
    const query = useQuery()

    if (query.has("businessCaseId")) {
        return query.get("businessCaseId") ?? undefined
    } else {
        return undefined
    }
}


export const useDataGridCustomLocaleText = () => {
    const { locale } = useAppSelector(selectSettings);

    return locale === "pl"
        ? plPL.components.MuiDataGrid.defaultProps.localeText
        : enUS.components.MuiDataGrid.defaultProps.localeText;
};
