import { useEffect, useState } from "react"
import { MeetingMinuteStatistics, loadMeetingMinutesStatistics } from "./meetingMinutesSlice"
import { useAppDispatch } from "../../../app/hooks"
import { unwrapResult } from "@reduxjs/toolkit"
import { Card, CardContent, Container, Paper, Typography } from "@mui/material"
import { PieChart } from "@mui/x-charts"
import { Localized, useLocalization } from "@fluent/react"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { ErrorContainer } from "../../../app/ErrorContainer"
import { APIError } from "../../../app/appTypes"

export const MeetingMinutesStatistics = (props: { meetingMinuteId: string }) => {
    const { l10n } = useLocalization()
    const { meetingMinuteId } = props
    const dispatch = useAppDispatch()
    const [statistics, setStatistics] = useState<MeetingMinuteStatistics>();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loadingError, setLoadingError] = useState<APIError | undefined>(undefined)
    const [loadingErrorOccured, setLoadingErrorOccured] = useState<boolean>(false)

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadMeetingMinutesStatistics(meetingMinuteId))
            .then(unwrapResult)
            .then(setStatistics)
            .catch((error) => { console.log(error); setLoadingErrorOccured(true); setLoadingError(error); })
            .finally(() => setIsLoading(false));
    }, [])

    if (loadingErrorOccured) {
        return <>
            <ErrorContainer error={loadingError} />
        </>
    }

    if (isLoading) {
        return <LoadingContainer/>
    }

    //!

    if (!statistics) return <></>

    return (
        <Container sx={{
            paddingTop: 2,
            paddingBottom: 2,
        }} component={Paper} maxWidth={false} >

            <Card>
                <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                        <Localized id="meeting-minutes-meetings-count">
                            <span>Liczba spotkań</span>
                        </Localized>
                    </Typography>
                    <Typography variant="h5">
                        {statistics?.meetingsCount}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        <Localized id="meeting-minutes-meetings-average-time">
                            <span>Średni czas spotkań (hh:mm:ss)</span>
                        </Localized>
                    </Typography>
                    <Typography variant="h5">
                        {statistics?.averageMeetingTime}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        <Localized id="meeting-minutes-longest-meeting">
                            <span> Najdłuższe spotkanie (hh:mm:ss)</span>
                        </Localized>
                    </Typography>
                    <Typography variant="h5">
                        {statistics?.maxMeetingTime}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        <Localized id="meeting-minutes-shortest-meeting">
                            <span> Najkrótsze spotkanie (hh:mm:ss)</span>
                        </Localized> 
                    </Typography>
                    <Typography variant="h5">
                        {statistics?.minMeetingTime}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        <Localized id="meeting-minutes-meetings-finished-on-time">
                            <span> Liczba spotkań zakończonych na czas (+/- 2 min)</span>
                        </Localized> 
                    </Typography>
                    <Typography variant="h5">
                        {statistics?.meetingsEndedOnTime}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        <Localized id="meeting-minutes-meetings-finished-after-time">
                            <span> Liczba spotkań zakończonych po czasie</span>
                        </Localized> 
                    </Typography>
                    <Typography variant="h5">
                        {statistics?.meetingsEndedAfterTime}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                        <Localized id="meeting-minutes-meetings-finished-before-time">
                            <span> Liczba spotkań zakończonych przed czasem</span>
                        </Localized> 
                    </Typography>
                    <Typography variant="h5">
                        {statistics?.meetingsEndedBeforeTime}
                    </Typography>
                </CardContent>
            </Card>
            <PieChart
                series={[
                    {

                        arcLabel: (item) => `${item.value}`,
                        arcLabelMinAngle: 45,

                        data: [
                            { 
                                id: 0, 
                                value: statistics?.meetingsEndedBeforeTime, 
                                label: l10n.getString("meeting-minutes-meetings-finished-before-time", null, "Spotkania przed czasem")
                            },
                            { 
                                id: 1, 
                                value: statistics?.meetingsEndedAfterTime, 
                                label: l10n.getString("meeting-minutes-meetings-finished-after-time", null, "Spotkania po czasie")
                            },
                            { 
                                id: 2, 
                                value: statistics?.meetingsEndedOnTime, 
                                label: l10n.getString("meeting-minutes-meetings-finished-on-time", null, "Spotkania na czas")
                            },
                        ],
                    },
                ]}
                width={900}
                height={200}
            />
        </Container>
    );
}
