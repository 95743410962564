import { useState } from "react";
import { Dialog, DialogContent, DialogTitle, DialogActions, Alert, Switch, FormControlLabel, Slider, Collapse, IconButton, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ExpandMore } from "@mui/icons-material";
import { Localized } from "@fluent/react";

const AutoMatchDialog = ({ serverError, saving, setServerError, handleConfirmAutoMatchClick, handleDialogCancelClick }) => {
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [settings, setSettings] = useState({
        levenshtein: { enabled: true, level: 75 },
        sbert: { enabled: true, level: 75 },
    });

    const handleToggle = (key) => {
        setSettings(prev => ({
            ...prev,
            [key]: { ...prev[key], enabled: !prev[key].enabled }
        }));
    };

    const handleSliderChange = (key, value) => {
        setSettings(prev => ({
            ...prev,
            [key]: { ...prev[key], level: value }
        }));
    };

    return (
        <Dialog open={true}>
            <DialogContent>
                <DialogTitle>
                    <Localized id="urs-offer-matching-question">
                        <span>Czy chcesz aby system zaproponował powiązania między wymaganiami o ofertą?</span>
                    </Localized>
                </DialogTitle>
                <Alert severity="info">
                    <Localized id="urs-offer-matching-question-description">
                        <span>Auto matching</span>
                    </Localized>
                </Alert>

                {serverError && (
                    <Alert sx={{ marginTop: 1 }} onClose={() => setServerError("")} severity="error">
                        <Localized id={serverError}>
                            <span>Server error</span>
                        </Localized>
                    </Alert>
                )}

                {/* Advanced Options Toggle */}
                <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                    <Typography variant="body1" sx={{ marginRight: 1 }}>Advanced Options</Typography>
                    <IconButton onClick={() => setShowAdvanced(!showAdvanced)}>
                        <ExpandMore sx={{ transform: showAdvanced ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </IconButton>
                </div>
                
                <Collapse in={showAdvanced}>
                    <div style={{ marginTop: 10 }}>
                        {Object.keys(settings).map((key) => (
                            <div key={key} style={{ marginBottom: 10 }}>
                                <FormControlLabel
                                    control={<Switch checked={settings[key].enabled} onChange={() => handleToggle(key)} />}
                                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                                />
                                <Slider
                                    value={settings[key].level}
                                    onChange={(e, value) => handleSliderChange(key, value)}
                                    min={0}
                                    max={100}
                                    step={1}
                                    valueLabelDisplay="auto"
                                    disabled={!settings[key].enabled}
                                />
                            </div>
                        ))}
                    </div>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={saving} onClick={handleConfirmAutoMatchClick}>
                    <Localized id="yes"><span>Tak</span></Localized>
                </LoadingButton>
                <LoadingButton loading={saving} onClick={handleDialogCancelClick}>
                    <Localized id="no"><span>Nie</span></Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default AutoMatchDialog;
