import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http";
import { logout } from "../user/userSlice";
import { SupplierDeviceDetail } from "./supplierDeviceDetailsSlice";

export interface SupplierDevice {
   id: AppId, 
   name: string, 
   structureId: string, 
   serialNo: string, 
   productionYear: number,
   description: string
}

export type SupplierDeviceWithDetails =
    SupplierDevice & { supplierDeviceDetails: SupplierDeviceDetail  }

const adapter = createEntityAdapter<SupplierDevice>({
    selectId: (supplierDevice) => supplierDevice.id,
})

export type DraftSupplierDevice = Omit<SupplierDevice, "id">

export type SupplierDevicesState = EntityState<SupplierDevice> & { loaded: boolean }

const initialState: SupplierDevicesState = adapter.getInitialState({ loaded: false })


export const loadSupplierDevices = createAsyncThunk<SupplierDevice[], void, AppThunkAPIType>("supplierdevices/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/SupplierDevice"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as SupplierDevice[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const loadSupplierDevicesForStructure = createAsyncThunk<SupplierDevice[], string, AppThunkAPIType>("supplierdevices/load", async (structureId, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: `api/SupplierDevice?structureId=${structureId}`}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as SupplierDevice[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})


export const loadSupplierDevice = createAsyncThunk<SupplierDevice, AppId, AppThunkAPIType>("supplierdevice/single-load", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: `api/SupplierDevice/${id}`}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as SupplierDevice
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const createSupplierDevice = createAsyncThunk<SupplierDevice, DraftSupplierDevice, AppThunkAPIType>(
    "supplierdevices/create", 
    async (device: DraftSupplierDevice, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/SupplierDevice",
            payload: device,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as SupplierDevice
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateSupplierDevice = createAsyncThunk<SupplierDevice, SupplierDevice, AppThunkAPIType>(
    "supplierdevices/update", 
    async (device: SupplierDevice, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/SupplierDevice/${device.id}`,
            payload: device,
        }))
        const { payload } = result

        if (putWithAuth.fulfilled.match(result)) {
            return device
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const deleteSupplierDevice = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "supplierdevices/delete",
    async (id: AppId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/SupplierDevice/${id}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const supplierDeviceSlice = createSlice({
    name: "supplierdevices",
    initialState,
    reducers: {
        clearSupplierDeviceSlice: (state) => {
            adapter.removeAll(state)
            state.loaded = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createSupplierDevice.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        })
        builder.addCase(deleteSupplierDevice.fulfilled, (state, action) => {
            state = adapter.removeOne(state, action.payload)
        })
        builder.addCase(updateSupplierDevice.fulfilled, (state, action) => {
            const { id } = action.payload
            const changes: Omit<SupplierDevice, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })

        builder.addCase(loadSupplierDevices.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.loaded = true
        })

        builder.addCase(logout.fulfilled, state => {
            adapter.removeAll(state)
            state.loaded = false
        })
    },
})

export const { clearSupplierDeviceSlice } = supplierDeviceSlice.actions

export const selectSupplierDevices = (state: RootState): SupplierDevicesState => state.supplierDevices

export const 
    { selectAll: selectAllSupplierDevices
    , selectById: selectSupplierDeviceById 
    , selectTotal: selectTotalSupplierDevices
    } = adapter.getSelectors<RootState>(selectSupplierDevices)

export default supplierDeviceSlice.reducer;
