import { Alert, Typography } from "@mui/material";
import { Fragment, ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { Empty } from "../../Empty";
import { selectUser } from "./userSlice";

type AuthorizeProps = {
    children: ReactNode
};

export const Authenticate = (props: AuthorizeProps) => {
    const user = useAppSelector(selectUser)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (user.tokenState === "invalid" || user.tokenState === 'empty') {
            navigate({
                pathname: '/login',
            }, {
                state: {
                    prevLocation: location,
                },
            })
        }
    }, [user.tokenState, location])

    if (user.tokenState === undefined) return (<Empty />)

    switch (user.tokenState) {
        case "valid":
            return (<Fragment>{props.children}</Fragment>);
        case "empty":
            return (<Empty />);
        case "invalid":
            return (<Empty />);
        default:
            return (<Empty />);
    }
}
