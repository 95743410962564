import { createAsyncThunk, createEntityAdapter, createSlice, EntityId, EntityState } from "@reduxjs/toolkit";
import { AppDataState, Nullable, AppThunkAPIType, AppId, unknownError } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { getWithAuth, postWithAuth, putWithAuth } from "../../http";
import { logout } from "../user/userSlice";

export type UserId = string;

export type Role = "admin" | "user" | "super"

export interface User {
    id: UserId;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: string;
    position: string;
    companyId: AppId;
    status: string;
    lockedBy: string;
    lockReason: string;
    lockedAt: Date | null;
    expiryDate: Date | null; 
}

export type UserWithoutLock = Omit<User, "lockedBy" | "lockReason" | "lockedAt">

export type DraftUserWithoutLock = Omit<User, "id" | "lockedBy" | "lockReason" | "lockedAt" | "expiryDate">

export interface UserEmailType {
    email: string
}

export interface UserBlockType {
    email: string,
    lockreason: string
}

export interface UserArchiveType {
    email: string,
    reason: string
    password: string
}

export const adapter = createEntityAdapter<User>({
    selectId: (user) => user.id,
})


export type DraftUser = Omit<User, "id">

export interface UsersState {
    items: EntityState<User> & { status: AppDataState };
    view: "list" | "form";
    itemId: UserId | undefined;
    formData: Nullable<DraftUser> | undefined;
}

const initialState: UsersState = {
    items: adapter.getInitialState({
        status: {
            type: "empty",
        }
    }),
    view: "list",
    itemId: undefined,
    formData: undefined,
}


export const loadUsers = createAsyncThunk<User[], void, AppThunkAPIType>("users/load", async (_, { dispatch, rejectWithValue, requestId, getState }) => {
    const { status } = getState().users.items

    switch (status.type) {
        case "loading":
            if (status.requestId === requestId) {
                const result = await dispatch(getWithAuth({ url: "api/users" }))
                const { payload } = result
                if (getWithAuth.fulfilled.match(result)) {
                    return payload as User[]
                } else {
                    return rejectWithValue(payload ?? { kind: 'unknown' })
                }
            } else {
                return []
            }
        default:
            return []
    }
})

export const loadArchivedUsers = createAsyncThunk<User[], void, AppThunkAPIType>(
    "archived-users/load",
    async (_, { dispatch, rejectWithValue, requestId, getState }) => {
        const result = await dispatch(getWithAuth({ url: "api/users/archived" }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as User[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const loadUsersForCompany = createAsyncThunk<User[], AppId, AppThunkAPIType>(
    "users-for-company/load",
    async (companyId, { dispatch, rejectWithValue }) => {

        const result = await dispatch(getWithAuth({ url: `api/users/for-company?companyId=${companyId}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as User[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const loadUser = createAsyncThunk<User, AppId, AppThunkAPIType>(
    "user/load",
    async (id, { dispatch, rejectWithValue, requestId, getState }) => {
        const result = await dispatch(getWithAuth({ url: `api/users/${id}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as User
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createUser = createAsyncThunk<User, DraftUserWithoutLock, AppThunkAPIType>(
    "users/create", 
    async (user: DraftUserWithoutLock, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/users/create",
            payload: user,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as User
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateUser = createAsyncThunk<User, UserWithoutLock, AppThunkAPIType>(
    "users/update", 
    async (user: UserWithoutLock, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: "api/users/update",
            payload: user,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as User
            
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const unlockUser = createAsyncThunk<User, UserEmailType, AppThunkAPIType>(
    "users/unlock", 
    async (userEmail: UserEmailType, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/users/unlock-user",
            payload: userEmail,
        }))
       
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as User;
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const blockUser = createAsyncThunk<User, UserBlockType, AppThunkAPIType>(
    "users/block-user", 
    async ( userBlock: UserBlockType, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/users/block-user",
            payload: userBlock,
        }))
       
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as User;
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const archiveUser = createAsyncThunk<User, UserArchiveType, AppThunkAPIType>(
    "users/archive-user", 
    async (userArchive: UserArchiveType, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/users/archive-user",
            payload: userArchive,
        }))
       
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as User;
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

//! Tutaj dopisałem Func, bo deleteUser istnieje - do poprawki

export const deleteUserFunc = createAsyncThunk<User, UserEmailType, AppThunkAPIType>(
    "users/delete-user", 
    async ( userEmail: UserEmailType, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/users/delete-user",
            payload: userEmail,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as User;
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const resetUserPassword = createAsyncThunk<void, UserEmailType, AppThunkAPIType>(
    "users/reset-user-password", 
    async (userEmail: UserEmailType, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/users/reset-user-password",
            payload: userEmail,
        }))
       
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return;
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const sendUserInvitation = createAsyncThunk<AppId, UserEmailType, AppThunkAPIType>(
    "users/sendUserInvitation", 
    async (userEmail: UserEmailType, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/users/send-invitation",
            payload: userEmail
        }))
       
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return "";
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)


export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createUser.fulfilled, (state, action) => {
            state.items = adapter.addOne(state.items, action.payload)
        });
        builder.addCase(deleteUserFunc.fulfilled, (state, action) => {
            const { id } = action.payload;
            state.items = adapter.removeOne(state.items, id)
        });
        builder.addCase(archiveUser.fulfilled, (state, action) => {
            const { id } = action.payload;
            state.items = adapter.removeOne(state.items, id)
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            const { id } = action.payload;
            const changes = action.payload as DraftUser;
            state.items = adapter.updateOne(state.items, {
                id,
                changes,
            });
        })

        builder.addCase(unlockUser.fulfilled, (state, action) => {
            const { id } = action.payload;
            console.log(id)
            const changes = action.payload as DraftUser;
            state.items = adapter.updateOne(state.items, {
                id,
                changes,
            });
        })

        builder.addCase(blockUser.fulfilled, (state, action) => {
            const { id } = action.payload;
            const changes = action.payload as DraftUser;
            state.items = adapter.updateOne(state.items, {
                id,
                changes,
            });
        })
        builder.addCase(loadUsers.pending, (state, action) => {
            const { status } = state.items
            if (status.type === "empty") {
                state.items.status = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadUsers.fulfilled, (state, action) => {
            const { status } = state.items
            if (status.type === "loading" && status.requestId === action.meta.requestId) {
                adapter.setAll(state.items, action.payload)
                state.items.status = {
                    type: "loaded",
                }
            }
        })
        builder.addCase(loadUsers.rejected, (state, action) => {
            const { status } = state.items
            if (status.type === "loading" && status.requestId === action.meta.requestId) {
                state.items.status = { 
                    type: "error",
                    error: action.payload ?? unknownError()
                }
            }
        })
        builder.addCase(logout.fulfilled, (state, action) => {
            adapter.removeAll(state.items)
            state.items.status = {
                type: "empty",
            }
        })
    },
})

export const selectUsers = (state: RootState) => state.users

export const selectUserByName = (state: RootState, userName: string) => selectAllUsers(state).find(i => i.userName === userName)

export const { 
    selectAll: selectAllUsers, 
    selectById: selectUserById 
} = 
    adapter.getSelectors<RootState>((state) => state.users.items)


export default usersSlice.reducer
