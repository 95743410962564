import React from "react";
import { Box, Chip } from "@mui/material";
import { DocumentStatusLabel } from "../../documents/DocumentStatusLabel";
import { DocumentStatus } from "../../documents/documentsApi";

interface StatusIndicatorProps {
    status: DocumentStatus;
    color?: string; // Pozwala na przekazanie niestandardowego koloru
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status, color }) => {
    const defaultColorMap: { [key in DocumentStatus]?: string } = {
        N: '#1D3557',
        P: '#1D3557',
    };

    const statusColor = color || defaultColorMap[status] || '#9e9e9e'; // Szary jako kolor domyślny

    // Pobranie tekstowej wartości statusu z komponentu DocumentStatusLabel
    const statusLabel = <DocumentStatusLabel status={status} />;

    return (
        <Box sx={{ textAlign: 'center', minWidth: '100px' }}>
            <Chip
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span>Status:&nbsp;</span>
                        <span style={{ color: statusColor, fontWeight: 'bold' }}>
                            {statusLabel}
                        </span>
                    </Box>
                }
                sx={{
                    color: 'black', // Standardowy kolor tekstu "Status"
                    fontWeight: 'normal',
                    minWidth: '120px',
                    padding: '0 8px',
                }}
            />
        </Box>
    );
};

export default StatusIndicator;
