import { Localized, useLocalization } from '@fluent/react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useMemo, useState } from 'react'
import { Box, ButtonGroup } from '@mui/material'
import { Skeleton } from '@mui/material'
import { LoadDeviceTypes, LoadURSs, LoadUsers } from '../../app/AppDataLoader'
import { selectAllStructure, selectStructureAndChildren } from '../device/structureRelationshipSlice'
import { loadUrsList, selectAllUrs } from '../urs/ursListSlice'
import { DataGrid, GridColDef, } from '@mui/x-data-grid'
import { plPL, enUS } from '@mui/x-data-grid/locales'
import { selectSettings } from '../settings/settingsSlice'
import { UrsHeader } from './UrsSlice'
import { useNavigate } from 'react-router-dom'
import { QuickSearchToolbar } from '../../app/QuickSearchToolbar'
import { getStructureName } from '../device/Structure'
import { DocumentFilterPanel } from '../documents/utils/DocumentFilterPanel'
import { selectModule } from '../modules/moduleSlice'
import { useLocalStorage } from '../../useLocalStorage'
import { URSListFilters } from '../documents/utils/types'
import { defURSFilters, filtersToQueryString } from '../documents/utils/helper'
import { selectContextStructure } from '../structure/contextStructureSlice'
import { LoadingContainer } from '../../app/LoadingContainer'


export const UrsList = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch();

    const currentModule = useAppSelector(state => selectModule(state).currentModule) 

    const [filteredUrs, setFilteredUrs] = useState<UrsHeader[]>([])
    const [useFilters, setUseFilters] = useState<boolean>(false);

    const [filters, setFilters] = useLocalStorage<URSListFilters>("URSListFilters", defURSFilters)
    const f = useMemo(() => filtersToQueryString(filters), [filters])

    const allURSs =
        useAppSelector(state => selectAllUrs(state))
    const { data: structure } = useAppSelector(selectContextStructure)
    const relationship = useAppSelector(state => {
        if (structure && structure.mode === 'manual') {
            return selectStructureAndChildren(state, structure.structId)
        } else {
            return selectAllStructure(state)
        }
    })
    const URSs = useMemo(() => {
        if(useFilters) {
            return filteredUrs.filter(({ deviceTypeId }) => relationship.has(deviceTypeId));
        } else {
            return allURSs.filter(({ deviceTypeId }) => relationship.has(deviceTypeId));
        }
    }, [allURSs, relationship, filteredUrs])

    const columns: GridColDef<UrsHeader, any, any>[] = [
        {
            field: 'ursNo',
            headerName: l10n.getString("document-number", null, "_Document number"),
            minWidth: 150
        },
        {
            field: 'version',
            headerName: l10n.getString("document-version", null, "_Document version"),
            valueGetter: (_, row) => {
                return row.version + 1
            }
        },
        {
            field: 'title',
            headerName: l10n.getString("document-title", null, "_Document title"),
            minWidth: 300,
            flex: 1
        },
        {
            field: 'deviceTypeId',
            headerName: l10n.getString("structure", null, "_Structure"),
            width: 300,
            valueGetter: (_, row) => {
                return getStructureName(row.deviceTypeId)
            }
        },
        {
            field: 'createdByFullName',
            headerName: l10n.getString("document-created-by", null, "_Author"),
            width: 200
        },
        {
            field: 'createDate',
            type: 'date',    
            headerName: l10n.getString("document-created-date", null, "_Create date"),
            width: 200,
            valueGetter: (_, row) => new Date(row.createDate),
        },
        {
            field: 'status',
            headerName: l10n.getString("document-status", null, "_Status"),
            width: 150,
            valueGetter: (_, row) => {
                var status = row.status.toLowerCase();
                const description = l10n.getString("urs-status-" + status, null, "");
                return description;
            }
        },
        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 75 }}
                            component={Link}
                            to={`/urs/edit/${params.row.id}?structureId=${params.row.deviceTypeId}`}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    const addURL = "/urs/create"

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const navigate = useNavigate()

    const handleRowDoubleClick = (params) => {
        navigate(`/urs/edit/${params.row.id}?structureId=${params.row.deviceTypeId}`)
    };

    const reload = () => {
        if (currentModule !== null) {
            dispatch(loadUrsList({ moduleId: currentModule.sysModuleId, filter: f }))
        }
    }

    return <>
        <DocumentFilterPanel filters={filters} onFilterChange={setFilters} reload={reload}/>
        <LoadURSs component={<LoadingContainer/>} filter={f}>
            <LoadUsers component={<LoadingContainer/>}>
                <LoadDeviceTypes component={<Skeleton variant="rectangular" />}>
                    <TableContainer component={Paper}>
                        <DataGrid localeText={getCustomLocaleText()}
                            style={{ minHeight: 100, height: 'calc(100vh - 290px)' }}
                            rows={URSs} columns={columns}
                            initialState={{
                                sorting: { sortModel: [{ field: 'createDate', sort: 'desc' }] }
                            }}
                            slots={{ toolbar: QuickSearchToolbar }}
                            onRowDoubleClick={handleRowDoubleClick}
                        />
                    </TableContainer>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <Fab sx={{
                            marginTop: 2,
                        }} component={Link} to={addURL} color="secondary">
                            <AddIcon />
                        </Fab>
                    </Box>
                </LoadDeviceTypes>
            </LoadUsers>
        </LoadURSs>
    </>
}
