import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { getWithAuth, postWithAuth, putWithAuth } from "../../http";

export type CompanyLicense = "BASIC" | "STANDARD" | "PREMIUM" | "CUSTOM" | "DEMO";

export interface Company {
    id: AppId,
    name: string,
    shortName: string,
    isProducer: boolean,
    isCustomer: boolean,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zipcode: string,
    country: string,
    contactPerson: string,
    email: string,
    phone: string,
    mobile: string,
    usersCount: number,
    userLimit: number,
    license: CompanyLicense,
    areas: Array<{areaId: AppId }>
}

const adapter = createEntityAdapter<Company>({
    selectId: (company) => company.id,
})

export type DraftCompany = Omit<Company, "id" | "usersCount">

export type CompaniesState = EntityState<Company> & { loaded: boolean }

const initialState: CompaniesState = adapter.getInitialState({ loaded: false })

export const loadCompanies = createAsyncThunk<Company[], void, AppThunkAPIType>("companies/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/companies"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as Company[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const loadCompany = createAsyncThunk<Company, string, AppThunkAPIType>("companies/load-by-id", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: `api/companies/details/${id}`}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as Company
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const createCompany = createAsyncThunk<Company, DraftCompany, AppThunkAPIType>
    ("companies/create", async (company: DraftCompany, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/companies",
            payload: company,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Company
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createCompanyQuery = createAsyncThunk<Company, { company: DraftCompany, companyId: string }, AppThunkAPIType>
    ("companies/create", async ({ company, companyId }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/companies?companyId=${companyId}`,
            payload: company,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Company
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateCompany = createAsyncThunk<Company, Company, AppThunkAPIType>(
    "companies/update", 
    async (company: Company, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/companies/${company.id}`,
            payload: company,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return company as Company
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const deleteCompany = createAsyncThunk("companies/delete", async (id: AppId) => {
    return id;
})

export const companiesSlice = createSlice({
    name: "companies",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createCompany.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        });
        builder.addCase(deleteCompany.fulfilled, (state, action) => {
            state = adapter.removeOne(state, action.payload)
        });
        builder.addCase(updateCompany.fulfilled, (state, action) => {
            const { id } = action.payload;
            const changes = action.payload as DraftCompany;
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })
        builder.addCase(loadCompanies.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.loaded = true
        });
    },
})

export const selectCompanies = (state: RootState): CompaniesState => state.companies

export const 
    { selectAll: selectAllCompanies
    , selectById: selectCompanyById 
    } = adapter.getSelectors<RootState>(selectCompanies)

export default companiesSlice.reducer
