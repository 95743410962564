import { useCallback, useEffect, useMemo, useState } from "react"
import { unwrapResult } from "@reduxjs/toolkit"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Localized, useLocalization } from "@fluent/react"
import { Box, Button, ButtonGroup, Fab, Paper, TableContainer } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { ErrorContainer } from "../../../app/ErrorContainer"
import { selectSettings } from "../../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { loadAllRiskAnalysisDocuments, RiskAnalysisHeader } from "./riskAnalysisApi"
import { DocumentStatusLabel } from "../DocumentStatusLabel"
import { QuickSearchToolbar } from "../../../app/QuickSearchToolbar"
import { DocumentFilterPanel } from "../utils/DocumentFilterPanel"
import { If } from "../../../app/If"
import { URSListFilters } from "../utils/types"
import { useLocalStorage } from "../../../useLocalStorage"
import { defURSFilters, filtersToQueryString } from "../utils/helper"
import { getStructureName } from "../../device/Structure"

export const RiskAnalysisList = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { locale } = useAppSelector(selectSettings);
    const [documents, setDocuments] = useState<RiskAnalysisHeader[]>([]);
    const { l10n } = useLocalization()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);

    const location = useLocation();

    const [filters, setFilters] = useLocalStorage<URSListFilters>("RiskAnalysisListFilters", defURSFilters)
    const f = useMemo(() => filtersToQueryString(filters), [filters])

    const isSupplierRiskAnalysis = location.pathname.includes('supplier-risk-analysis');

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadAllRiskAnalysisDocuments({ filter: f }))
        .then(unwrapResult)
        .then(setDocuments)
        .catch((error) => { console.error(error); setIsError(true); setError(error); })
        .finally(() => setIsLoading(false))

    }, [])

    const handleGoToClick = (id) => {
        if (!isSupplierRiskAnalysis) {
            navigate(`/new-risk-analysis/edit/${id}`)
        }
        else {
            navigate(`/supplier-risk-analysis/edit/${id}`)
        }
    };


    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const columns: GridColDef[] =
        [
            {
                field: 'no',
                flex: 1,
                headerName: l10n.getString('', null, 'Numer'),

            },

            {
                field: 'createdByFullName',
                headerName: l10n.getString('meeting-minutes-author', null, 'Autor'),
                width: 400
            },
            {
                field: 'createDate',
                type: 'date',
                width: 150,
                headerName: l10n.getString('meeting-minutes-create-date', null, 'Data utworzenia'),
                valueGetter: (_, row) => new Date(row.createDate),
            },
            {
                field: 'status',
                headerName: l10n.getString('meeting-minutes-status', null, 'Status'),
                width: 100,
                renderCell: (params) => {
                    return (
                        <div>
                            <DocumentStatusLabel status={params.row.status} />
                        </div>
                    )
                }
            },
            {
                field: 'actions', type: 'actions', width: 400,
                renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            <Button onClick={() => handleGoToClick(params.id)} color="secondary" variant="outlined">
                                <Localized id="go-to">
                                    <span>Przejdz</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        ];

    const reload = useCallback(() => {
        setIsLoading(true)
        dispatch(loadAllRiskAnalysisDocuments({ filter: f }))
        .then(unwrapResult)
        .then(setDocuments)
        .catch((error) => { console.error(error); setIsError(true); setError(error); })
        .finally(() => setIsLoading(false))
    }, [f])


    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    return <>
        <DocumentFilterPanel filters={filters} onFilterChange={setFilters} reload={reload} />

        <If condition={!isLoading} otherwise={<LoadingContainer />}>
            <TableContainer component={Paper}>
                <DataGrid
                    style={{ minHeight: 100, height: 'calc(100vh - 250px)' }}
                    localeText={getCustomLocaleText()}
                    rows={documents}
                    columns={columns}
                    slots={{ toolbar: QuickSearchToolbar }}
                    onRowDoubleClick={({ row }) => handleGoToClick(row.id)}
                />
                <Box sx={{
                    display: 'flex',
                }}>
                    <Fab sx={{
                        margin: 1,
                    }} component={Link} to={`create`} color="secondary" size="small" >
                        <AddIcon />
                    </Fab>
                </Box>
            </TableContainer>
        </If>

    </>
}
