import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppThunkAPIType } from "../../../app/appTypes"
import { postWithAuth, putWithAuth } from "../../../http"
import { Urs } from "../../urs/UrsSlice"

export interface IUrs {
    description: string
    structureId: string
    title: string
    ursNumber: string
    language: string
    relatedCompanyName: string
    relatedDocumentId: string
}

export interface IUrsBody {
    types: ISection[]
    metadata: string | null
}

export interface ISection {
    name: string
    groups: IGroup[]
    requirements: IReq[]
}

export interface IGroup {
    name: string
    requirements: IReq[]
}

export interface IReq {
    code: string
    name: string
    criticality: string
    jsonContent: string | null
    searcheableContent: string | null
}

export const imporUrsHeader = createAsyncThunk<Urs, IUrs, AppThunkAPIType>
    ("import/create-urs-head", async (data, { dispatch, rejectWithValue }) => {

        const result = await dispatch(postWithAuth({
            url: "api/Urs/import/start-import",
            payload: data,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as Urs
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }

})

export const importUrsData = createAsyncThunk<Urs, { id: string, body: IUrsBody }, AppThunkAPIType>
    ("import/save-urs-body", async ({ id, body }, { dispatch, rejectWithValue }) => {

    const result = await dispatch(putWithAuth({
        url: `api/Urs/import/${id}/finalize-import`,
        payload: body,
    }))
    const { payload } = result

    if (putWithAuth.fulfilled.match(result)) {
        return payload as Urs
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }

})
