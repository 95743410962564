import { createAsyncThunk } from "@reduxjs/toolkit"
import { getWithAuth, postWithAuth } from "../../../http";
import { AppThunkAPIType } from "../../../app/appTypes";
import { CreateSystemDocumentTemplatePayload, SystemDocumentTemplate, SystemDocumentTemplateHeader } from "./systemTypes";


export const loadSystemDocumentTemplates = createAsyncThunk<SystemDocumentTemplateHeader[], void, AppThunkAPIType>(
    "system-document-templates/load-all", async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/system-document-templates` }))
        const { payload } = result

        if (getWithAuth.fulfilled.match(result)) {
            return payload as SystemDocumentTemplateHeader[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })


export const loadSystemDocumentTemplate = createAsyncThunk<SystemDocumentTemplate, string, AppThunkAPIType>(
    "system-document-templates/load-one", async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/system-document-templates/${id}`,
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as SystemDocumentTemplate
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })


export const createSystemDocumentTemplate = createAsyncThunk<SystemDocumentTemplateHeader, CreateSystemDocumentTemplatePayload, AppThunkAPIType>(
    "system-document-templates/create",
    async (template: CreateSystemDocumentTemplatePayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/system-document-templates",
            payload: template,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as SystemDocumentTemplateHeader
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)
