import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import userReducer from '../features/user/userSlice'
import deviceReducer from '../features/device/deviceSlice'
import settingsReducer from '../features/settings/settingsSlice'
import companiesReducer from '../features/company/companiesSlice'
import companiesFormReducer from '../features/company/companiesFormSlice'
import usersReducer from '../features/users/usersSlice'
import notificationsReducer from '../features/notifications/notificationsSlice'
import validationsReducer from '../features/validations/validationsSlice'
import deviceTypesReducer from '../features/device/deviceTypesSlice'
import processesReducer from '../features/process/processSlice'
import activitiesReducer from '../features/validations/activitiesSlice'
import myTasksReducer from '../features/tasks/myTasksSlice'
import processTestsReducer from '../features/process/processTestsSlice'
import processStepsReducer from '../features/process/processTestStepSlice'
import processStageRemarkReducer from '../features/process/processStageRemarkSlice'
import ursReducer from '../features/urs/UrsSlice'
import requirementsReducer from '../features/requirements/RequirementsDictSlice'
import ursTemplatesReducer from '../features/urstemplates/ursTemplatesSlice'
import requirementsTypesReducer from '../features/requirements/RequirementsTypesSlice'
import riskAnalysisReducer from '../features/riskAnalysis/riskAnalysisSlice'
import areasReducer from '../features/areas/areaSlice'
import ursOffersReducer from '../features/ursOffer/ursOfferSlice'
import structureReducer from '../features/device/structureSlice'
import moduleReducer from '../features/modules/moduleSlice'
import structureRelationshipReducer from '../features/device/structureRelationshipSlice'
import requirementTest from '../features/requirements/requirementTestSlice'
import aiQuestionsReducer from '../features/ai/aiQuestionsSlice'
import suppliersReducer from '../features/suppliers/suppliersSlice'
import ursCommentsReducer from '../features/urs/URSCommentSlice'
import ursAttachementsReducer from '../features/urs/ursAttachementsSlice'
import mailTemplatesReducer from '../features/mailing/mailTemplatesSlice'
import ursListReducer from '../features/urs/ursListSlice'
import mailMappingReducer from '../features/systemSettings/system/mailMappingsSlice'
import supplierDevicesReducer from '../features/supplierdevices/supplierDeviceSlice'
import supplierDeviceDetailsReducer from '../features/supplierdevices/supplierDeviceDetailsSlice'
import materialCardReducer from '../features/project/materialCards/materialCardSlice'
import materialCardItemReducer from '../features/project/materialCards/materialCardItemSlice'
import projectsReducer from '../features/project/projectSlice'
import externalUrsOfferReducer from '../features/externalOffer/externalOfferSlice'
import helpArticlesReducer from '../features/systemSettings/system/helpArticlesSlice'
import dialogReducer from '../features/dialogs/dialogSlice'
import businessCaseReducer from '../features/businessCase/businessCaseSlice'
import chcReducer from '../features/chc/chcSlice'
import specReducer from '../features/specs/specSlice'
import contextStructureReducer from '../features/structure/contextStructureSlice'
import importedUrsReducer from '../features/ursOffer/importedUrsSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    devices: deviceReducer,
    settings: settingsReducer,
    companies: companiesReducer,
    companiesForm: companiesFormReducer,
    users: usersReducer,
    notifications: notificationsReducer,
    validations: validationsReducer,
    deviceTypes: deviceTypesReducer,
    processes: processesReducer,
    activities: activitiesReducer,
    myTasks: myTasksReducer,
    processTests: processTestsReducer,
    processTestSteps: processStepsReducer,
    processStageRemarks: processStageRemarkReducer,
    urs: ursReducer,
    requirements: requirementsReducer,
    requirementsTypes: requirementsTypesReducer,
    ursTemplates: ursTemplatesReducer,
    riskAnalysis: riskAnalysisReducer,
    areas: areasReducer,
    ursOffers: ursOffersReducer,
    structure: structureReducer,
    module: moduleReducer,
    structureRelationship: structureRelationshipReducer,
    requirementTest: requirementTest,
    aiquestions: aiQuestionsReducer,
    suppliers: suppliersReducer,
    ursComments: ursCommentsReducer,
    ursAttachements: ursAttachementsReducer,
    mailTemplates: mailTemplatesReducer,
    ursList: ursListReducer,
    mailMappings: mailMappingReducer,
    supplierDevices: supplierDevicesReducer,
    supplierDeviceDetails: supplierDeviceDetailsReducer,
    materialCards: materialCardReducer,
    materialCardItems: materialCardItemReducer,
    projects: projectsReducer,
    externalUrsOffers: externalUrsOfferReducer,
    helpArticles: helpArticlesReducer,
    dialog: dialogReducer,
    businessCases: businessCaseReducer,
    chc: chcReducer,
    specs: specReducer,
    contextStructure: contextStructureReducer,
    importedUrs: importedUrsReducer,
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
