import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../../app/appTypes";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../../http";
import { ManagementRoleType } from "../../utils/managedObject";

export type CompanySettingType
    = "GENERAL"
    | "SECURITY"

export type CompanySettingCode
    = "DEFAULT_LANGUAGE"
    | "DEFAULT_TIMEZONE"

    | "AUTH_2FA_EMAIL_CODE"

    | "PASS_CHECK_MIN_LENGTH"
    | "PASS_MIN_LENGTH"
    | "PASS_REQ_BIG_LETTER"
    | "PASS_REQ_SPECIAL_CHAR"
    | "PASS_REQ_DIGIT"
    | "PASS_CHECK_USAGE"
    | "PASS_EXPIRY_DAYS"
    | "MATCHING_MIN_LEVENSHTEIN_SIMILARITY"
    | "MATCHING_MIN_SBERT_SIMILARITY"

export type SupplierEventType
    = "NEW_URS_REQUEST"

export type WorkflowRoleType
    = "OWNER"
    | "PROCESS_MANAGER"
    | "COLLABORATOR"
    | "APPROVER"

export const PASSWORD_RULES = {
    MIN_LENGTH: 8,
    MAX_LENGTH: 50,

    MIN_USAGE: 1,
    MAX_USAGE: 10,

    MIN_DAYS: 1,
    MAX_DAYS: 360
};

export type NotificationMethodType
    = "EMAIL"

export interface CompanySetting {
    id: AppId
    companyId: number
    type: CompanySettingType
    code: CompanySettingCode
    value: string
    isActive: boolean
    isFixed: boolean
    modifiedBy: string
    modifyDate: Date
}

export interface SecuredCompanySettingUpdate {
    password: string
    settings: CompanySetting[]
}

export interface CompanySupplierAssigmentSetting {
    id: AppId;
    user: string;
    companyId: number;
    event: SupplierEventType
    role: ManagementRoleType;
    isActive: boolean
    notifyUser: boolean
    notificationMethod: boolean
    modifiedBy: string
    modifyDate: Date
}

export interface CompanySupplierAssigmentSettingPayload {
    id?: AppId;
    user: string;
    event: SupplierEventType;
    role: ManagementRoleType;
    notifyUser: boolean;
}

export const loadCompanySettings = createAsyncThunk<CompanySetting[], void, AppThunkAPIType>("/company-settings/load-main-settings",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/company-settings` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as CompanySetting[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const updateCompanySettings = createAsyncThunk<CompanySetting[], SecuredCompanySettingUpdate, AppThunkAPIType>("/company-settings/",
    async (data, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ url: `api/company-settings/`, payload: data }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as CompanySetting[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadSupplierAssigmentsSettings = createAsyncThunk<CompanySupplierAssigmentSetting[], void, AppThunkAPIType>("/company-settings/supplier-assigments",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/company-settings/supplier-assigments` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as CompanySupplierAssigmentSetting[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const createSupplierAssigmentsSettings = createAsyncThunk<CompanySupplierAssigmentSetting, CompanySupplierAssigmentSettingPayload, AppThunkAPIType>("/company-settings/update-supplier-assigments",
    async (companySupplierAssigmentSetting, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ url: `api/company-settings/supplier-assigments`, payload: companySupplierAssigmentSetting }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as CompanySupplierAssigmentSetting
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const updateSupplierAssigmentsSettings = createAsyncThunk<CompanySupplierAssigmentSetting, CompanySupplierAssigmentSettingPayload, AppThunkAPIType>("/company-settings/update-supplier-assigments",
    async (companySupplierAssigmentSetting, { dispatch, rejectWithValue }) => {
        const { id, ...data } = companySupplierAssigmentSetting;
        const result = await dispatch(putWithAuth({ url: `api/company-settings/supplier-assigments/${id}`, payload: data }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as CompanySupplierAssigmentSetting
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const deleteSupplierAssigmentsSettings = createAsyncThunk<CompanySupplierAssigmentSetting, string, AppThunkAPIType>("/company-settings/update-supplier-assigments",
    async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/company-settings/supplier-assigments/${id}` }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return payload as CompanySupplierAssigmentSetting
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })