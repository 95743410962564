import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppId } from "../../app/appTypes";
import { RootState } from "../../app/store";

export type ContextStructureMode = 'auto' | 'manual'

export interface ContextStructure {
    structId: AppId
    mode: ContextStructureMode
}

export interface ContextStructureState {
    data: ContextStructure | undefined
    prevData: ContextStructure | undefined
}

const initialState: ContextStructureState  = {
    data: undefined,
    prevData : undefined,
}

export const moduleSlice = createSlice({
    name: "contextStructure",
    initialState,
    reducers: {
        setStructureManually: (state, action: PayloadAction<AppId>) => {
            state.data = {
                structId: action.payload,
                mode: 'manual',
            }
            state.prevData = undefined
        },
        setStructureAuto: (state, action: PayloadAction<AppId>) => {
            if (state.data && state.data.mode === 'manual') {
                state.prevData = state.data
            }
            state.data = {
                structId: action.payload,
                mode: 'auto',
            }
        },
        clearContextStructure: (state ) => {
            state.data = undefined
            if (state.prevData) {
                state.data = state.prevData
                state.prevData = undefined
            }
        }
    },
})

export const selectContextStructure = (state: RootState): ContextStructureState => state.contextStructure

export const { setStructureManually, setStructureAuto, clearContextStructure } = moduleSlice.actions

export default moduleSlice.reducer
