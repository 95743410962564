import { Card, CardContent, CardHeader, Divider, TextField } from "@mui/material";
import { List, ListItem, ListItemText, Switch, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { CompanySetting, PASSWORD_RULES, updateCompanySettings } from "../companySettingsAPI";
import { PasswordConfirmationDialog } from "../dialogs/PasswordConfirmationDialog";
import { showError, showSuccess } from "../../../notifications/notificationsSlice";
import { useAppDispatch } from "../../../../app/hooks";
import { CardActionButtons } from "./CardActionButtons";
import { Localized } from "@fluent/react";

interface CompanyPasswordSettingCardProps {
    companySettings: CompanySetting[];
    onUpdate: (updatedSettings: CompanySetting[]) => void;
}


export const CompanyPasswordSettingCard: React.FC<CompanyPasswordSettingCardProps> = ({ companySettings, onUpdate }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [serverError, setServerError] = useState("")

    const [dialogOpen, setDialogOpen] = useState(false);
    const dispatch = useAppDispatch()

    const passwordMinLengthOrg = companySettings.find(setting => setting.code === "PASS_MIN_LENGTH");
    const passwordBigLetterOrg = companySettings.find(setting => setting.code === "PASS_REQ_BIG_LETTER");
    const passwordSpecialCharOrg = companySettings.find(setting => setting.code === "PASS_REQ_SPECIAL_CHAR");
    const passwordCheckUsageOrg = companySettings.find(setting => setting.code === "PASS_CHECK_USAGE");
    const passwordExpiryDaysOrg = companySettings.find(setting => setting.code === "PASS_EXPIRY_DAYS");
    const passwordReqDigitOrg = companySettings.find(setting => setting.code === "PASS_REQ_DIGIT");

    const [passwordMinLength, setPasswordCheckMinLength] = useState<CompanySetting | undefined>(passwordMinLengthOrg);
    const [passwordBigLetter, setPasswordBigLetter] = useState<CompanySetting | undefined>(passwordBigLetterOrg);
    const [passwordSpecialChar, setPasswordSpecialChar] = useState<CompanySetting | undefined>(passwordSpecialCharOrg);
    const [passwordCheckUsage, setPasswordCheckUsage] = useState<CompanySetting | undefined>(passwordCheckUsageOrg);
    const [passwordExpiryDays, setPasswordExpiryDays] = useState<CompanySetting | undefined>(passwordExpiryDaysOrg);
    const [passwordReqDigit, setPasswordReqDigit] = useState<CompanySetting | undefined>(passwordReqDigitOrg);

    const passwordMinLengthIsActive = () => {
        setPasswordCheckMinLength(prev => prev ? { ...prev, isActive: !prev.isActive } : undefined);
    };

    const passwordDigitRequired = () => {
        setPasswordReqDigit(prev => prev ? { ...prev, isActive: !prev.isActive } : undefined);
    };

    const passwordBigLetterRequired = () => {
        setPasswordBigLetter(prev => prev ? { ...prev, isActive: !prev.isActive } : undefined);
    };


    const passwordSpecialCharRequired = () => {
        setPasswordSpecialChar(prev => prev ? { ...prev, isActive: !prev.isActive } : undefined);
    };

    const passwordExpiryDayRequired = () => {
        setPasswordExpiryDays(prev => prev ? { ...prev, isActive: !prev.isActive } : undefined);
    };

    const passwordCheckUsageRequired = () => {
        setPasswordCheckUsage(prev => prev ? { ...prev, isActive: !prev.isActive } : undefined);
    };

    const handlePasswordMinLengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(e.target.value, 10) || 0;

        setPasswordCheckMinLength(prev => prev ? { ...prev, value: newValue.toString() } : undefined);
    };

    const handlePasswordMinLengthBlur = () => {
        const safePrev = passwordMinLength ?? { value: PASSWORD_RULES.MIN_LENGTH.toString() };
        const numericValue = parseInt(safePrev.value, 10) || PASSWORD_RULES.MIN_LENGTH;
        const val = Math.min(Math.max(numericValue, PASSWORD_RULES.MIN_LENGTH), PASSWORD_RULES.MAX_LENGTH).toString();

        setPasswordCheckMinLength(prev => prev ? { ...prev, value: val.toString() } : undefined);
    };

    const handlePasswordUsageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(e.target.value, 10) || 0;

        setPasswordCheckUsage(prev => prev ? { ...prev, value: newValue.toString() } : undefined);
    };

    const handlePasswordUsageBlur = () => {
        const safePrev = passwordCheckUsage ?? { value: PASSWORD_RULES.MIN_USAGE.toString() };
        const numericValue = parseInt(safePrev.value, 10) || PASSWORD_RULES.MIN_USAGE;
        const val = Math.min(Math.max(numericValue, PASSWORD_RULES.MIN_USAGE), PASSWORD_RULES.MAX_USAGE).toString();

        setPasswordCheckUsage(prev => prev ? { ...prev, value: val.toString() } : undefined);
    };

    const handlePasswordExpiryDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(e.target.value, 10) || 0;

        setPasswordExpiryDays(prev => prev ? { ...prev, value: newValue.toString() } : undefined);
    };

    const handlePasswordExpiryDaysBlur = () => {
        const safePrev = passwordExpiryDays ?? { value: PASSWORD_RULES.MIN_DAYS.toString() };
        const numericValue = parseInt(safePrev.value, 10) || PASSWORD_RULES.MIN_DAYS;
        const val = Math.min(Math.max(numericValue, PASSWORD_RULES.MIN_DAYS), PASSWORD_RULES.MAX_DAYS).toString();

        setPasswordExpiryDays(prev => prev ? { ...prev, value: val.toString() } : undefined);
    };

    useEffect(() => {
        setPasswordCheckMinLength(passwordMinLengthOrg);
        setPasswordBigLetter(passwordBigLetterOrg);
        setPasswordSpecialChar(passwordSpecialCharOrg);
        setPasswordCheckUsage(passwordCheckUsageOrg);
        setPasswordExpiryDays(passwordExpiryDaysOrg);
        setPasswordReqDigit(passwordReqDigitOrg);
    }, [companySettings, isEditing]);


    const handleSave = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
        setServerError("")
    };

    const handleConfirmSave = async (password: string) => {
        if (password && passwordMinLength && passwordBigLetter && passwordSpecialChar && passwordCheckUsage && passwordExpiryDays && passwordReqDigit) {
            setSaving(true)
            setServerError("")
            try {
                const settingsToUpdate: CompanySetting[] = [
                    passwordMinLength,
                    passwordBigLetter,
                    passwordSpecialChar,
                    passwordCheckUsage,
                    passwordExpiryDays,
                    passwordReqDigit
                ];

                const result =
                    await dispatch(updateCompanySettings({
                        password: password,
                        settings: settingsToUpdate
                    }))

                if (updateCompanySettings.fulfilled.match(result)) {
                    const settings = result.payload as CompanySetting[];
                    onUpdate(settings)
                    setDialogOpen(false);
                    setIsEditing(false);
                    dispatch(showSuccess("saved"))
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    setServerError(errorMsg)
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }


    return (
        <Card sx={{ width: "100%", maxWidth: 800, borderRadius: 2, boxShadow: 3 }}>
            <CardHeader
                title={<Localized id="company-settings-password">
                    <span>_password</span>
                </Localized>}
                sx={{
                    textAlign: "center",
                    bgcolor: "primary.main",
                    color: "white",
                    py: 1,
                    "& .MuiCardHeader-title": { fontSize: "1rem" },
                }}
            />
            <CardContent>
                <List>
                    <ListItem>
                        <ListItemText
                            primary={<Localized id="company-settings-password-desc">
                                <span>_password</span>
                            </Localized>}
                        />
                    </ListItem>
                    <Box sx={{ pl: 2 }}>
                        <ListItem>
                            <ListItemText
                                primary={<Localized id="company-settings-password-verify-length">
                                    <span>_password</span>
                                </Localized>}
                            />
                            <Switch
                                checked={passwordMinLength?.isActive}
                                onChange={passwordMinLengthIsActive}
                                disabled={!isEditing || passwordMinLength?.isFixed}
                            />
                        </ListItem>
                        {passwordMinLength?.isActive && (
                            <ListItem>
                                <TextField
                                    label={<Localized id="company-settings-password-min-length">
                                        <span>_min</span>
                                    </Localized>}
                                    type="number"
                                    value={passwordMinLength.value}
                                    onChange={handlePasswordMinLengthChange}
                                    onBlur={handlePasswordMinLengthBlur}
                                    disabled={!isEditing}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    inputProps={{
                                        min: 8,
                                        max: 50,
                                        step: 1,
                                    }}
                                />
                            </ListItem>
                        )}
                        <ListItem>
                            <ListItemText
                                primary={<Localized id="company-settings-password-digit-required">
                                    <span>_Co najmniej cyfra</span>
                                </Localized>}
                            />
                            <Switch
                                checked={passwordReqDigit?.isActive}
                                onChange={passwordDigitRequired}
                                disabled={!isEditing || passwordReqDigit?.isFixed}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={<Localized id="company-settings-password-big-letter-required">
                                    <span>_Co najmniej cyfra</span>
                                </Localized>}
                            />
                            <Switch
                                checked={passwordBigLetter?.isActive}
                                onChange={passwordBigLetterRequired}
                                disabled={!isEditing || passwordBigLetter?.isFixed}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={<Localized id="company-settings-password-special-case-required">
                                    <span>_znak specjalny</span>
                                </Localized>}
                            />
                            <Switch
                                checked={passwordSpecialChar?.isActive}
                                onChange={passwordSpecialCharRequired}
                                disabled={!isEditing || passwordSpecialChar?.isFixed}
                            />
                        </ListItem>
                    </Box>

                    <ListItem>
                        <ListItemText
                            primary={<Localized id="company-settings-password-unique-required">
                                <span>_Wymagaj unikalnych haseł</span>
                            </Localized>}
                        />
                        <Switch
                            checked={passwordCheckUsage?.isActive || passwordCheckUsage?.isFixed}
                            onChange={passwordCheckUsageRequired}
                            disabled={!isEditing}
                        />
                    </ListItem>

                    {passwordCheckUsage?.isActive && (
                        <ListItem>
                            <TextField
                                label={<Localized id="company-settings-password-unique-number">
                                    <span>_Wymagaj unikalnych haseł</span>
                                </Localized>}
                                type="number"
                                value={passwordCheckUsage.value}
                                onChange={handlePasswordUsageChange}
                                onBlur={handlePasswordUsageBlur}
                                disabled={!isEditing}
                                variant="outlined"
                                fullWidth
                                size="small"
                            />
                        </ListItem>
                    )}

                    <ListItem>
                        <ListItemText
                            primary={<Localized id="company-settings-password-days-expiration">
                                <span>_Okres ważności hasła (dni)</span>
                            </Localized>}
                        />
                        <Switch
                            checked={passwordExpiryDays?.isActive || passwordExpiryDays?.isFixed}
                            onChange={passwordExpiryDayRequired}
                            disabled={!isEditing}
                        />
                    </ListItem>
                    {passwordExpiryDays?.isActive && (
                        <ListItem>
                            <TextField
                                label={<Localized id="days">
                                    <span>_dni</span>
                                </Localized>}
                                type="number"
                                value={passwordExpiryDays.value}
                                onChange={handlePasswordExpiryDaysChange}
                                onBlur={handlePasswordExpiryDaysBlur}
                                disabled={!isEditing}
                                variant="outlined"
                                fullWidth
                                size="small"
                            />
                        </ListItem>
                    )}

                </List>
                <Divider sx={{ width: "100%", mt: 3 }} />
                <CardActionButtons
                    isEditing={isEditing}
                    onSave={handleSave}
                    onCancel={() => setIsEditing(false)}
                    onEdit={() => setIsEditing(true)}
                />
            </CardContent>
            {dialogOpen &&
                <PasswordConfirmationDialog
                    saving={saving}
                    serverError={serverError}
                    onClose={handleClose}
                    onConfirm={handleConfirmSave}
                />
            }
        </Card>
    );
};

