import { ChangeEvent, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "./app/hooks"
import { login, selectUser, verify2FA } from './features/user/userSlice'
import {
    Backdrop,
    Button,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { Localized, useLocalization } from "@fluent/react"
import { showError, showSuccess } from "./features/notifications/notificationsSlice";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useCallback } from 'react';
import { selectSettings, setLocalePersistent, Locale } from './features/settings/settingsSlice';
import { If } from "./app/If";
import { Refresh } from "@mui/icons-material"
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { APIError } from "./app/appTypes";
import { AuthPageLayout } from "./AuthPageLayout";
import { LanguageSelector } from "./LanguageSelector";


const PREFIX = 'Login';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    logo: `${PREFIX}-logo`,
    text: `${PREFIX}-text`,
    backdrop: `${PREFIX}-backdrop`
};

export interface LoginAPIError {
    success: boolean
    token: null | string
    errors: string[]
}

export const LanguageDropdown = () => {
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector(selectSettings);

    const handleLangChange = useCallback((e: SelectChangeEvent) => {
        dispatch(setLocalePersistent(e.target.value as Locale));
    }, [dispatch])

    return <FormControl sx={{ width: '80%' }} >
        <InputLabel id="app-language-label">Language/Język</InputLabel>
        <Select
            labelId="app-language-label"
            id="app-language"
            value={locale}
            onChange={handleLangChange}
            label="Language/Język"
            fullWidth
        >
            <MenuItem value={'pl'}>polski</MenuItem>
            <MenuItem value={'en-US'}>english</MenuItem>
        </Select>
    </FormControl>
}

export const Login = () => {
    const dispatch = useAppDispatch()
    const { tokenState } = useAppSelector(selectUser)
    const { l10n } = useLocalization()
    const location = useLocation()
    const navigate = useNavigate()

    const [step, setStep] = useState<1 | 2>(1);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [code2FA, setCode2FA] = useState("");

    const [mask, setMask] = useState<boolean>(false);

    const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)
    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
    const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => setCode2FA(e.target.value)

    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            handleLoginClick()
        }
    };

    const handleBackToLogin = () => {
        setStep(1);
    };


    const [requires2FA, setRequires2FA] = useState(false);
    const [emailFor2FA, setEmailFor2FA] = useState<string | null>(null);



    const handleLoginClick = async () => {
        if (username && password) {

            setMask(true)


            try {
                const result = await dispatch(login({ username, password })).unwrap()

                if (result.requires2FA) {
                    setRequires2FA(true)
                    setEmailFor2FA(result.emailFor2FA)
                    setStep(2)
                    setMask(false)
                    return
                }
                if (result.passwordExpired) {
                    navigate("/changepsw")
                    return
                }
            }
            catch (error) {
                const payload = error as APIError
                if (payload) {
                    switch (payload.kind) {
                        case "http":
                            if (payload.body) {
                                const apiResponse = JSON.parse(payload.body) as LoginAPIError
                                const [error] = apiResponse.errors
                                dispatch(showError(error ?? "error-login-failed"))
                            } else {
                                dispatch(showError("error-login-failed"))
                            }
                            break
                        case "connection":
                            dispatch(showError("connection-issue"))
                            break
                    }
                } else {
                    dispatch(showError("error"))
                }
                setMask(false)
            }
            finally {
                setMask(false)
            }
        }
    }

    const handleResend2FA = async () => {
        try {
            const response = await fetch("api/AuthManagement/Resend2FA", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: emailFor2FA }),
            });

            if (response.ok) {
                dispatch(showSuccess("Nowy kod 2FA wysłany!"));
            } else {
                const error = await response.json();
                dispatch(showError(error.message || "Błąd podczas wysyłania kodu 2FA."));
            }
        } catch (error) {
            dispatch(showError("Błąd połączenia."));
        }
    };

    const handleVerify2FA = async () => {
        if (code2FA && username) {

            setMask(true)

            const resultAction = await dispatch(verify2FA({ email: username, code: code2FA }))

            if (verify2FA.rejected.match(resultAction)) {
                const { payload } = resultAction
                if (payload) {
                    switch (payload.kind) {
                        case "http":
                            if (payload.body) {
                                const apiResponse = JSON.parse(payload.body) as LoginAPIError
                                const [error] = apiResponse.errors
                                dispatch(showError(error ?? "error-login-failed"))
                            } else {
                                dispatch(showError("error-login-failed"))
                            }
                            break
                        case "connection":
                            dispatch(showError("connection-issue"))
                            break
                    }
                } else {
                    dispatch(showError("error"))
                }
                setMask(false)
            }
        }
    };



    useEffect(() => {
        if (tokenState === 'valid') {
            const redirectDestination = location.state?.prevLocation ?? { pathname: "/" }
            navigate(redirectDestination)
        }
    }, [tokenState, location])

    switch (tokenState) {
        case "valid":
            return null
        default:
            return (

                <AuthPageLayout>
                    <If condition={step === 1}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="subtitle2" fontWeight="bold" textAlign="center">
                                    <Localized id="logging">
                                        <span>Logowanie</span>
                                    </Localized>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    onChange={onUsernameChange}
                                    label={l10n.getString("user", null, "Użytkownik")}
                                    type="email"
                                    autoComplete="email"
                                    aria-required="true"
                                />
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    type="password"
                                    autoComplete="current-password"
                                    aria-required="true"
                                    onChange={onPasswordChange}
                                    onKeyDown={handleEnter}
                                    label={l10n.getString("password", null, "Hasło")}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleLoginClick}
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    variant="contained"
                                >
                                    <Localized id="login">
                                        <span>Login</span>
                                    </Localized>
                                </Button>
                            </Grid>
                            <Grid item textAlign="center">
                                <Link to="/forgotpsw" style={{ fontSize: '0.9rem', color: '#1976d2' }}>
                                    <Localized id="forgot-password-question">
                                        <span>Forgot password?</span>
                                    </Localized>
                                </Link>
                            </Grid>
                            <Grid item textAlign="center">
                                <LanguageSelector />
                            </Grid>
                        </Grid>
                    </If>
                    <If condition={step === 2 && requires2FA}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    <Localized id="two-factor-title">
                                        <span>_Weryfikacja dwuetapowa</span>
                                    </Localized>
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    <Localized id="two-factor-info">
                                        <span>_Kod send {emailFor2FA}</span>
                                    </Localized>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="text"
                                    aria-required="true"
                                    autoComplete="one-time-code"
                                    onChange={onCodeChange}
                                    label={l10n.getString("code", null, "Kod")}
                                    inputProps={{ maxLength: 6 }}
                                    sx={{ letterSpacing: "0.3em", textAlign: "center" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleVerify2FA} color="primary" size="large" variant="contained">
                                    <Localized id="confirm-2fa">
                                        <span>_Potwierdź</span>
                                    </Localized>
                                </Button>
                            </Grid>
                            <Divider style={{ marginLeft: 30, marginRight: 20, marginTop: 20, marginBottom: 20 }} />
                            <Grid container justifyContent="space-between" alignItems="center" >
                                <Grid item>
                                    <Button
                                        onClick={handleBackToLogin}
                                        color="primary"
                                        size="small"
                                        startIcon={<ArrowLeftIcon />}
                                        sx={{ textTransform: "none", marginLeft: 3 }}
                                    >
                                        <Localized id="back-to-login">
                                            <span>_Wstecz</span>
                                        </Localized>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleResend2FA}
                                        color="primary"
                                        size="small"
                                        endIcon={<Refresh />}
                                        sx={{ textTransform: "none", marginRight: 1 }}
                                    >
                                        <Localized id="resend-code">
                                            <span>_Wyślij nowy kod</span>
                                        </Localized>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </If>

                    <Backdrop className={classes.backdrop} open={mask}>
                        <CircularProgress color="inherit"></CircularProgress>
                    </Backdrop>
                </AuthPageLayout>
            );
    }
}