import { useParams, useSearchParams } from "react-router-dom";
import { Localized, useLocalization } from "@fluent/react";
import { useCallback, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useAppDispatch } from "../../../app/hooks";
import { SystemDocumentTemplate } from "../api/systemTypes";
import { APIError } from "../../../app/appTypes";
import { loadSystemDocumentTemplate } from "../api/systemDocumentTemplatesApi";
import { LoadingContainer } from "../../../app/LoadingContainer";
import { ServerErrorMsg } from "../../../app/ServerErrorMsg";
import { Container, Divider, Grid, Tab } from "@mui/material";
import { Attachements } from "../../urs/Attachements";
import SystemTemplateHeader from "./SystemTemplateHeader";
import DocumentDescriptionView from "../../mockups/DocumentDescriptionView";
import SampleContent from "./SampleContent";


type FormTabs = "content" | "details" | "attachments"


export const SystemDocumentTemplateForm = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''

    return <>
        <EditForm templateId={id} />
    </>
}
const EditForm = (props: { templateId: string }) => {
    const { templateId } = props
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const [template, setTemplate] = useState<SystemDocumentTemplate>()
    const [error, setError] = useState<APIError | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)


    //loading data
    useEffect(() => {
        setIsLoading(true);

        dispatch(loadSystemDocumentTemplate(templateId))
            .then(unwrapResult)
            .then(setTemplate)
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false));
    }, [])


    // Tab handling
    const [params, setParams] = useSearchParams()
    const tab = params.get('tab') as FormTabs || 'main'
    const setTab = (tab: FormTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    if (error || !template) {
        return <ServerErrorMsg err={error} />
    }

    return <>
        <Container maxWidth={false} sx={{ padding: "2px", paddingLeft: "2px!important", paddingRight: "2px!important" }}>
            <Grid container >
                <Grid item xs={12}>
                    <SystemTemplateHeader documentTemplate={template} />
                </Grid>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                key={0}
                                value="content"
                                label={<Localized id="">_Content</Localized>} />
                            <Tab
                                key={1}
                                value="details"
                                label={<Localized id="">_Details</Localized>} />
                            <Tab
                                key={2}
                                value="attachments"
                                label={<Localized id="attachments">_attachments</Localized>} />
                        </TabList>
                        <TabPanel value="content" sx={{ padding: "2px", paddingLeft: "2px!important", paddingRight: "2px!important" }}>
                            <SampleContent/>
                        </TabPanel>
                        <TabPanel value="details">
                            <DocumentDescriptionView />;
                        </TabPanel>
                        <TabPanel value="attachments">
                            <Attachements resourceId={template.guid} canDelete={true} canUpload={true} />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
        </Container>
    </>
}