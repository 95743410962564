import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectSettings } from "../../settings/settingsSlice";
import { Alert, Button, ButtonGroup, Card, CardContent, CardHeader, Container, Grid, Paper, Skeleton, Stack, Tab, Typography } from "@mui/material";
import { Localized, useLocalization } from "@fluent/react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { ProjectProcess, ProjectProcessTeamMember, loadProjectProcess } from "./ProjectProcessApiClient";
import { NotesList } from "../Notes/NotesList";
import { Attachements } from "../../urs/Attachements";
import { BottomAppBar } from "../../../app/BottomAppBar";
import { ProjectProcessStatusLabel } from "./ProjectProcessStatusLabel";
import { loadManyBlobs } from "../../urs/ursAttachementsSlice";
import { APIError, AppId } from "../../../app/appTypes";
import { ServerErrorMsg } from "../../../app/ServerErrorMsg";
import { If } from "../../../app/If";
import ShareIcon from '@mui/icons-material/Share';
import { ProcessTeamMemberDialog, ProcessTeamMemberDialogProps } from "./ProcessTeamMemberDialog";
import { InviteProcessTeamMemberDialog, InviteProcessTeamMemberDialogProps } from "./InviteProjectTeamMemberDialog";
import produce from "immer";
import { RolesLabel } from "../Labels/ProjectRolesLabel";
import { DeleteConfirmationDialog, DeleteConfirmationDialogProps } from "../Dialogs/DeleteConfirmation";
import { selectLoggedUser } from "../../user/userSlice";
import { MeetingMinutesList } from "../mettingMinutes/MeetingMinutesList";
import { EditSimpleDescriptionField, EditSimpleDescriptionFieldProps } from "./EditSimpleDescriptionField";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Action } from "../projectSlice";
import { ActionRunner } from "../ActionRunner";
import { AssingDocumentDialog, AssingDocumentDialogProps } from "./AssingDocumentDialog";
import { LoadingContainer } from "../../../app/LoadingContainer";
import { ErrorContainer } from "../../../app/ErrorContainer";

type FormTabs = "documents" | "management" | "notes" | "attachments" | "meetingMinutes"


export const ProjectProcessForm = () => {
  const { id: paramId } = useParams(); const id = paramId ?? ''

  return <>
    <EditForm processId={id} />
  </>
}

type DialogState
  = { tag: 'none' }
  | { tag: 'editTeamMember', props: ProcessTeamMemberDialogProps }
  | { tag: 'inviteTeamMember', props: InviteProcessTeamMemberDialogProps }
  | { tag: 'confirmDelete', props: DeleteConfirmationDialogProps }
  | { tag: 'editSimpleField', props: EditSimpleDescriptionFieldProps }
  | { tag: 'assingDocument', props: AssingDocumentDialogProps }

  
const EditForm = (props: { processId: string }) => {
  const { processId } = props
  const { l10n } = useLocalization()

  const dispatch = useAppDispatch()
  const { id: paramId } = useParams(); const id = paramId ?? ''
  const { locale } = useAppSelector(selectSettings);
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0);
  const [process, setProcess] = useState<ProjectProcess>()
  const [dialog, setDialog] = useState<DialogState>({ tag: 'none' })
  const [teamMemberToEdit, setTeamMemberToEdit] = useState<ProjectProcessTeamMember | undefined>(undefined)
  const [actionToRun, setActionToRun] = useState<Action & { name: string, description: string } | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<APIError | undefined>(undefined)
  const [loadingErrorOccured, setLoadingErrorOccured] = useState<boolean>(false)

  const allTeamMembers = process?.processTeamMembers || []
  const allStages = process?.projectProcessStages || []

  const currentUser = useAppSelector(selectLoggedUser)
  const foundUser = allTeamMembers.find(x => x.user === currentUser);
  const access = foundUser ? foundUser.role !== "O" : false;
  
  const teamMembersSelection = useMemo(() => new Set(allTeamMembers.map(u => u.user)), [allTeamMembers])

  const createdDate = process?.createDate ? new Date(process.createDate).toISOString().split('T')[0] : "";
  
  const isProcessFinished = process?.status === "F";
  const isProcessInProgress = process?.status === 'I';
  const isProcessPaused = process?.status === "H";


  useEffect(() => {
    setIsLoading(true);
    dispatch(loadProjectProcess(processId))
      .then(unwrapResult)
      .then(setProcess)
      .catch((error) => { console.log(error); setLoadingErrorOccured(true); setLoadingError(error); })
      .finally(() => setIsLoading(false));
  }, [])

  useEffect(() => {
    if (process) {
      dispatch(loadManyBlobs([{ docType: 'process', docId: process.guid }, [process.guid]]))
    }
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [params, setParams] = useSearchParams()
  const tab = params.get('tab') as FormTabs || 'documents'
  const setTab = (tab: FormTabs) => {
    params.set('tab', tab)
    setParams(params, { replace: true })
  }

  const handleTabChange = useCallback((_, newTab) => {
    setTab(newTab)
  }, [])



  const handleBackButtonClick = useCallback(() => {
    navigate(-1)
  }, [navigate])


  const handleEditTeamMemberClick = useCallback(() => {
    if (process) {
      setDialog({
        tag: 'editTeamMember',
        props: {
          processId: process?.id,
          mode: teamMemberToEdit ? "edit" : "create",
          excludedUsers: teamMembersSelection,
          defaultUserName: teamMemberToEdit?.user ?? "",
          onSave: (tm) => {
            setDialog({ tag: 'none' })
            setProcess(produce(draft => {
              if (draft !== undefined) {
                const index = draft.processTeamMembers.findIndex(u => u.id === tm.id)
                if (index >= 0) {
                  draft.processTeamMembers[index] = tm
                } else {
                  draft.processTeamMembers.push(tm)
                }
              }
            }))
          },
          onCancel: () => setDialog({ tag: 'none' })
        }
      })
    }
  }, [process])

  const handleAssingDocumentClick = useCallback(() => {
    if (process) {
      setDialog({
        tag: 'assingDocument',
        props: {
          processId: process?.id,
          onSave: (process) => {
            setDialog({ tag: 'none' })
            setProcess(process)
        },
          onCancel: () => setDialog({ tag: 'none' })
        }
      })
    }
  
  }, [process])

  const handleInviteTeamMemberClick = useCallback(() => {
    if (process) {
      setDialog({
        tag: 'inviteTeamMember',
        props: {
          processId: process?.id,
          onSave: (tm) => {
            setDialog({ tag: 'none' })
            setProcess(produce(draft => {
              if (draft !== undefined) {
                const index = draft.processTeamMembers.findIndex(u => u.id === tm.id)
                if (index >= 0) {
                  draft.processTeamMembers[index] = tm
                } else {
                  draft.processTeamMembers.push(tm)
                }
              }
            }))
          },
          onCancel: () => setDialog({ tag: 'none' })
        }
      })
    }
  }, [process])

  const handleTeamMemberDeleteClick = (id: AppId) => {
    if (process) {
      setDialog({
        tag: 'confirmDelete',
        props: {
          objectId: id,
          mode: 'deleteProcessTeamMember',
          onSave: (teamMemberId: AppId) => {
            setProcess(produce(draft => {
                if (draft !== undefined) {
                    draft.processTeamMembers = draft.processTeamMembers.filter(u => u.id !== teamMemberId)
                }
            }))
            setDialog({ tag: 'none' })
        },
          onCancel: () => setDialog({ tag: 'none' })
        }
      })
    }
  }

  const handleEditSimpleDescriptionClick = (fieldName: string) => {
    if (process) {
        setDialog({
            tag: 'editSimpleField',
            props: {
                processId: process.id,
                field: fieldName,
                value: process[`${fieldName}`] || '',
                onSave: (ph) => {
                    setDialog({ tag: 'none' })
                    setProcess(produce(draft => {
                        if (draft) {
                            draft[`${fieldName}`] = ph[`${fieldName}`]
                        }
                    }))
                },
                onCancel: () => setDialog({ tag: 'none' })
            }
        })
    }
  }


  const doccolumns: GridColDef[] = [
    {
      field: 'type',
      headerName: l10n.getString("process-type", null, "Typ"),
      width: 100
    },
    {
      field: 'no',
      headerName: l10n.getString("number", null, "Numer"),
      width: 100
    },
    {
      field: 'name',
      headerName: l10n.getString("title", null, "Tytuł"),
      flex: 1
    },
    {
      field: 'responsibleFullName',
      headerName: l10n.getString("responsible", null, "responsible"),
      width: 400
    },
    {
      field: 'createDate',
      headerName: l10n.getString("create-date", null, "create-date"),
      width: 150,
      valueGetter: (_, row) => {
        return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
      }
    },
    {
      field: 'status',
      headerName: l10n.getString("status", null, "status"),
      width: 120,
      valueGetter: (_, row) => {
        var status = row.status.toLowerCase();
        const description = l10n.getString("urs-status-" + status, null, "");
        return description;
      }
    },
    {
      field: 'actions', type: 'actions', width: 300,
      renderCell: (params) => {
        return (
          <ButtonGroup size="small">
            <Button
              style={{ width: 75 }}
              component={Link}
              to={`/urs/edit/${params.row.id}`}
              color="secondary"
              variant="outlined" >
              <Localized id="go-to">
                <span>Przejdz</span>
              </Localized>
            </Button>
         </ButtonGroup>
        )
      }
    }
  ];

  const teamcolumns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: l10n.getString('user-first-name', null, 'Imię'),
      width: 300
    },
    {
      field: 'lastName',
      headerName: l10n.getString('user-last-name', null, 'Nazwisko'),
      flex: 1
    },
    {
      field: 'position',
      headerName: l10n.getString('user-position', null, 'Stanowisko'),
      width: 200
    },
    {
      field: 'companyName',
      headerName: l10n.getString('user-company', null, 'Firma'),
      flex: 1
    },
    {
      field: 'role',
      headerName: l10n.getString('project-role', null, 'Rola w procesie'),
      flex: 1,
      renderCell: (params) => (
        <div>
          <RolesLabel role={params.value ?? ""} />
        </div>
      )
    },
    {
      field: 'actions',
      type: 'actions',
      width: 200,
      renderCell: (params) => {
        return (
          <ButtonGroup>
            <If condition={params.row.role != "M" && access}>
              <Button onClick={() => handleTeamMemberDeleteClick(params.row.id)} color="secondary" variant="outlined">
                <Localized id="delete">
                  <span>delete</span>
                </Localized>
              </Button>
            </If>
          </ButtonGroup>
        )
      }
    }
  ];

  if (loadingErrorOccured) {
    return <>
      <ErrorContainer error={loadingError} />
    </>
  }

  if (isLoading){
    return <>
      <LoadingContainer/>
    </>
  }

  //!
  if (process === undefined) {
    return <Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>;
  }

  return <>
    <Container maxWidth={false}>
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Card>
                  <CardHeader
                    title={process.name}
                    subheader={<Localized id="process-name"><span>Nazwa procesu</span></Localized>}
                  />
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardHeader
                    title={createdDate}
                    subheader={<Localized id="process-date"><span>Data</span></Localized>}
                  />
                </Card>
              </Grid>
              <Grid item xs={2}>
                <Card>
                  <CardHeader
                    title={<ProjectProcessStatusLabel status={process.status} />}
                    subheader={<Localized id="process-status"><span>Status</span></Localized>}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TabContext value={tab}>
                  <TabList onChange={handleTabChange}>
                    <Tab
                      key={0}
                      value={"documents"}
                      label={<Localized id="process-stage-names">Dokumenty/Etapy</Localized>} />
                    <Tab
                      key={1}
                      value={"management"}
                      label={<Localized id="process-management">Zarządzanie</Localized>} />
                    <Tab
                      key={1}
                      value={"team"}
                      label={<Localized id="process-team">Zespół</Localized>} />
                    <Tab
                      key={2}
                      value={"attachments"}
                      label={<Localized id="process-team-attachments">Załaczniki</Localized>} />
                    <Tab
                      key={3}
                      value={"notes"}
                      label={<Localized id="process-scratchpad">Zapiski</Localized>} />
                  </TabList>
                  <TabPanel value="documents">
                    <DataGrid
                      rows={allStages} autoHeight
                      columns={doccolumns}
                    />
                    <If condition={access}>
                      <Button sx={{ marginTop: "5px" }} component={Link} to={`/urs/create?processId=${processId}`} variant="outlined" color="secondary" startIcon={<AddIcon />} disabled={isProcessFinished || isProcessPaused}>
                        <Localized id="process-add-urs">
                          <span>Dodaj URS</span>
                        </Localized>
                      </Button>
                      <Button sx={{ marginTop: "5px" }} component={Link} to={`/new-risk-analysis/create?processId=${processId}`} variant="outlined" color="secondary" startIcon={<AddIcon />} disabled={isProcessFinished || isProcessPaused}>
                        <Localized id="">
                          <span>Dodaj Analize ryzyka</span>
                        </Localized>
                      </Button>
                      <Button sx={{ marginTop: "5px" }} component={Link}
                            to={{
                                pathname: "/process/new",
                            }}
                            state={{
                                deviceId: "",
                                ursId: ""
                            }}
                            variant="outlined" color="secondary" startIcon={<AddIcon />}
                            disabled={isProcessFinished || isProcessPaused}
                        >
                            <Localized id="process-add-validation">
                                <span>Waliduj</span>
                            </Localized>
                        </Button>
                        <Button sx={{ marginTop: "5px" }} 
                            variant="outlined" color="secondary" startIcon={<AddIcon />}
                            onClick={handleAssingDocumentClick}
                            disabled={isProcessFinished || isProcessPaused}
                        >
                            <Localized id="process-add-document">
                                <span>Podepnij dokument</span>
                            </Localized>
                        </Button>
                    </If>

                  </TabPanel>
                  <TabPanel value="team">
                    <DataGrid autoHeight
                      rows={allTeamMembers}
                      columns={teamcolumns}
                    />
                    <If condition={access}>
                      <Button sx={{ marginTop: "5px" }} variant="contained" color="secondary" onClick={handleEditTeamMemberClick} startIcon={<AddIcon />} >
                        <Localized id="add">
                          <span>Dodaj</span>
                        </Localized>
                      </Button>
                      <Button sx={{ marginLeft: "5px", marginTop: "6px" }} onClick={handleInviteTeamMemberClick} variant="outlined" startIcon={<ShareIcon />}  >
                        <Localized id="invite">
                          <span>Zaproś</span>
                        </Localized>
                      </Button>
                    </If>

                  </TabPanel>
                  <TabPanel value="meetingMinutes">
                      <MeetingMinutesList
                          objectId={process.guid}
                          objectType = 'PROCESS'
                          canEdit = {access}
                      />
                  </TabPanel>
                  <TabPanel value="attachments">
                    <Attachements resourceId={process.guid} canDelete={access} canUpload={access} />
                  </TabPanel>
                  <TabPanel value="notes">
                    <NotesList
                      objectId={process.guid} canEdit={access}
                    />
                  </TabPanel>
                  <TabPanel value="management">
                      <Grid container spacing={2}>
                          <Grid item xs={12}>
                              <Paper sx={{ p: 1 }}>
                                  <Typography component="div" color="text.secondary" gutterBottom>
                                      <Localized id='process-no' />
                                  </Typography>
                                  <If condition={process.no !== null && process.no !== ''} otherwise={<Alert severity="warning"><Localized id='missing'>Brak</Localized></Alert>}>
                                      <Typography sx={{ ml: 4 }} variant="h5">{process.no}</Typography>
                                  </If>
                                  <Button size="small" variant="text" onClick={() => handleEditSimpleDescriptionClick('no')}><Localized id='edit' /></Button>
                              </Paper>
                          </Grid>
                          <Grid item xs={12}>
                              <Paper sx={{ p: 1 }}>
                                  <Typography component="div" color="text.secondary" gutterBottom>
                                      <Localized id='process-name' />
                                  </Typography>
                                  <If condition={process.name !== null && process.name !== ''} otherwise={<Alert severity="warning"><Localized id='missing'>Brak</Localized></Alert>}>
                                      <Typography sx={{ ml: 4 }} variant="h5">{process.name}</Typography>
                                  </If>
                                  <Button size="small" variant="text" onClick={() => handleEditSimpleDescriptionClick('name')}><Localized id='edit' /></Button>
                              </Paper>
                          </Grid>
                          <Grid item xs={12}>
                              <Paper sx={{ p: 1 }}>
                                  <Typography component="div" color="text.secondary" gutterBottom>
                                      <Localized id='process-description' />
                                  </Typography>
                                  <If condition={process.description !== null && process.description !== ''} otherwise={<Alert severity="warning"><Localized id='missing'>Brak</Localized></Alert>}>
                                      <Typography sx={{ ml: 4 }} variant="h5">{process.description}</Typography>
                                  </If>
                                  <Button size="small" variant="text" onClick={() => handleEditSimpleDescriptionClick('description')}><Localized id='edit' /></Button>
                              </Paper>
                          </Grid>
                          <If condition={access && !isProcessFinished}>
                            <Grid item xs={12}>
                                <Paper sx={{ p: 1 }}>
                                    <Typography component="div" color="text.secondary" gutterBottom>
                                        <Localized id='process-management'/>
                                    </Typography>
                                    <ButtonGroup>
                                      <Button onClick={() => setActionToRun({ id: process.id, type: "POST", authorize: true, actionId: "deleteProjectProcess", name: process.name, description: "" })} color="error" variant="outlined">
                                          <Localized id="delete">Usuń</Localized>
                                      </Button>
                                      <If condition={!isProcessInProgress}>
                                        <Button onClick={() => setActionToRun({ id: process?.id, status: "I", type: "PUT", authorize: false, actionId: "startProjectProcess", name: process.name, description: "" })} color="secondary" variant="outlined">
                                            <Localized id="project-process-start">Rozpocznij projekt</Localized>
                                        </Button>
                                      </If>
                                      <If condition={!isProcessPaused}>
                                          <Button onClick={() => setActionToRun({ id: process?.id, status: "H", type: "PUT", authorize: false, actionId: "pauseProjectProcess", name: process.name, description: "" })} color="warning" variant="outlined">
                                              <Localized id="project-process-pause">Wstrzymaj projekt</Localized>
                                          </Button>
                                      </If>
                                      <Button onClick={() => setActionToRun({ id: process?.id, status: "F", type: "PUT", authorize: true, actionId: "finishProjectProcess", name: process.name, description: "" })} color="secondary" variant="outlined">
                                          <Localized id="project-process-finish">Zakończ projekt</Localized>
                                      </Button>
                                    </ButtonGroup>
                                </Paper>
                            </Grid>
                          </If>
                      </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
            <BottomAppBar>
              <Button key="back" onClick={handleBackButtonClick} color="secondary" variant="outlined">
                <Localized id="back">Wróć</Localized>
              </Button>
            </BottomAppBar>
          </CardContent>
        </Card>
      </Stack>
    </Container>
    <>{actionToRun && <ActionRunner
      action={actionToRun}
      onError={() => { }}
      onSuccess={result => {
          setActionToRun(null)
          if(result.actionId === "deleteProjectProcess") {
            handleBackButtonClick();
          } else if(result.actionId === "startProjectProcess" || result.actionId === "pauseProjectProcess" || result.actionId === "finishProjectProcess") {
            setProcess(produce(draft => {
              if(draft) {
                draft.status = result.status;
              }
            }))
          }
      }}
      onCancel={() => setActionToRun(null)} />}</>
    <DialogDispatcher dialog={dialog} />
  </>
}

const DialogDispatcher = ({ dialog }: { dialog: DialogState }) => {
  switch (dialog.tag) {
    case 'none':
      return null

    case 'editTeamMember':
      return <ProcessTeamMemberDialog {...dialog.props} />

    case 'inviteTeamMember':
      return <InviteProcessTeamMemberDialog {...dialog.props} />

    case 'editSimpleField':
      return <EditSimpleDescriptionField {...dialog.props} />

    case 'confirmDelete':
      return <DeleteConfirmationDialog {...dialog.props} />

    case 'assingDocument':
      return <AssingDocumentDialog {...dialog.props} />
  }
}

