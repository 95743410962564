import { Localized, useLocalization } from "@fluent/react"
import { Alert, AlertTitle, Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Skeleton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { UrsOfferStatusLabel } from "../ursOffer/UrsOfferStatusLabel"
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import PageviewIcon from '@mui/icons-material/Pageview';
import Divider from '@mui/material/Divider';
import { AppId } from "../../app/appTypes"
import { selectSettings } from '../settings/settingsSlice';
import { useAppDispatch, useAppSelector, useDataGridCustomLocaleText } from "../../app/hooks"
import { loadAllUrsOffers, Offers } from "./UrsSlice"
import { useCallback, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { ErrorContainer } from "../../app/ErrorContainer";
import { LoadingContainer } from "../../app/LoadingContainer";

export const UrsOffers = (props: { ursId: AppId, offerSelected: (id: AppId) => void, handleNewVersion: () => void, handleWithoutNewVersion: () => void }) => {
    const { ursId, offerSelected, handleNewVersion, handleWithoutNewVersion } = props
    const dispatch = useAppDispatch()
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const [ursOffers, setUrsOffers] = useState<Offers[] | null>(null)
    const localeText = useDataGridCustomLocaleText();
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadAllUrsOffers(ursId))
            .then(unwrapResult)
            .then(setUrsOffers)
            .catch((error) => { console.error(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false));
    }, [])

    const [showSelectOfferMessage, setShowSelectOfferMessage] = useState(false);
    const [message, setMessage] = useState("");


    const handleConfirmNewVersionClick = async () => {

        setShowSelectOfferMessage(false)
        handleNewVersion()
    }

    const handleConfirmWithoutNewVersionClick = async () => {

        setShowSelectOfferMessage(false)
        handleWithoutNewVersion()
    }


    const handleCancelSelectOfferClick = useCallback(() => {
        setShowSelectOfferMessage(false)
    }, [setShowSelectOfferMessage])



    const handleSelectOfferClick = async (id: AppId) => {
        if (ursOffers !== null) {
            let result = ursOffers.find(x => x.id === id);
            if (result) {
                if (result.hasUnfunfilledRequirements) {

                    setMessage(l10n.getString("urs-offer-all-requirements-not-ok-message", null, "Wybrana oferta zawiera wymagania, które nie zostały spełnione przez producenta. Wskazne jest utworzenie nowej wersji URS."));

                }
                else {
                    setMessage(l10n.getString("urs-offer-all-requirements-ok-message", null, "Wybrana oferta spełnia wszystkie wymagania"));
                }
            }
            offerSelected(id);
            setShowSelectOfferMessage(true)
        }

    }

    const handlePrintUrsClick = async (id: AppId) => {
        if (id) {
            window.open(`${process.env.REACT_APP_REST_API}api/reports/urs-offer/${id}?language=${locale}`, '_blank')
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'supplierName',
            headerName: l10n.getString("urs-offer-supplier", null, "_Dostawca"),
            flex: 1
        },
        {
            field: 'createDate',
            headerName: l10n.getString("urs-offer-send-date", null, "_Data wysłania"),
            minWidth: 200,

            valueGetter: (_, row) => {
                return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
            }
        },
        {
            field: 'responseDate',
            headerName: l10n.getString("urs-offer-response-date", null, "_Data odpowiedzi"),
            minWidth: 200,

            valueGetter: (_, row) => {
                return row.responseDate ? new Date(row.responseDate).toISOString().split('T')[0] : "";
            }
        },
        {
            field: 'comment',
            headerName: l10n.getString("urs-offer-comment", null, "_Komentarz"),
            minWidth: 200
        },
        {
            field: 'status',
            headerName: l10n.getString("urs-offer-status", null, "_Status"),
            width: 150,
            renderCell: (params) => {
                return (
                    <UrsOfferStatusLabel status={params.row.status} />
                );
            }
        },
        {
            field: 'actions', type: 'actions', width: 200, align: 'right',
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small" >
                        <ButtonGroup>
                            <Tooltip title={<Localized id="rs-offer-action-approve">_Zatwierdź</Localized>}>
                                <IconButton
                                    onClick={() => handleSelectOfferClick(params.row.id)}
                                    style={{ display: params.row.status === "A" ? "inline-flex" : "none" }}>
                                    <PriceCheckIcon />
                                </IconButton>
                            </Tooltip>
                            <Divider orientation="vertical" flexItem />
                            <IconButton aria-label="print" onClick={() => handlePrintUrsClick(params.row.id)} style={{ display: params.row.status !== "N" ? "inline-flex" : "none" }}>
                                <PrintIcon />
                            </IconButton>
                            <IconButton aria-label="open" component={Link} to={`/offer-preview/${params.row.id}`}>
                                <PageviewIcon />
                            </IconButton>
                        </ButtonGroup>
                    </ButtonGroup>
                )
            }
        }
    ]


    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const handleSelectionChange = (selection: GridRowSelectionModel) => {
        setSelectedRows(selection);
    };

    const handleCompare = () => {
        if(selectedRows && selectedRows.length > 0){
            navigate("/urs-offer-comparison", { state: { selectedOfferIds: selectedRows, ursId: ursId } });
        }
    };

    const handlePrint = async () => {
        const offerIdsParam = selectedRows.map(id => `offerIds=${id}`).join("&");
        if (offerIdsParam) {
            const url = `${process.env.REACT_APP_REST_API}api/urs-offer-comparator/report/${ursId}?language=${locale}&${offerIdsParam}`;

            window.open(url, '_blank');
        }

    };

    if(isError) {
        return <>
            <ErrorContainer error={error}/>
        </>
    }
    
    if (isLoading) {
        return <>
            <LoadingContainer/>
        </>
    }
    
    if (ursOffers == null) return <Skeleton />

    return <>
        <Box>
            <DataGrid
                rows={ursOffers}
                columns={columns}
                style={{ minHeight: 100, height: 'calc(100vh - 400px)' }}
                localeText={localeText}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                rowSelectionModel={selectedRows}
            />
            {
                <Paper >
                    <Alert severity="info" sx={{ padding: 0 }} >
                        <AlertTitle>
                            <>
                                <Localized id="selected-count">Wybrano </Localized>  {selectedRows.length}
                                <Button startIcon={<CompareArrowsIcon />} onClick={handleCompare} variant="contained" color="primary" size="small" sx={{ marginLeft: 5 }}>
                                    <Localized id="compare">_porównaj </Localized> ({selectedRows.length})
                                </Button>
                                <Button startIcon={<PrintIcon />} onClick={handlePrint} variant="contained" color="secondary" size="small" sx={{ marginLeft: 1 }}>
                                    <Localized id="print-compare">_Drukuj </Localized>
                                </Button >
                            </>
                        </AlertTitle>
                    </Alert>
                </Paper>
            }
        </Box >

        <Dialog open={showSelectOfferMessage}>
            <DialogTitle>
                <Localized id="urs-offer-action-approve-question">Czy zatwierdzić wskazaną ofertę?</Localized>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span>{message}</span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmNewVersionClick} variant="outlined" color="secondary" >
                    <Localized id="urs-offer-action-approve-new-version"><span>Zatwierdź z nową wersją URS</span></Localized>
                </Button>
                <Button onClick={handleConfirmWithoutNewVersionClick} variant="outlined" color="secondary" >
                    <Localized id="urs-offer-action-approve"><span>Zatwierdź</span></Localized>
                </Button>
                <Button onClick={handleCancelSelectOfferClick} variant="outlined">
                    <Localized id="cancel"><span>Anuluj</span></Localized>
                </Button>
            </DialogActions>
        </Dialog>
    </>
}
