import Container from '@mui/material/Container';
import TextField from "@mui/material/TextField";
import { Localized, useLocalization } from "@fluent/react";
import { Button, ButtonGroup, Grid, MenuItem, Paper, Tab, TableContainer, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect, useState } from "react";
import { loadUser, User } from './usersSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectUserCompanyId } from '../user/userSlice';
import { UserStatusLabel } from './UserStatusLabel';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { SystemEvent, loadSystemEventsForCompany } from '../systemSettings/systemEvents/systemEventsApi';
import { enUS, plPL } from '@mui/x-data-grid/locales';
import { selectSettings } from '../settings/settingsSlice';
import { useNavigate } from 'react-router-dom';

type FormTabs = "details" | "events"

export const UserDetails = () => {

    const navigate = useNavigate()

    const { l10n } = useLocalization();
    const { locale } = useAppSelector(selectSettings);
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const [user, setUser] = useState<User>()
    const dispatch = useAppDispatch();

    const [systemEvents, setSystemEvents] = useState<SystemEvent[]>([]);

    const handleGoBackclick = useCallback(() => navigate(-1), [navigate])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const loggedUserCompanyId = useAppSelector(selectUserCompanyId) as string

    useEffect(() => {
        dispatch(loadUser(id))
            .then(unwrapResult)
            .then(setUser)
            .catch((error) => console.error(error));

        dispatch(loadSystemEventsForCompany({ companyId: loggedUserCompanyId, query: `?objectType=User&objectId=${id}` }))
            .then(unwrapResult)
            .then(setSystemEvents)
            .catch((error) => console.error(error))
    }, [dispatch, id, loggedUserCompanyId])

    const [params, setParams] = useSearchParams()
    const tab = params.get('tab') as FormTabs || 'details'
    const setTab = (tab: FormTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const columns: GridColDef<SystemEvent, any, any>[] = [
        {
            field: 'createdBy',
            headerName: l10n.getString("username", null, "username"),
            width: 300
        },
        {
            field: 'eventType',
            headerName: l10n.getString("system-events-type", null, "Zdarzenie"),
            flex: 1,
        },
        {
            field: 'eventDate',
            headerName: l10n.getString("system-events-date", null, "Data zdarzenia"),
            flex: 1,
            valueGetter: (_, row) => {
                if(row.eventDate !== null) return new Date(row.eventDate).toLocaleString();
            }
        },
        {
            field: 'description',
            headerName: l10n.getString("system-events-description", null, "Opis"),
            flex: 1,
        },
        {
            field: 'expired',
            headerName: l10n.getString("status", null, "Status"),
            flex: 1,
            valueGetter: (_, row) => {
                if(row.eventType === "SendInvitation" && row.eventDate !== null && (new Date().getTime() - new Date(row.eventDate).getTime()) / (1000 * 60 * 60) >= 24) return l10n.getString("system-events-expired", null, "Wygasło")
            }
        }
    ]

    return (
        <Container maxWidth="md" sx={{
            paddingTop: 2,
            paddingBottom: 2,
        }}>
            <TabContext value={tab}>
                <TabList onChange={handleTabChange}>
                    <Tab
                        key={0}
                        value={"details"}
                        label={<Localized id="details">Szczegóły</Localized>} />
                    <Tab
                        key={1}
                        value={"events"}
                        label={<Localized id="system-events-history">Historia zdarzeń</Localized>} />
                </TabList>
                <TabPanel value="details">
                    <Paper sx={{ marginTop: "10px" }}>
                        <Grid container spacing={2} sx={{ padding: 2 }}>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    aria-readonly
                                    value={user?.userName || ""}
                                    label={<Localized id="username">Nazwa użytkownika</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography >
                                    <UserStatusLabel status={user?.status || ""}></UserStatusLabel>
                                </Typography >
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    aria-readonly
                                    value={user?.firstName || ""}
                                    label={<Localized id="user-first-name">Imię</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    aria-readonly
                                    value={user?.lastName || ""}
                                    label={<Localized id="user-last-name">Nazwisko</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    aria-readonly
                                    sx={{ minWidth: 256 }}
                                    value={user?.role || ""}
                                    label={<Localized id="user-role">Rola</Localized>} >
                                    {["admin", "user"].map(stage => <MenuItem key={stage} value={stage}>{stage}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    aria-readonly
                                    value={user?.email || ""}
                                    type={"email"}
                                    label={<Localized id="user-email">Email</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    aria-readonly
                                    value={user?.phone || ""}
                                    label={<Localized id="user-phone">Phone</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    aria-readonly
                                    value={user?.position || ""}
                                    label={<Localized id="user-position">Stanowisko</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={user?.lockedBy ?? ""}
                                    aria-readonly
                                    label={<Localized id="user-archived-by">Zarchiwizowany przez</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={new Date(user?.lockedAt ?? "").toLocaleString()}
                                    aria-readonly
                                    label={<Localized id="user-archiving-date">Data archiwizacji</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={user?.lockReason || ""}
                                    multiline
                                    rows={4}
                                    aria-readonly
                                    label={<Localized id="user-archiving-reason">Powód archiwizacji</Localized>}>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <ButtonGroup color="secondary" variant="contained">
                                    <Button onClick={handleGoBackclick} color="secondary" variant="outlined">
                                        <Localized id="back">Wróć</Localized>
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Paper>
                </TabPanel>
                <TabPanel value="events">
                    <TableContainer component={Paper}>
                        <DataGrid localeText={getCustomLocaleText()}
                            style={{ minHeight: 100, height: 'calc(100vh - 240px)' }}
                            rows={systemEvents}
                            columns={columns}
                            initialState={{
                                sorting: { sortModel: [{ field: 'eventDate', sort: 'desc' }] }
                            }}
                        />
                    </TableContainer>
                </TabPanel>
            </TabContext>
        </Container>
    );
}
