import { Localized, useLocalization } from '@fluent/react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import WarningIcon from '@mui/icons-material/Warning'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { loadCompanies, selectAllCompanies, selectCompanies } from './companiesSlice'
import { useEffect } from 'react'
import { Box, ButtonGroup } from '@mui/material'
import { FlexDiv } from '../../app/Utils'
import { selectSettings } from '../settings/settingsSlice'
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { useNavigate } from 'react-router-dom'
import { QuickSearchToolbar } from '../../app/QuickSearchToolbar'
import { If } from '../../app/If'

export const Companies = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const { loaded } = useAppSelector(selectCompanies)
    const allCompanies = useAppSelector(selectAllCompanies)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!loaded) {
            dispatch(loadCompanies())
        }
    }, [dispatch, loaded])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleGoToClick = (id) => {
        navigate(`/company/edit/${id}`)
    };

    const columns: GridColDef[] = [
        {
            field: 'shortName',
            headerName: l10n.getString("companies-short-name", null, "Nazwa skrócona"),
            width: 400
        },
        {
            field: 'name',
            headerName: l10n.getString("companies-name", null, "Nazwa"),
            flex: 1
        },
        {
            field: "license",
            headerName: l10n.getString("companies-license", null, "_Licencja"),
            flex: 1
        },
        {
            field: "userLimit",
            headerName: l10n.getString("companies-users", null, "_Użytkownicy"),
            flex: 1,
            valueGetter: (_, row) => {
                return `${row.usersCount}/${row.userLimit}`
            },
            renderCell: (params) => {
                return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <If condition={params.row.usersCount > params.row.userLimit}>
                            <WarningIcon color="warning" />
                        </If>
                        <span style={{ marginLeft: 8 }}>{params.row.usersCount}/{params.row.userLimit}</span>
                    </Box>
                )
            }
        },
        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 75 }}
                            onClick={() => handleGoToClick(params.row.id)}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    return (
        <>
            <TableContainer component={Paper}>
                <DataGrid localeText={getCustomLocaleText()}
                    style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                    rows={allCompanies}
                    columns={columns}
                    initialState={{
                        sorting: { sortModel: [{ field: 'shortName', sort: 'asc' }] }
                    }}
                    slots={{ toolbar: QuickSearchToolbar }}
                    onRowDoubleClick={({ row }) => handleGoToClick(row.id)}
                />
            </TableContainer>
            <FlexDiv>
                <Fab sx={{
                        marginTop: 2,
                    }} 
                    component={Link} 
                    to="/company/create" 
                    color="secondary" 
                >
                    <AddIcon />
                </Fab>
            </FlexDiv>
        </>
    );
}
